import { HrtfRecord } from './HrtfRecord'
import { HrtfDatabase } from './HrtfDatabase'

export class HrtfLoader {
  private DEBUG_ON: boolean = false

  DEBUG(e: any) {
    if (this.DEBUG_ON) {
      console.log(e)
    }
  }

  /*
   * Gets an HRTF
   *
   * @param baseUrl
   * @param hrtfName
   * @param usagePath
   * @returns {Promise|Promise<T>}
   */
  public getHrtf(
    baseUrl: string,
    hrtfName: string,
    usagePath: string,
    browser: any
  ): Promise<HrtfDatabase> {
    const self = this

    this.DEBUG('calling getHrtf: ' + hrtfName + ', usagePath: ' + usagePath)

    // console.log(`browser`, browser)

    let hrtfUrl: any = null
    if (typeof browser === `undefined`) {
      hrtfUrl = '/' + baseUrl + '/hrtf/' + usagePath + '/' + hrtfName
    } else {
      hrtfUrl = browser.runtime.getURL(
        baseUrl + '/hrtf/' + usagePath + '/' + hrtfName
      )
    }

    // console.log(`hrtfUrl:`, hrtfUrl)

    // Local URL
    return new Promise(function (resolve, reject) {
      fetch(hrtfUrl)
        .then((response) => response.blob())
        .then((response) => {
          self.readBlob(response).then(
            function (blobResponse) {
              var arrayBuffer = <ArrayBuffer>blobResponse
              var hrtfDatabase: HrtfDatabase = self.decodeAtHrtf(arrayBuffer)
              resolve(hrtfDatabase)
            },
            function (error) {
              reject(error)
            }
          )
        })
        .catch((error) => {
          console.log(error)
          reject(new Error('Request failed.  Error: ' + error))
        })
    })

    // Remote URL
    // return new Promise(function (resolve, reject) {
    //   var xhr = new XMLHttpRequest()
    //   xhr.open('GET', hrtfUrl, true)
    //   xhr.responseType = 'blob'

    //   xhr.onload = function () {
    //     if (xhr.status === 200) {
    //       self.readBlob(xhr.response).then(
    //         function (blobResponse) {
    //           var arrayBuffer = <ArrayBuffer>blobResponse
    //           var hrtfDatabase: HrtfDatabase = self.decodeAtHrtf(arrayBuffer)
    //           resolve(hrtfDatabase)
    //         },
    //         function (error) {
    //           reject(error)
    //         }
    //       )
    //     } else {
    //       console.error('Request failed.  Returned status of ' + xhr.status)
    //       reject(new Error('Request failed.  Returned status of ' + xhr.status))
    //     }
    //   }
    //   xhr.send()
    // })
  }

  /*
   *
   * @param dataBlob
   * @returns {IPromise<T>}
   */
  private readBlob(dataBlob: any): Promise<any> {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader()
      reader.onload = function () {
        resolve(reader.result)
      }
      reader.onerror = function (error) {
        console.error(`Cannot read Blob (1), error:`, error)
        reject(new Error(`Cannot read Blob`))
      }
      try {
        reader.readAsArrayBuffer(dataBlob)
      } catch (error) {
        console.error(`Cannot read Blob (2), error:`, error)
        reject(error)
      }
    })
  }

  /*
   * Decodes ATHRTF.
   *
   * @param data
   */
  decodeAtHrtf(arrayBuffer: ArrayBuffer): HrtfDatabase {
    var hrtfDatabase: HrtfDatabase = new HrtfDatabase()

    var self = this
    // reader.result contains the contents of blob as a typed array
    var dataView = new DataView(arrayBuffer)

    var startIndex = 0
    var endIndex = 15

    // Name
    hrtfDatabase.name = self.getStringFromDataView(
      dataView,
      startIndex,
      endIndex
    )
    // console.log(hrtfDatabase.name)

    // Version
    startIndex = endIndex
    endIndex = endIndex + 4
    hrtfDatabase.version = dataView.getFloat32(startIndex, true)
    // console.log(hrtfDatabase.version)

    // Description
    startIndex = endIndex
    endIndex = endIndex + 200
    hrtfDatabase.description = self.getStringFromDataView(
      dataView,
      startIndex,
      endIndex
    )
    // console.log(hrtfDatabase.description)

    // Gain
    startIndex = endIndex
    endIndex = endIndex + 4
    hrtfDatabase.gain = dataView.getFloat32(startIndex, true)
    // console.log(hrtfDatabase.gain)

    // Filter Type
    startIndex = endIndex
    endIndex = endIndex + 4
    hrtfDatabase.filterType = self.getStringFromDataView(
      dataView,
      startIndex,
      endIndex
    )
    // console.log(hrtfDatabase.filterType)

    // Measured Channels
    startIndex = endIndex
    endIndex = endIndex + 6
    hrtfDatabase.measuredChannels = self.getStringFromDataView(
      dataView,
      startIndex,
      endIndex
    )
    // console.log(hrtfDatabase.measuredChannels)

    // Number Of Channels
    startIndex = endIndex
    endIndex = endIndex + 4
    hrtfDatabase.numberOfChannels = dataView.getInt32(startIndex, true)
    // console.log(hrtfDatabase.numberOfChannels)

    // Sampling Frequency
    startIndex = endIndex
    endIndex = endIndex + 4
    hrtfDatabase.sampleRate = dataView.getInt32(startIndex, true)
    // console.log(hrtfDatabase.sampleRate)

    // Number of Taps
    startIndex = endIndex
    endIndex = endIndex + 4
    hrtfDatabase.numberOfTaps = dataView.getInt32(startIndex, true)
    // console.log('numberOfTaps: ' + hrtfDatabase.numberOfTaps)

    // Data Type
    startIndex = endIndex
    endIndex = endIndex + 4
    hrtfDatabase.dataType = self.getStringFromDataView(
      dataView,
      startIndex,
      endIndex
    )
    // console.log(hrtfDatabase.dataType)

    // Number of Directions
    startIndex = endIndex
    endIndex = endIndex + 4
    hrtfDatabase.numberOfDirections = dataView.getInt32(startIndex, true)
    // console.log(hrtfDatabase.numberOfDirections)

    // Mapping
    // var mapArray = self.createMapArray();
    hrtfDatabase.mapArray = new Array(360 * 181)
    for (var i = 0; i < 360 * 181; i++) {
      startIndex = endIndex
      endIndex = endIndex + 4
      var index = dataView.getInt32(startIndex, true)
      hrtfDatabase.mapArray[i] = index
    }
    // console.log(hrtfDatabase.mapArray)
    this.printAvailableAzimuths(hrtfDatabase.mapArray)

    // Mapping Closest
    hrtfDatabase.mapArrayClosest = new Array(360 * 181)
    for (var i = 0; i < 360 * 181; i++) {
      startIndex = endIndex
      endIndex = endIndex + 4
      var index = dataView.getInt32(startIndex, true)
      hrtfDatabase.mapArrayClosest[i] = index
    }
    // console.log(hrtfDatabase.mapArrayClosest)

    // Check data type
    var length =
      hrtfDatabase.numberOfDirections *
      hrtfDatabase.numberOfChannels *
      hrtfDatabase.numberOfTaps
    // console.log(length)

    hrtfDatabase.ir = new Float32Array(length)
    hrtfDatabase.shortIr = new Int16Array(length)

    if (hrtfDatabase.dataType === '32f') {
      // console.log('YES! 32f')

      for (var i = 0; i < length; i++) {
        startIndex = endIndex
        endIndex = endIndex + 4
        var floatValue = dataView.getFloat32(startIndex, true)
        hrtfDatabase.ir[i] = floatValue

        var shortValue = floatValue * Math.pow(2.0, 15.0)
        hrtfDatabase.shortIr[i] = shortValue
      }

      // TODO // console.log(ir);
    } else if (hrtfDatabase.dataType === '16s') {
      // console.log('YES! 16s')

      for (var i = 0; i < length; i++) {
        startIndex = endIndex
        endIndex = endIndex + 2
        var shortValue = dataView.getInt16(startIndex, true)
        hrtfDatabase.shortIr[i] = shortValue

        var floatValue = shortValue / Math.pow(2.0, 15.0)
        hrtfDatabase.ir[i] = floatValue
      }

      // TODO // console.log(ir);
    } else {
      console.log('NO! unknown dataType:' + hrtfDatabase.dataType)
    }

    var k = 0
    for (var i = 0; i < 360; i++) {
      for (var j = -90; j <= +90; j++) {
        // hrtfDatabase.myMapToHrtfRecordArray.set(i + ',' + j, hrtfDatabase.hrtfRecordArray.length);

        if (hrtfDatabase.mapArray[k] !== -1) {
          if (hrtfDatabase.measuredChannels === 'LEFT') {
            var hrtfRecord: HrtfRecord = self.getHrtfForLeftMeasured(
              hrtfDatabase.mapArrayClosest,
              i,
              j,
              hrtfDatabase.ir,
              hrtfDatabase.numberOfTaps
            )
            hrtfDatabase.addHrtfRecord(hrtfRecord)
          } else if (hrtfDatabase.measuredChannels === 'RIGHT') {
            var hrtfRecord: HrtfRecord = self.getHrtfForRightMeasured(
              hrtfDatabase.mapArrayClosest,
              i,
              j,
              hrtfDatabase.ir,
              hrtfDatabase.numberOfTaps
            )
            hrtfDatabase.addHrtfRecord(hrtfRecord)
          } else if (hrtfDatabase.measuredChannels === 'BOTH') {
            var hrtfRecord: HrtfRecord = self.getHrtfForBothMeasured(
              hrtfDatabase.mapArrayClosest,
              i,
              j,
              hrtfDatabase.ir,
              hrtfDatabase.numberOfTaps
            )
            // console.log(hrtfRecord);
            hrtfDatabase.addHrtfRecord(hrtfRecord)
          } else {
            // TODO
          }
        }
        k++
      }
    }

    // console.log(hrtfDatabase);
    // console.log(hrtfDatabase.myMapToHrtfRecordArray);

    return hrtfDatabase
  }

  /*
   *
   * @returns {any[]}
   */
  createMapArray() {
    var array = new Array(360)
    for (var i = 0; i < 360; i++) {
      array[i] = new Array(181)
    }
    return array
  }

  /*
   *
   * @param dataView
   * @param startIndex
   * @param endIndex
   * @returns {string}
   */
  getStringFromDataView(
    dataView: DataView,
    startIndex: number,
    endIndex: number
  ): string {
    var str = ''
    for (var i = startIndex; i < endIndex; ++i) {
      var charCode = dataView.getInt8(i)
      if (charCode >= 32 && charCode <= 126) {
        str = str + String.fromCharCode(charCode)
      }
    }
    str = str.trim()
    return str
  }

  /*
   *
   * @param mapArray
   * @param Azimuth
   * @param Elevation
   * @param ir
   * @param numberOfTaps
   * @returns {HrtfRecord}
   */
  getHrtfForLeftMeasured(
    mapArray: any,
    Azimuth: any,
    Elevation: any,
    ir: any,
    numberOfTaps: any
  ): HrtfRecord {
    var index1 = mapArray[Azimuth * 181 + (Elevation + 90)]
    var index2 = mapArray[(359 - Azimuth) * 181 + (Elevation + 90)]

    // console.log('getHrtfForLeftMeasured, ' + index1 + ' - ' + index2 + ', for Az,El ' + Azimuth, +', ' + Elevation);

    var l = this.getFilter(
      ir,
      index1 * numberOfTaps,
      index1 * numberOfTaps + numberOfTaps
    )
    var r = this.getFilter(
      ir,
      index2 * numberOfTaps,
      index2 * numberOfTaps + numberOfTaps
    )

    return new HrtfRecord(Azimuth, Elevation, 1, l, r)
  }

  /*
   *
   * @param mapArray
   * @param Azimuth
   * @param Elevation
   * @param ir
   * @param numberOfTaps
   * @returns {HrtfRecord}
   */
  getHrtfForRightMeasured(
    mapArray: any,
    Azimuth: any,
    Elevation: any,
    ir: any,
    numberOfTaps: any
  ): HrtfRecord {
    var index1 = mapArray[Azimuth * 181 + (Elevation + 90)]
    var index2 = mapArray[(359 - Azimuth) * 181 + (Elevation + 90)]

    // console.log('getHrtfForLeftMeasured, ' + index1 + ' - ' + index2 + ', for Az,El ' + Azimuth, +', ' + Elevation);

    // Inverse l - r compared to getHrtfForLeftMeasured
    var r = this.getFilter(
      ir,
      index1 * numberOfTaps,
      index1 * numberOfTaps + numberOfTaps
    )
    var l = this.getFilter(
      ir,
      index2 * numberOfTaps,
      index2 * numberOfTaps + numberOfTaps
    )

    return new HrtfRecord(Azimuth, Elevation, 1, l, r)
  }

  /*
   *
   * @param mapArray
   * @param Azimuth
   * @param Elevation
   * @param ir
   * @param numberOfTaps
   * @returns {HrtfRecord}
   */
  getHrtfForBothMeasured(
    mapArray: any,
    Azimuth: any,
    Elevation: any,
    ir: any,
    numberOfTaps: any
  ): HrtfRecord {
    var switchIrs: boolean = false
    var azimuthOriginal: number = Azimuth
    if (Azimuth > 180) {
      switchIrs = true
      Azimuth = 360 - Azimuth
    }

    var index = mapArray[Azimuth * 181 + (Elevation + 90)]

    // console.log('getHrtfForLeftMeasured, ' + index1 + ' - ' + index2 + ', for Az,El ' + Azimuth, +', ' + Elevation);

    var l = this.getFilter(
      ir,
      2 * index * numberOfTaps,
      2 * index * numberOfTaps + numberOfTaps
    )
    var r = this.getFilter(
      ir,
      (2 * index + 1) * numberOfTaps,
      (2 * index + 1) * numberOfTaps + numberOfTaps
    )

    if (switchIrs) {
      return new HrtfRecord(azimuthOriginal, Elevation, 1, r, l)
    } else {
      return new HrtfRecord(Azimuth, Elevation, 1, l, r)
    }
  }

  /*
     int index = m_ariMAPClosest[(int)Azimuth][(int)(Elevation + 90)];
     if(index !== -1)// && index > 0)
     {
     if(m_iSamplingFrequency === m_iSamplingFrequencyOriginal)
     {
     ippsCopy_32f((Ipp32f*)m_pIR + (2*index)*m_iNumberOfTapsOriginal, pIRLeft, m_iNumberOfTapsOriginal);
     ippsCopy_32f((Ipp32f*)m_pIR + (2*index + 1)*m_iNumberOfTapsOriginal, pIRRight, m_iNumberOfTapsOriginal);

     */

  /*
   *
   * @param ir
   * @param startIndex
   * @param endIndex
   * @returns {any[]}
   */
  getFilter(ir: any, startIndex: number, endIndex: number) {
    var filter = new Array(endIndex - startIndex)
    var counter = 0
    for (var i = startIndex; i < endIndex; i++) {
      filter[counter] = ir[i]
      counter++
    }
    return filter
  }

  private printAvailableAzimuths(mapArray: any): void {
    var availableAzimuths: any = []

    for (var azimuth: number = 0; azimuth < 360; azimuth++) {
      for (var elevation: number = 0; elevation <= 0; elevation++) {
        var index = mapArray[azimuth * 181 + (elevation + 90)]

        if (index > -1) {
          availableAzimuths.push(azimuth)
        }
      }
    }

    // console.log(availableAzimuths);
  }
}
