import { AreiAudioNode } from './arei-audio-node'
import { VirtualStereoFilterCalculatorService } from './virtual-stereo/VirtualStereoFilterCalculatorService'
import { VirtualStereoFilters } from './virtual-stereo/VirtualStereoFilters'
import { VirtualAmbioFilterCalculatorService } from './virtual-ambio/VirtualAmbioFilterCalculatorService'
import { VirtualAmbioFilters } from './virtual-ambio/VirtualAmbioFilters'
import AreiPluginParametersService from '../../audio-plugins-parameters/arei/arei-plugin-parameters.service'
import { HrtfLoader } from '../../audio-core/hrtf/HrtfLoader'
import { IPluginService } from '../../audio-core/plugin/IPluginService'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'

import ResonanceAudioNode from './resonance-audio-node'
export class AreiPluginService implements IPluginService {
  private hrtfLoader: HrtfLoader
  private virtualStereoFilterCalculatorService: VirtualStereoFilterCalculatorService
  private virtualAmbioFilterCalculatorService: VirtualAmbioFilterCalculatorService
  private baseUrl = `assets`

  constructor(
    private areiPluginParametersService: AreiPluginParametersService
  ) {
    this.hrtfLoader = new HrtfLoader()
    this.virtualStereoFilterCalculatorService =
      new VirtualStereoFilterCalculatorService(this.hrtfLoader)
    this.virtualAmbioFilterCalculatorService =
      new VirtualAmbioFilterCalculatorService(this.hrtfLoader)
  }

  createAudioNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<IAudioNode> {
    return new Promise((resolve, reject) => {
      pluginInstance =
        this.areiPluginParametersService.ensurePluginInstanceParameterKeyValues(
          pluginInstance
        )

      const method = this.areiPluginParametersService.getParameterValue(
        pluginInstance,
        AreiPluginParametersService.METHOD
      )

      // console.log('AreiPluginService - method: ', method)

      // ResonanceAudio
      if (method === 'resonanceAudio') {
        const resonanceAudioNode = new ResonanceAudioNode(
          audioContext,
          this.areiPluginParametersService.getParameterKeyValues(pluginInstance)
        )

        resolve(resonanceAudioNode)
      }
      // VIRTUAL XTC
      else if (method === 'virtualCtc') {
        this.virtualStereoFilterCalculatorService
          .calculate(
            this.baseUrl,
            audioContext,
            {
              hrtf: `dry.aria3d.hrtf`,
              frontAngle: 60
            },
            browser
          )
          .then(
            (result) => {
              const virtualStereoFilters: VirtualStereoFilters = <
                VirtualStereoFilters
              >result

              const audioNode: AreiAudioNode = new AreiAudioNode(
                audioContext,
                this.areiPluginParametersService.getParameterKeyValues(
                  pluginInstance
                ),
                virtualStereoFilters,
                null
              )

              resolve(audioNode)
            },
            (result: any) => {
              console.error(`Cannot create AREI node`)
              reject(result)
            }
          )
      }
      // VIRTUAL STEREO
      else if (method === 'virtualStereo') {
        this.virtualStereoFilterCalculatorService
          .calculate(
            this.baseUrl,
            audioContext,
            {
              hrtf: this.areiPluginParametersService.getParameterValue(
                pluginInstance,
                AreiPluginParametersService.SIDE_HRTF
              ),
              frontAngle: this.areiPluginParametersService.getParameterValue(
                pluginInstance,
                AreiPluginParametersService.SIDE_ANGLE
              )
            },
            browser
          )
          .then(
            (result) => {
              const virtualStereoFilters: VirtualStereoFilters = <
                VirtualStereoFilters
              >result

              const audioNode: AreiAudioNode = new AreiAudioNode(
                audioContext,
                this.areiPluginParametersService.getParameterKeyValues(
                  pluginInstance
                ),
                virtualStereoFilters,
                null
              )

              resolve(audioNode)
            },
            (result: any) => {
              console.error(`Cannot create AREI node`)
              reject(result)
            }
          )
      }
      // VIRTUAL AMBIO
      else if (method === 'virtualAmbio') {
        this.virtualAmbioFilterCalculatorService
          .calculate(
            this.baseUrl,
            audioContext,
            {
              frontAnglePerSpeaker:
                this.areiPluginParametersService.getParameterValue(
                  pluginInstance,
                  AreiPluginParametersService.FRONT_ANGLE
                ),
              backAnglePerSpeaker:
                this.areiPluginParametersService.getParameterValue(
                  pluginInstance,
                  AreiPluginParametersService.REAR_ANGLE
                ),
              frontBackHrtf: this.areiPluginParametersService.getParameterValue(
                pluginInstance,
                AreiPluginParametersService.FRONT_HRTF
              ),
              sideAnglePerSpeaker:
                this.areiPluginParametersService.getParameterValue(
                  pluginInstance,
                  AreiPluginParametersService.SIDE_ANGLE
                ),
              sideHrtf: this.areiPluginParametersService.getParameterValue(
                pluginInstance,
                AreiPluginParametersService.SIDE_HRTF
              ),
              xtcFilterLength:
                this.areiPluginParametersService.getParameterValue(
                  pluginInstance,
                  AreiPluginParametersService.XTC_FILTER_LENGTH
                ),
              xtcAttenuations:
                this.areiPluginParametersService.getParameterValue(
                  pluginInstance,
                  AreiPluginParametersService.XTC_ATTENUATIONS_IN_DB
                ),
              xtcDelay: this.areiPluginParametersService.getParameterValue(
                pluginInstance,
                AreiPluginParametersService.XTC_DELAY_IN_SAMPLES
              )
            },
            browser
          )
          .then(
            (result) => {
              const virtualAmbioFilters: VirtualAmbioFilters = <
                VirtualAmbioFilters
              >result
              // console.log(virtualAmbioFilters);

              const audioNode: AreiAudioNode = new AreiAudioNode(
                audioContext,
                this.areiPluginParametersService.getParameterKeyValues(
                  pluginInstance
                ),
                null,
                virtualAmbioFilters
              )

              resolve(audioNode)
            },
            (result: any) => {
              console.error(`Cannot create AREI node`)
              reject(result)
            }
          )
      } else {
        console.error('Invalid AREI method: ' + method)
      }
    })
  }

  setParameterValue(
    audioContext: AudioContext,
    audioNode: IAudioNode,
    parameterKeyValue: ParameterKeyValue,
    browser: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      audioNode.setParameter(parameterKeyValue)

      const method = audioNode.getParameter(
        AreiPluginParametersService.METHOD
      ).value
      // console.log('method: ' + method);

      // VIRTUAL STEREO
      if (method === 'virtualStereo') {
        switch (parameterKeyValue.key) {
          case AreiPluginParametersService.METHOD:
          case AreiPluginParametersService.SIDE_HRTF:
          case AreiPluginParametersService.SIDE_ANGLE:
            // console.log('this.virtualStereoFilterCalculatorService.calculate');
            this.virtualStereoFilterCalculatorService
              .calculate(
                this.baseUrl,
                audioContext,
                {
                  hrtf: audioNode.getParameter(
                    AreiPluginParametersService.SIDE_HRTF
                  ).value,
                  frontAngle: audioNode.getParameter(
                    AreiPluginParametersService.SIDE_ANGLE
                  ).value
                },
                browser
              )
              .then(
                (result) => {
                  const virtualStereoFilters: VirtualStereoFilters = <
                    VirtualStereoFilters
                  >result
                  ;(<AreiAudioNode>audioNode).applyVirtualStereoFilters(
                    virtualStereoFilters
                  )
                  resolve()
                },
                (result: any) => {
                  console.error(
                    `Cannot calculate AREI filters for VIRTUAL_STEREO`
                  )
                  reject(result)
                }
              )
            break
          default:
            console.error(
              'No switch case for parameter with name: ' + parameterKeyValue.key
            )
            reject()
            break
        }
      }
      // VIRTUAL AMBIO
      else if (method === 'virtualAmbio') {
        switch (parameterKeyValue.key) {
          case AreiPluginParametersService.METHOD:
          case AreiPluginParametersService.FRONT_HRTF:
          case AreiPluginParametersService.FRONT_ANGLE:
          case AreiPluginParametersService.REAR_HRTF:
          case AreiPluginParametersService.REAR_ANGLE:
          case AreiPluginParametersService.SIDE_HRTF:
          case AreiPluginParametersService.SIDE_ANGLE:
            this.virtualAmbioFilterCalculatorService
              .calculate(
                this.baseUrl,
                audioContext,
                {
                  frontAnglePerSpeaker: audioNode.getParameter(
                    AreiPluginParametersService.FRONT_ANGLE
                  ).value,
                  backAnglePerSpeaker: audioNode.getParameter(
                    AreiPluginParametersService.REAR_ANGLE
                  ).value,
                  frontBackHrtf: audioNode.getParameter(
                    AreiPluginParametersService.FRONT_HRTF
                  ).value,
                  sideAnglePerSpeaker: audioNode.getParameter(
                    AreiPluginParametersService.SIDE_ANGLE
                  ).value,
                  sideHrtf: audioNode.getParameter(
                    AreiPluginParametersService.SIDE_HRTF
                  ).value,
                  xtcFilterLength: audioNode.getParameter(
                    AreiPluginParametersService.XTC_FILTER_LENGTH
                  ).value,
                  xtcAttenuations: audioNode.getParameter(
                    AreiPluginParametersService.XTC_ATTENUATIONS_IN_DB
                  ).value,
                  xtcDelay: audioNode.getParameter(
                    AreiPluginParametersService.XTC_DELAY_IN_SAMPLES
                  ).value
                },
                browser
              )
              .then(
                (result) => {
                  const virtualAmbioFilters: VirtualAmbioFilters = <
                    VirtualAmbioFilters
                  >result
                  // console.log(virtualAmbioFilters);
                  ;(<AreiAudioNode>audioNode).applyVirtualAmbioFilters(
                    virtualAmbioFilters
                  )
                  resolve()
                },
                (result: any) => {
                  console.error(
                    `Cannot calculate AREI filters for VIRTUAL_AMBIO`
                  )
                  reject(result)
                }
              )

            break

          case AreiPluginParametersService.FRONT_GAIN:
            ;(<AreiAudioNode>audioNode).virtualAmbioAudioNode.applyFrontGain()
            break
          case AreiPluginParametersService.REAR_GAIN:
            ;(<AreiAudioNode>audioNode).virtualAmbioAudioNode.applyRearGain()
            break
          case AreiPluginParametersService.SIDE_GAIN:
            ;(<AreiAudioNode>audioNode).virtualAmbioAudioNode.applySideGain()
            break
          default:
            console.error(
              'No switch case for parameter with name: ' + parameterKeyValue.key
            )
            reject()
            break
        }
      } else {
        console.error('Invalid AREI method: ' + method)
      }
    })
  }
}
