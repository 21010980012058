import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'

export class PluginGainParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static GAIN: string = 'gain'

  // private static DEFAULT_GAIN_VALUE: number = 1
  // private static DEFAULT_GAIN_VALUE: number = 1.5
  // private static DEFAULT_GAIN_VALUE: number = 1.25
  private static DEFAULT_GAIN_VALUE: number = 1.375

  constructor() {
    super()

    this.parameters.push({
      key: PluginGainParametersService.GAIN,
      value: PluginGainParametersService.DEFAULT_GAIN_VALUE
    })
  }

  public getPluginId(): any {
    return 'SIMPLE_GAIN'
  }

  public getPresetDiscriminator(): string {
    return null
  }
}

export default PluginGainParametersService
