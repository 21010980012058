import { ParameterKeyValue } from '../../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../../audio-core/web-audio-api/audio-node/IAudioNode'

export class DynamicConvolver extends AbstractAudioNode implements IAudioNode {
  private impulseResponse: AudioBuffer
  private convolverNode: ConvolverNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>,
    impulseResponse: AudioBuffer
  ) {
    super(audioContext, parameters)

    this.impulseResponse = impulseResponse

    this.convolverNode = this.audioContext.createConvolver()
    this.convolverNode.normalize = false
    this.convolverNode.buffer = this.impulseResponse
    this.convolverNode.channelInterpretation = 'discrete'

    this.wire()
  }

  wire(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.convolverNode
  }

  getOutput(): AudioNode {
    return this.convolverNode
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.convolverNode.connect(destination)
  }

  disconnect(output?: number): void {
    this.convolverNode.disconnect(output)
  }

  updateImpulseResponse(impulseResponse: AudioBuffer) {
    // console.log('DynamicConvolver');
    // console.log(this.impulseResponse);
    this.impulseResponse = impulseResponse

    // Stopped working in Chrome 69
    // this.convolverNode.buffer = this.impulseResponse;

    this.convolverNode = this.audioContext.createConvolver()
    this.convolverNode.normalize = false
    this.convolverNode.buffer = this.impulseResponse
    this.convolverNode.channelInterpretation = 'discrete'
  }
}
