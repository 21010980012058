import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'

export class DynamicsCompressorPluginParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static THRESHOLD: string = 'threshold'
  public static KNEE: string = 'knee'
  public static RATIO: string = 'ratio'
  public static ATTACK: string = 'attack'
  public static RELEASE: string = 'release'
  /**
   * A read-only decibel value for metering purposes, representing the current amount of gain reduction
   * that the compressor is applying to the signal.
   * If fed no signal the value will be 0 (no gain reduction).
   * When this attribute is read, return the value of the private slot [[internal reduction]].
   */
  public static REDUCTION: string = 'reduction'

  private static DEFAULT_THRESHOLD_VALUE: number = -24
  private static DEFAULT_KNEE_VALUE: number = 30
  private static DEFAULT_RATIO_VALUE: number = 12
  private static DEFAULT_ATTACK_VALUE: number = 0.003
  private static DEFAULT_RELEASE_VALUE: number = 0.25
  private static DEFAULT_REDUCTION_VALUE: number = 0

  constructor() {
    super()

    this.parameters.push({
      key: DynamicsCompressorPluginParametersService.THRESHOLD,
      value: DynamicsCompressorPluginParametersService.DEFAULT_THRESHOLD_VALUE
    })

    this.parameters.push({
      key: DynamicsCompressorPluginParametersService.KNEE,
      value: DynamicsCompressorPluginParametersService.DEFAULT_KNEE_VALUE
    })

    this.parameters.push({
      key: DynamicsCompressorPluginParametersService.RATIO,
      value: DynamicsCompressorPluginParametersService.DEFAULT_RATIO_VALUE
    })

    this.parameters.push({
      key: DynamicsCompressorPluginParametersService.ATTACK,
      value: DynamicsCompressorPluginParametersService.DEFAULT_ATTACK_VALUE
    })

    this.parameters.push({
      key: DynamicsCompressorPluginParametersService.RELEASE,
      value: DynamicsCompressorPluginParametersService.DEFAULT_RELEASE_VALUE
    })

    this.parameters.push({
      key: DynamicsCompressorPluginParametersService.REDUCTION,
      value: DynamicsCompressorPluginParametersService.DEFAULT_REDUCTION_VALUE
    })
  }

  public getPluginId(): any {
    return 'DYNAMICS_COMPRESSOR'
  }

  public getPresetDiscriminator(): string {
    return null
  }

  public static getDefaultThresholdValue() {
    return DynamicsCompressorPluginParametersService.DEFAULT_THRESHOLD_VALUE
  }

  public static getDefaultKneeValue() {
    return DynamicsCompressorPluginParametersService.DEFAULT_KNEE_VALUE
  }

  public static getDefaultRatioValue() {
    return DynamicsCompressorPluginParametersService.DEFAULT_RATIO_VALUE
  }

  public static getDefaultAttackValue() {
    return DynamicsCompressorPluginParametersService.DEFAULT_ATTACK_VALUE
  }

  public static getDefaultReleaseValue() {
    return DynamicsCompressorPluginParametersService.DEFAULT_RELEASE_VALUE
  }
}
