import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { DynamicsCompressorPluginParametersService } from '../../audio-plugins-parameters/dynamics-compressor/dynamics-compressor-plugin-parameters.service'

export class DynamicsCompressorAudioNode
  extends AbstractAudioNode
  implements IAudioNode
{
  public dynamicsCompressorNode: DynamicsCompressorNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    super(audioContext, parameters)

    this.dynamicsCompressorNode = this.audioContext.createDynamicsCompressor()

    this.applyThreshold()
    this.applyKnee()
    this.applyRatio()
    this.applyAttack()
    this.applyRelease()
  }

  wire(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.dynamicsCompressorNode
  }

  getOutput(): AudioNode {
    return this.dynamicsCompressorNode
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.dynamicsCompressorNode.connect(destination)
  }

  disconnect(output?: number): void {
    this.dynamicsCompressorNode.disconnect(output)
  }

  applyThreshold() {
    this.dynamicsCompressorNode.threshold.setTargetAtTime(
      this.getParameter(DynamicsCompressorPluginParametersService.THRESHOLD)
        .value,
      this.audioContext.currentTime,
      0.015
    )
  }

  applyKnee() {
    this.dynamicsCompressorNode.knee.setTargetAtTime(
      this.getParameter(DynamicsCompressorPluginParametersService.KNEE).value,
      this.audioContext.currentTime,
      0.015
    )
  }

  applyRatio() {
    this.dynamicsCompressorNode.ratio.setTargetAtTime(
      this.getParameter(DynamicsCompressorPluginParametersService.RATIO).value,
      this.audioContext.currentTime,
      0.015
    )
  }

  applyAttack() {
    this.dynamicsCompressorNode.attack.setTargetAtTime(
      this.getParameter(DynamicsCompressorPluginParametersService.ATTACK).value,
      this.audioContext.currentTime,
      0.015
    )
  }

  applyRelease() {
    this.dynamicsCompressorNode.release.setTargetAtTime(
      this.getParameter(DynamicsCompressorPluginParametersService.RELEASE)
        .value,
      this.audioContext.currentTime,
      0.015
    )
  }
}
