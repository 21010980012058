import { IAudioNode } from '../web-audio-api/audio-node/IAudioNode'
import { IPluginService } from './IPluginService'
import { PluginInstance } from '../plugins/PluginInstance'

export class PluginGraphNode {
  constructor(
    public audioContext: AudioContext,
    public audioNode: IAudioNode,
    public pluginService: IPluginService,
    public pluginInstance: PluginInstance,
    public browser: any
  ) {}
}

export default PluginGraphNode
