import { MySpecialAudioInputNode } from './MySpecialAudioInputNode'
import { BaseAudioInputNode } from './BaseAudioInputNode'
import { IMediaContent } from '../../media-content/IMediaContent'

export class InputAudioDom extends BaseAudioInputNode {
  constructor() {
    super()
  }

  /*
   * Create Node.
   *
   * @param mediaContent
   * @param audioContext
   * @returns {any}
   */
  public createNode(
    mediaContent: IMediaContent,
    audioContext: AudioContext
  ): Promise<MySpecialAudioInputNode> {
    var mySpecialAudioInputNode: MySpecialAudioInputNode =
      new MySpecialAudioInputNode()

    mySpecialAudioInputNode.htmlMediaElement = mediaContent.getSource()

    return new Promise((resolve, reject) => {
      // mySpecialAudioInputNode.htmlMediaElement.addEventListener(
      //   'canplay',
      //   function () {
      if (mySpecialAudioInputNode.mediaElementAudioSourceNode === null) {
        try {
          mySpecialAudioInputNode.mediaElementAudioSourceNode =
            audioContext.createMediaElementSource(
              mySpecialAudioInputNode.htmlMediaElement
            )
        } catch (err) {
          console.error(err)
          reject(new Error(err))
        }
      }

      resolve(mySpecialAudioInputNode)
      //   }
      // )
    })
  }
}
