import AudioContextProvider from '../audio-core/web-audio-api/AudioContextProvider'
import StreamPlayer from '../audio-core/player/stream-player'
import PluginProcessorAudioNode from '../audio-core/plugin/PluginProcessorAudioNode'
import MediaStreamMediaContent from '../audio-core/media-content/MediaStreamMediaContent'
import { PluginInstance } from '../audio-core/plugins/PluginInstance'
import { PluginInstanceParameterKeyValue } from '../audio-core/plugins/PluginInstanceParameterKeyValue'
import { PluginInstanceFactoryService } from './plugin-instance-factory.service'
import { IPluginBackgroundController } from '../audio-core/plugins/IPluginBackgroundController'
import AudioInputNodeFactory from '../audio-core/web-audio-api/audio-input/AudioInputNodeFactory'

export class BackgroundStreamPlayerService
  implements IPluginBackgroundController
{
  private pluginInstanceFactoryService: PluginInstanceFactoryService
  private audioInputNodeFactory: AudioInputNodeFactory

  private streamPlayerArray: any = {}

  constructor(
    pluginInstanceFactoryService: PluginInstanceFactoryService,
    audioInputNodeFactory: AudioInputNodeFactory
  ) {
    // console.log('BackgroundStreamPlayerService constructor');
    this.pluginInstanceFactoryService = pluginInstanceFactoryService
    this.audioInputNodeFactory = audioInputNodeFactory
  }

  createPluginInstance(
    tabId: any,
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<PluginInstance> {
    return this.addNewPluginInstanceInGraph(
      tabId,
      audioContext,
      pluginInstance,
      browser
    )
  }

  removePluginInstance(
    tabId: any,
    pluginInstance: PluginInstance
  ): Promise<void> {
    // TODO
    return this.removePluginInstanceFromGraph(tabId, pluginInstance)
  }

  removeAllPluginInstances(tabId: any): Promise<void> {
    return this.getStreamPlayer(tabId).removeAllPluginInstances()
  }

  createStreamPlayer(
    streamPlayerId: any,
    audioContext: any,
    mediaStreamMediaContent?: MediaStreamMediaContent
  ) {
    // console.log(`createStreamPlayer - streamPlayerId`, streamPlayerId)
    // console.log(`this.streamPlayerArray`, this.streamPlayerArray)
    if (!this.streamPlayerArray[streamPlayerId]) {
      var streamPlayer: StreamPlayer = new StreamPlayer(
        audioContext,
        this.pluginInstanceFactoryService,
        this.audioInputNodeFactory
      )

      if (mediaStreamMediaContent) {
        streamPlayer.play(mediaStreamMediaContent, 1)
      }

      this.streamPlayerArray[streamPlayerId] = streamPlayer
      // console.log(`this.streamPlayerArray`, this.streamPlayerArray)
    }
  }

  getStreamPlayer(streamPlayerId: any): StreamPlayer {
    if (this.streamPlayerArray[streamPlayerId]) {
      // console.log('Yes StreamPlayer with id: ' + streamPlayerId)
      // console.log(this.streamPlayerArray[streamPlayerId]);
      return this.streamPlayerArray[streamPlayerId]
    } else {
      // console.warn('No StreamPlayer with id: ' + streamPlayerId);
      return undefined
    }
  }

  getAnalyserData(tabId: any, playerId: any): Promise<any> {
    // console.log(
    //   'BackgroundStreamPlayerService - getAnalyserData, tabId, playerId',
    //   tabId,
    //   playerId
    // )

    // console.log(`streamPlayerArray`, this.streamPlayerArray)

    return new Promise((resolve, reject) => {
      var streamPlayer = this.getStreamPlayer(playerId)
      if (streamPlayer) {
        // console.log('OK!');
        resolve(streamPlayer.getAnalyserData())
      } else {
        // TODO
        // reject(
        //   new Error(`Could not get analyzer data for playerId=${playerId}`)
        // )
        resolve({})
      }
    })
  }

  addNewPluginInstanceInGraph(
    tabId: any,
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<PluginInstance> {
    // console.log('BackgroundStreamPlayerService - addNewPluginInstanceInGraph')
    // console.log(`pluginInstance`, pluginInstance)
    // console.log(`tabId`, tabId)

    return this.getStreamPlayer(tabId).addNewPluginInstanceInGraph(
      audioContext,
      pluginInstance,
      browser
    )
  }

  removePluginInstanceFromGraph(
    tabId: any,
    pluginInstance: PluginInstance
  ): Promise<void> {
    // console.log('BackgroundStreamPlayerService - removePluginInstanceInGraph');
    // console.log(pluginInstance);

    return this.getStreamPlayer(tabId).removePluginInstanceFromGraph(
      pluginInstance
    )
  }

  removeAllPluginInstancesFromGraph(tabId: any): Promise<void> {
    // console.log('audio-background, BackgroundStreamPlayerService, removeAllPluginInstancesFromGraph');

    return this.getStreamPlayer(tabId).removeAllPluginInstances()
  }

  setPluginInstanceParameterKeyValue(
    tabId: any,
    audioContext: AudioContext,
    pluginInstanceParameterKeyValue: PluginInstanceParameterKeyValue,
    browser: any
  ): Promise<PluginInstance> {
    // console.log('BackgroundStreamPlayerService - setPluginInstanceParameterKeyValue');
    // console.log(pluginInstanceParameterKeyValue);

    return this.getStreamPlayer(tabId).setPluginInstanceParameterKeyValue(
      audioContext,
      pluginInstanceParameterKeyValue,
      browser
    )
  }

  getAllPluginInstances(tabId: any): Promise<Array<PluginInstance>> {
    // console.log('BackgroundStreamPlayerService - getAllPluginInstances')

    return this.getStreamPlayer(tabId).getAllPluginInstances()
  }

  getSampleRate(audioContext: AudioContext): Promise<number> {
    return new Promise((resolve, reject) => {
      resolve(audioContext.sampleRate)
    })
  }

  setProcessingOn(tabId: any): Promise<void> {
    return this.getStreamPlayer(tabId).setProcessingOn()
  }

  setProcessingOff(tabId: any): Promise<void> {
    return this.getStreamPlayer(tabId).setProcessingOff()
  }

  queryProcessingState(tabId: any): Promise<boolean> {
    return this.getStreamPlayer(tabId).queryProcessingState()
  }

  // ONLY FOR ARIA-3D
  setListeningThroughSpeakers(tabId: any): Promise<void> {
    return this.getStreamPlayer(tabId).setListeningThroughSpeakers()
  }

  // ONLY FOR ARIA-3D
  setListeningThroughHeadphones(tabId: any): Promise<void> {
    return this.getStreamPlayer(tabId).setListeningThroughHeadphones()
  }

  queryListeningDevice(tabId: any): Promise<string> {
    return this.getStreamPlayer(tabId).queryListeningDevice()
  }

  enablePluginInstance(
    tabId: any,
    instanceId: string
  ): Promise<PluginInstance> {
    return this.getStreamPlayer(tabId).enablePluginInstance(instanceId)
  }

  disablePluginInstance(
    tabId: any,
    instanceId: string
  ): Promise<PluginInstance> {
    return this.getStreamPlayer(tabId).disablePluginInstance(instanceId)
  }
}

export default BackgroundStreamPlayerService
