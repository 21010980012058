import { MySpecialAudioInputNode } from './MySpecialAudioInputNode'
import { BaseAudioInputNode } from './BaseAudioInputNode'
import { IMediaContent } from '../../media-content/IMediaContent'

export class InputVideoRemoteFile extends BaseAudioInputNode {
  constructor() {
    super()
  }

  /*
   * Create Node.
   *
   * @param mediaContent
   * @param audioContext
   * @returns {any}
   */
  public createNode(
    mediaContent: IMediaContent,
    audioContext: AudioContext,
    index: number,
    useStreamForRemote: boolean
  ): Promise<MySpecialAudioInputNode> {
    return this.createVideoStream(mediaContent.getSource(), audioContext, index)
  }
}
