import { IAudioNode } from '../web-audio-api/audio-node/IAudioNode'
import { AbstractAudioNode } from '../web-audio-api/audio-node/AbstractAudioNode'
import { PluginGraph } from './PluginGraph'

export class PluginProcessorAudioNode
  extends AbstractAudioNode
  implements IAudioNode
{
  input: GainNode
  output: GainNode

  constructor(
    public audioContext: AudioContext,
    public pluginGraph: PluginGraph
  ) {
    super(audioContext, [])

    this.input = this.audioContext.createGain()

    this.output = this.audioContext.createGain()
  }

  wire(): Promise<any> {
    this.disconnectAll()

    if (this.isEnabled() && this.pluginGraph.hasNodes()) {
      return this.pluginGraph.wire()
    } else {
      var self = this
      return new Promise<void>((resolve) => {
        self.input.connect(self.output)
        resolve()
      })
    }
  }

  getInput(): AudioNode | undefined {
    if (this.isEnabled() && this.pluginGraph.hasNodes()) {
      return this.pluginGraph.getFirstGraphNode()?.audioNode?.getInput()
    } else {
      return this.input
    }
  }

  getOutput(): AudioNode | undefined {
    if (this.isEnabled() && this.pluginGraph.hasNodes()) {
      return this.pluginGraph.getLastGraphNode()?.audioNode?.getOutput()
    } else {
      return this.output
    }
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    const thisOutput = this.getOutput()
    if (thisOutput) {
      thisOutput.connect(destination)
    }
  }

  disconnect(output?: number): void {
    const thisOutput = this.getOutput()
    if (thisOutput) {
      if (output) {
        thisOutput.disconnect(output)
      } else {
        thisOutput.disconnect()
      }
    }
  }

  private disconnectAll(): void {
    this.input.disconnect()
    this.output.disconnect()
  }
}

export default PluginProcessorAudioNode
