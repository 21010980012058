export class RaceDspService {
  calculate(args: any) {
    var left: Float32Array = new Float32Array(args.filterLength).fill(0)
    var right: Float32Array = new Float32Array(args.filterLength).fill(0)

    left[0] = 1
    right[0] = 0

    for (var i = 0; i < args.filterLength; i++) {
      left[i] =
        left[i] -
        args.attenuation * (i - args.delay < 0 ? 0 : right[i - args.delay])
      right[i] =
        right[i] -
        args.attenuation * (i - args.delay < 0 ? 0 : left[i - args.delay])
    }

    return {
      left: left,
      right: right
    }
  }

  calculateWithPromise(args: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let result = this.calculate(args)
      resolve(result)
    })
  }
}
