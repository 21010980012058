import { MySpecialAudioInputNode } from '../web-audio-api/audio-input/MySpecialAudioInputNode'
import { AudioInputNodeFactory } from '../web-audio-api/audio-input/AudioInputNodeFactory'
import { IMediaContent } from '../media-content/IMediaContent'
import { PluginProcessorAudioNode } from '../plugin/PluginProcessorAudioNode'
import PluginGraph from '../plugin/PluginGraph'
import PluginInstance from '../plugins/PluginInstance'
import PluginGraphNode from '../plugin/PluginGraphNode'
import PluginInstanceFactoryService from '../../audio-background/plugin-instance-factory.service'
import { PluginInstanceParameterKeyValue } from '../plugins/PluginInstanceParameterKeyValue'

export class BasePlayer {
  private static ANALYZER_FFT_SIZE: number = 2048

  private pluginGraph: PluginGraph
  private pluginProcessorAudioNode: PluginProcessorAudioNode

  private mySpecialAudioInputNode: MySpecialAudioInputNode
  private analyserNode: AnalyserNode
  private analyserData: any

  protected currentMediaContent: IMediaContent

  private useAnalyserNode: boolean = true

  private audioInputNodeArray: MySpecialAudioInputNode[]

  private isPaused: boolean = false
  private selectedIndex: number = -1

  constructor(
    public audioContext: AudioContext,
    public pluginInstanceFactoryService: PluginInstanceFactoryService,
    public audioInputNodeFactory: AudioInputNodeFactory,
    public callbackOnFinish?: any
  ) {
    // console.log(`BasePlayer constructor`)
    // console.log(`audioContext`, audioContext)

    this.mySpecialAudioInputNode = null
    this.currentMediaContent = null
    this.audioInputNodeArray = []

    this.pluginGraph = new PluginGraph(audioContext)

    this.pluginProcessorAudioNode = new PluginProcessorAudioNode(
      audioContext,
      this.pluginGraph
    )

    this.createAnalyserNode()
  }

  /**
   * Appends a media content to the array as audio node
   * @param mediaContent
   */
  public async appendInputMediaContent(
    mediaContent: IMediaContent
  ): Promise<MySpecialAudioInputNode> {
    // console.log(`appendInputMediaContent:`, mediaContent)

    try {
      const mySpecialAudioInputNode: MySpecialAudioInputNode =
        await this.audioInputNodeFactory.createAudioNode(
          mediaContent,
          this.audioContext,
          this.audioInputNodeArray.length + 1
        )

      // console.log(`mySpecialAudioInputNode`, mySpecialAudioInputNode)
      // console.log(
      //   `mySpecialAudioInputNode.getAudioNode()`,
      //   mySpecialAudioInputNode.getAudioNode()
      // )

      this.audioInputNodeArray.push(mySpecialAudioInputNode)

      await this.wireForAudioInputNodeArray()

      return Promise.resolve(mySpecialAudioInputNode)
    } catch (error) {
      console.error(new Error(error))

      return Promise.reject(error)
    }
  }

  protected pauseCurrentMediaContent(
    selectedIndex: number
  ): Promise<MySpecialAudioInputNode> {
    this.isPaused = true
    this.selectedIndex = selectedIndex

    var self = this
    return new Promise((resolve, reject) => {
      // console.log('pauseCurrentMediaContent')

      if (self.mySpecialAudioInputNode.htmlMediaElement != null) {
        // console.log('Try htmlMediaElement.pause()')
        try {
          self.mySpecialAudioInputNode.htmlMediaElement.pause()
          resolve(self.mySpecialAudioInputNode)
        } catch (err) {
          console.error(
            'self.mySpecialAudioInputNode.htmlMediaElement.pause(); FAILED!'
          )
          console.error(err)
          reject(
            new Error(
              'self.mySpecialAudioInputNode.htmlMediaElement.pause(); FAILED!'
            )
          )
        }
      } else if (self.mySpecialAudioInputNode.audioBufferSourceNode != null) {
        // console.log('audioNode.start(0)');
        ;(<AudioBufferSourceNode>(
          self.mySpecialAudioInputNode.getAudioNode()
        )).start(0)
        resolve(self.mySpecialAudioInputNode)
      } else if (
        self.mySpecialAudioInputNode.mediaStreamAudioSourceNode != null
      ) {
        resolve(self.mySpecialAudioInputNode)
      } else {
        console.error('BOTH htmlMediaElement AND audioNode are NULL')
        reject(new Error('BOTH htmlMediaElement AND audioNode are NULL'))
      }
    })
  }

  /*
   * Play Stream.
   *
   * @param index
   * @returns {Promise|Promise<T>}
   */
  protected playCurrentMediaContent(
    selectedIndex: number
  ): Promise<MySpecialAudioInputNode> {
    // console.log('playCurrentMediaContent, selectedIndex/this-selectedIndex:', selectedIndex, this.selectedIndex);

    var self = this

    if (this.selectedIndex === selectedIndex && this.isPaused === true) {
      return new Promise((resolve, reject) => {
        this.isPaused = false

        if (self.mySpecialAudioInputNode.htmlMediaElement != null) {
          // console.log('Try htmlMediaElement.pause()')
          try {
            self.mySpecialAudioInputNode.htmlMediaElement.play()
            resolve(self.mySpecialAudioInputNode)
          } catch (err) {
            console.error(
              'self.mySpecialAudioInputNode.htmlMediaElement.pause(); FAILED!'
            )
            console.error(err)
            reject(
              new Error(
                'self.mySpecialAudioInputNode.htmlMediaElement.pause(); FAILED!'
              )
            )
          }
        }
      })
    }

    this.isPaused = false
    this.selectedIndex = selectedIndex

    return new Promise((resolve, reject) => {
      // console.log('playCurrentMediaContent - in Promise - 1');

      self.resetMySpecialAudioInputNode()

      // console.log('playCurrentMediaContent - in Promise - 2');

      self.audioInputNodeFactory
        .createAudioNode(self.currentMediaContent, self.audioContext, 0)
        .then(
          (result: any) => {
            // console.log(result);
            // console.log('playCurrentMediaContent - in Promise - 3');

            self.mySpecialAudioInputNode = result

            // Media Element
            if (self.mySpecialAudioInputNode.htmlMediaElement != null) {
              self.mySpecialAudioInputNode.htmlMediaElement.onended = (
                event: Event
              ) => {
                // console.log('Your audio has finished playing')
                // console.log(`event`, event)
                if (self.callbackOnFinish) {
                  // console.log('Calling callbackOnFinish')
                  self.callbackOnFinish(event)
                }
                // console.log('playCurrentMediaContent - in Promise - 4')
              }
            }

            // console.log('playCurrentMediaContent - in Promise - 5');

            // console.log('BEFORE self.wire()');
            self.wire().then(
              (value) => {
                // console.log('self.wire() OK');
                // console.log('playCurrentMediaContent - in Promise - 6');

                if (self.mySpecialAudioInputNode.htmlMediaElement != null) {
                  // console.log('Try htmlMediaElement.play()');
                  try {
                    self.mySpecialAudioInputNode.htmlMediaElement.play()
                    resolve(self.mySpecialAudioInputNode)
                  } catch (err) {
                    console.error(
                      'self.mySpecialAudioInputNode.htmlMediaElement.play(); FAILED!'
                    )
                    console.error(err)
                    reject(
                      new Error(
                        'self.mySpecialAudioInputNode.htmlMediaElement.play(); FAILED!'
                      )
                    )
                  }
                } else if (
                  self.mySpecialAudioInputNode.audioBufferSourceNode != null
                ) {
                  // console.log('audioNode.start(0)');
                  ;(<AudioBufferSourceNode>(
                    self.mySpecialAudioInputNode.getAudioNode()
                  )).start(0)
                  resolve(self.mySpecialAudioInputNode)
                } else if (
                  self.mySpecialAudioInputNode.mediaStreamAudioSourceNode !=
                  null
                ) {
                  resolve(self.mySpecialAudioInputNode)
                } else {
                  console.error('BOTH htmlMediaElement AND audioNode are NULL')
                  reject(
                    new Error('BOTH htmlMediaElement AND audioNode are NULL')
                  )
                }
              },
              (reason) => {
                // console.log('playCurrentMediaContent - in Promise - 7');
                console.error(reason)
                reject(new Error(reason))
              }
            )
          },
          (result: any) => {
            // console.log('playCurrentMediaContent - in Promise - 8');
            console.error(result)
            reject(new Error(result))
          }
        )
      // console.log('playCurrentMediaContent - in Promise - 9');
    })
  }

  /*
   * clearMySpecialAudioInputNode
   */
  private resetMySpecialAudioInputNode(): void {
    if (this.mySpecialAudioInputNode != null) {
      this.mySpecialAudioInputNode.disconnectAudioBufferSourceNode()
      this.mySpecialAudioInputNode.disconnectMediaElementAudioSourceNode()
      this.mySpecialAudioInputNode.disconnectMediaStreamSourceNode()
      this.mySpecialAudioInputNode = null
    }
  }

  private disconnectMySpecialAudioInputNode(): void {
    if (this.mySpecialAudioInputNode) {
      if (this.mySpecialAudioInputNode.getAudioNode()) {
        this.mySpecialAudioInputNode.getAudioNode().disconnect()
      }
    }
  }

  /*
   *
   * @returns {Promise<T>}
   */
  public wire(): Promise<void> {
    if (this.mySpecialAudioInputNode === null) {
      return this.wireForAudioInputNodeArray()
    }

    var self = this

    // console.log('wire - 1');

    return new Promise<void>(function (resolve, reject) {
      // console.log('wire - 2');

      if (self.mySpecialAudioInputNode === null) {
        // console.log('wire - 3');
        console.warn('self.mySpecialAudioInputNode === null')
        resolve()
        return
      }

      // console.log('wire - 4');

      if (
        self.mySpecialAudioInputNode.getAudioNode() === null &&
        self.mySpecialAudioInputNode.htmlMediaElement === null
      ) {
        // console.log('wire - 5');
        console.warn(
          'self.mySpecialAudioInputNode.getAudioNode() === null && self.mySpecialAudioInputNode.htmlMediaElement === null'
        )
        resolve()
        return
      }

      // console.log('wire - 6');

      // Wire plugin processor and connect to AudioContext Destination & Analyzer
      self.disconnectAll()

      // console.log('wire - 7');

      self.pluginProcessorAudioNode.wire().then(
        (result: any) => {
          // console.log('wire - 8');

          // console.log('pluginProcessorAudioNode.wire() OK!');
          // console.log(self.mySpecialAudioInputNode.getAudioNode());
          // console.log(self.pluginProcessorAudioNode.getOutput());

          self.mySpecialAudioInputNode
            .getAudioNode()
            .connect(self.pluginProcessorAudioNode.getInput())
          self.pluginProcessorAudioNode
            .getOutput()
            .connect(self.audioContext.destination)
          if (self.useAnalyserNode) {
            self.pluginProcessorAudioNode.getOutput().connect(self.analyserNode)
          }

          resolve()
        },
        (error: any) => {
          // console.log('wire - 9');
          console.error('pluginProcessorAudioNode.wire() failed! Reason:')
          console.error(error)
        }
      )
      // console.log('wire - 10');
    })
  }

  private async wireForAudioInputNodeArray(): Promise<void> {
    // console.log(`wireForAudioInputNodeArray`)
    // console.log(`audioInputNodeArray.length`, this.audioInputNodeArray.length)

    this.disconnectAll()

    await this.pluginProcessorAudioNode.wire()

    this.audioInputNodeArray.forEach((audioInputNode) => {
      // console.log(`audioInputNode`, audioInputNode)

      if (audioInputNode) {
        // console.log(`YES audioInputNode`)
        audioInputNode
          .getAudioNode()
          .connect(this.pluginProcessorAudioNode.getInput())
      } else {
        // console.log(`NO audioInputNode`)
      }
    })

    this.pluginProcessorAudioNode
      .getOutput()
      .connect(this.audioContext.destination)

    if (this.useAnalyserNode) {
      this.pluginProcessorAudioNode.getOutput().connect(this.analyserNode)
    }

    Promise.resolve()
  }

  disconnectAll(): void {
    // console.log('disconnectAll')
    this.pluginProcessorAudioNode.getOutput().disconnect()
    this.disconnectMySpecialAudioInputNode()

    this.audioInputNodeArray.forEach((audioInputNode) => {
      // console.log(`Disconnect audioInputNode`, audioInputNode)

      if (audioInputNode) {
        audioInputNode.getAudioNode().disconnect()
      } else {
        // console.log(`NO audioInputNode`)
      }
    })
  }

  createAnalyserNode() {
    if (this.useAnalyserNode) {
      this.analyserNode = this.audioContext.createAnalyser()
      this.analyserNode.fftSize = BasePlayer.ANALYZER_FFT_SIZE

      this.analyserData = {
        bufferSize: this.analyserNode.frequencyBinCount,
        frequencyData: new Uint8Array(this.analyserNode.frequencyBinCount),
        timeData: new Uint8Array(this.analyserNode.frequencyBinCount)
      }
    }
  }

  public getAnalyserData(): any {
    // console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK')
    // console.log(this.analyserNode)
    if (this.useAnalyserNode) {
      // console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL')
      this.analyserNode.getByteFrequencyData(this.analyserData.frequencyData)
      this.analyserNode.getByteTimeDomainData(this.analyserData.timeData)
      this.analyserData.frequencyBinCount = this.analyserNode.frequencyBinCount

      return this.analyserData
    } else {
      return undefined
    }
  }

  // /////////////////////////////////////////////////////////////////////////////
  // From Background Controller
  // /////////////////////////////////////////////////////////////////////////////

  public removeAllPluginInstances(): Promise<void> {
    return new Promise((resolve) => {
      this.pluginGraph.removeAllNodes()
      resolve()
    })
  }

  public addNewPluginInstanceInGraph(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<PluginInstance> {
    // console.log(
    //   'BasePlayer - addNewPluginInstanceInGraph, pluginInstance:',
    //   pluginInstance
    // )

    return new Promise((resolve, reject) => {
      if (pluginInstance.order === -1) {
        pluginInstance.order = this.calculateNewPluginInstanceOrder()
      }

      // console.log(
      //   'Create ' +
      //     pluginInstance.pluginId +
      //     '(' +
      //     pluginInstance.instanceId +
      //     ') for order: ' +
      //     pluginInstance.order
      // )

      this.pluginInstanceFactoryService
        .createPluginGraphNode(audioContext, pluginInstance, browser)
        .then(
          (pluginGraphNode: PluginGraphNode) => {
            // console.log(`pluginGraphNode to add`, pluginGraphNode)

            this.pluginGraph.addNode(pluginGraphNode)

            // console.log(
            //   `pluginGraphNode added & before wire pluginGraphNode:`,
            //   pluginGraphNode
            // )

            this.wire()

            // console.log(`after wire pluginGraphNode:`, pluginGraphNode)

            resolve(pluginInstance)
          },
          (result: any) => {
            console.error(
              `BasePlayer - addNewPluginInstanceInGraph - Could not add pluginInstance in PluginGraphNodes:`,
              pluginInstance
            )
            reject(result)
          }
        )
    })
  }

  public removePluginInstanceFromGraph(
    pluginInstance: PluginInstance
  ): Promise<void> {
    // console.log('BackgroundStreamPlayerService - addNewPluginInstanceInGraph');
    // console.log(pluginInstance);

    return new Promise((resolve, reject) => {
      // TODO
      this.pluginGraph.removeNode(null)

      this.wire()

      resolve()
    })
  }

  public removeAllPluginInstancesFromGraph(): Promise<void> {
    // console.log('audio-background, BackgroundStreamPlayerService, removeAllPluginInstancesFromGraph');

    return new Promise((resolve) => {
      this.pluginGraph.removeAllNodes()

      this.wire()

      resolve()
    })
  }

  public setPluginInstanceParameterKeyValue(
    audioContext: AudioContext,
    pluginInstanceParameterKeyValue: PluginInstanceParameterKeyValue,
    browser: any
  ): Promise<PluginInstance> {
    // console.log('BackgroundStreamPlayerService - setPluginInstanceParameterKeyValue');
    // console.log(pluginInstanceParameterKeyValue);

    if (pluginInstanceParameterKeyValue.parameter.key === 'enabled') {
      if (pluginInstanceParameterKeyValue.parameter.value === true) {
        return this.enablePluginInstance(
          pluginInstanceParameterKeyValue.instanceId
        )
      } else {
        return this.disablePluginInstance(
          pluginInstanceParameterKeyValue.instanceId
        )
      }
    } else {
      return this.pluginGraph.setPluginGraphNodeParameter(
        audioContext,
        pluginInstanceParameterKeyValue,
        browser
      )
    }
  }

  public getAllPluginInstances(): Promise<Array<PluginInstance>> {
    // console.log('BackgroundStreamPlayerService - getAllPluginInstances')

    return new Promise((resolve, reject) => {
      const result = this.pluginGraph.nodes.map((p) => {
        return p.pluginInstance
      })

      // console.log(`this.pluginGraph.nodes`, this.pluginGraph.nodes)
      // console.log(`result`, result)

      resolve(result)
    })
  }

  public setProcessingOn(): Promise<void> {
    return new Promise((resolve, reject) => {
      // console.log(`this.pluginProcessorAudioNode.enable()`)
      this.pluginProcessorAudioNode.enable()

      this.wire()

      resolve()
    })
  }

  public setProcessingOff(): Promise<void> {
    return new Promise((resolve, reject) => {
      // console.log(`this.pluginProcessorAudioNode.disable()`)
      this.pluginProcessorAudioNode.disable()

      this.wire()

      resolve()
    })
  }

  public queryProcessingState(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // console.log(`this.pluginProcessorAudioNode.isEnabled()`)
      resolve(this.pluginProcessorAudioNode.isEnabled())
    })
  }

  // ONLY FOR ARIA-3D
  public setListeningThroughSpeakers(): Promise<void> {
    return new Promise((resolve, reject) => {
      // console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
      // console.log('setListeningThroughSpeakers')

      const speakersNode = this.getSpeakersPluginGraphNode()
      // console.log(speakersNode);
      if (speakersNode) {
        speakersNode.pluginInstance.enabled = true
        speakersNode.audioNode.enable()
      } else {
        console.error('Cannot get speakersNode')
      }

      const headphonesNode = this.getHeadphonesPluginGraphNode()
      // console.log(
      //   `setListeningThroughSpeakers - headphonesNode`,
      //   headphonesNode
      // )
      if (headphonesNode) {
        headphonesNode.pluginInstance.enabled = false
        headphonesNode.audioNode.disable()
      } else {
        console.error('Cannot get headphonesNode')
      }

      this.wire()

      resolve()
    })
  }

  // ONLY FOR ARIA-3D
  public setListeningThroughHeadphones(): Promise<void> {
    return new Promise((resolve, reject) => {
      // console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB')
      // console.log('setListeningThroughHeadphones')

      const speakersNode = this.getSpeakersPluginGraphNode()
      // console.log(speakersNode);
      if (speakersNode) {
        speakersNode.pluginInstance.enabled = false
        speakersNode.audioNode.disable()
      } else {
        console.error('Cannot get speakersNode')
      }

      const headphonesNode = this.getHeadphonesPluginGraphNode()
      // console.log(
      //   `setListeningThroughHeadphones - headphonesNode`,
      //   headphonesNode
      // )
      if (headphonesNode) {
        headphonesNode.pluginInstance.enabled = true
        headphonesNode.audioNode.enable()
      } else {
        console.error('Cannot get headphonesNode')
      }

      this.wire()

      resolve()
    })
  }

  public queryListeningDevice(): Promise<string> {
    // console.log(`this.pluginGraph.nodes.length`, this.pluginGraph.nodes.length)
    // console.log(`this.pluginGraph.nodes`, this.pluginGraph.nodes)
    const pluginGraphNodeHeadphones: PluginGraphNode =
      this.getHeadphonesPluginGraphNode()
    // console.log(`pluginGraphNodeHeadphones`, pluginGraphNodeHeadphones)
    if (pluginGraphNodeHeadphones) {
      if (pluginGraphNodeHeadphones.pluginInstance.enabled) {
        return new Promise((resolve, reject) => {
          // console.log(`this.pluginProcessorAudioNode.queryListeningDevice()`)
          resolve(`HEADPHONES`)
        })
      } else {
        return new Promise((resolve, reject) => {
          // console.log(`this.pluginProcessorAudioNode.queryListeningDevice()`)
          resolve(`SPEAKERS`)
        })
      }
    } else {
      return new Promise((resolve, reject) => {
        // console.log(`this.pluginProcessorAudioNode.queryListeningDevice()`)
        resolve(`SPEAKERS`)
      })
    }
  }

  private calculateNewPluginInstanceOrder(): number {
    var maximumOrder: number = 1

    if (this.pluginGraph.nodes.length > 0) {
      maximumOrder = this.pluginGraph.nodes
        .map((p) => p.pluginInstance.order)
        .reduce(function (a, b) {
          return Math.max(a, b)
        })

      maximumOrder += 1
    }

    return maximumOrder
  }

  enablePluginInstance(instanceId: string): Promise<PluginInstance> {
    // console.log(`enablePluginInstance - instanceId`, instanceId)
    return new Promise((resolve, reject) => {
      const graphNode: PluginGraphNode = this.pluginGraph.nodes.find(
        (p) => p.pluginInstance.instanceId === instanceId
      )

      if (graphNode) {
        // Object is read only ONLY in website NOT in extension .... WHY ????
        // graphNode.pluginInstance.enabled = true

        // This Works
        graphNode.pluginInstance = {
          ...graphNode.pluginInstance,
          enabled: true
        }

        graphNode.audioNode.enable()

        this.wire()

        resolve(graphNode.pluginInstance)
      } else {
        reject(new Error('No plugin instance found with id: ' + instanceId))
      }
    })
  }

  disablePluginInstance(instanceId: string): Promise<PluginInstance> {
    // console.log(`disablePluginInstance - instanceId`, instanceId)
    return new Promise((resolve, reject) => {
      const graphNode: PluginGraphNode = this.pluginGraph.nodes.find(
        (p) => p.pluginInstance.instanceId === instanceId
      )

      // console.log(`disablePluginInstance - graphNode1`, graphNode)

      if (graphNode) {
        // Object is read only ONLY in website NOT in extension .... WHY ????
        // try {
        //   graphNode.pluginInstance.enabled = false
        // } catch (error) {
        //   console.error(error)
        // }

        // This Works
        graphNode.pluginInstance = {
          ...graphNode.pluginInstance,
          enabled: false
        }

        graphNode.audioNode.disable()

        this.wire()

        resolve(graphNode.pluginInstance)
      } else {
        reject(new Error('No plugin instance found with id: ' + instanceId))
      }
    })
  }

  // ONLY FOR ARIA-3D
  private getSpeakersPluginGraphNode(): PluginGraphNode {
    return this.pluginGraph.nodes.find(
      (p) => p.pluginInstance.pluginId === 'ARCC'
    )
  }

  // ONLY FOR ARIA-3D
  private getHeadphonesPluginGraphNode(): PluginGraphNode {
    return this.pluginGraph.nodes.find(
      (p) => p.pluginInstance.pluginId === 'AREI'
    )
  }
}
