import { NgAudioEngineService } from '../../../audio-core/angular/ng-audio-engine.service'
import { XtcFilters } from '../XtcFilters'

export class ArccDspService {
  private daspXtcArccParameters: any
  private daspXtcArcc: any

  constructor() {
    this.daspXtcArccParameters = new (NgAudioEngineService.getInstance()
      .getDaspService()
      .getInstance().XtcArccParameters)()
    this.daspXtcArcc = new (NgAudioEngineService.getInstance()
      .getDaspService()
      .getInstance().XtcArcc)()
  }

  public calculate(audioContext: AudioContext, args: any): Promise<XtcFilters> {
    // console.log('calculate in XtcArccFilterCalculatorService');
    // console.log(args);

    var self = this
    return new Promise((resolve, reject) => {
      if (!args) {
        reject(new Error(`No args provided`))
        return
      }

      self.calculateFromDasp(audioContext, args).then(
        (result: any) => {
          var xtcFilters: XtcFilters = result
          resolve(xtcFilters)
        },
        (result: any) => {
          console.error('calculateFromDasp failed:')
          reject(result)
        }
      )
    })
  }

  /*
   * ARCC
   *
   * @param sampleRate
   * @param angle
   * @returns {XtcFilters}
   */
  private calculateFromDasp(
    audioContext: AudioContext,
    args: any
  ): Promise<XtcFilters> {
    // console.log('Calculate ARCC')
    // console.log('args', args)

    var self = this
    return new Promise((resolve, reject) => {
      if (!args) {
        reject(new Error(`No args provided`))
        return
      }
      var BPE = NgAudioEngineService.getInstance().getMemoryService().BPE
      var realArray: Array<number> = []

      self.calculateFeed(audioContext, args).then(
        (result: any) => {
          // console.log('FEED RESULT');
          // console.log(result[0]);
          // console.log(result[0].length);
          // console.log(result[0].left);

          // /////////////////////////////////////////////////////////////////////////
          // FEED
          // /////////////////////////////////////////////////////////////////////////
          var i: number = 0
          var lengthOfFeed: number = result[0].length

          // ipsilateral
          for (i = 0; i < lengthOfFeed; i++) {
            realArray.push(result[0].left[i])
          }

          // contralateral
          for (i = 0; i < lengthOfFeed; i++) {
            realArray.push(result[0].right[i])
          }

          // EDO

          // /////////////////////////////////////////////////////////////////////////
          // FILTER BANK
          // /////////////////////////////////////////////////////////////////////////
          const lengthOfFilterBank = args.numberOfBands
          const frequencies = args.frequencies
          const attenuations = args.attenuations

          for (i = 0; i < lengthOfFilterBank; i++) {
            // console.log('---------------------------------------------------');
            // console.log(xtcArccUserParameters.filterBankFrequenciesInHertz[i]);
            realArray.push(frequencies[i])
          }

          for (i = 0; i < lengthOfFilterBank; i++) {
            // console.log('---------------------------------------------------');
            // console.log(xtcArccUserParameters.filterBankAttenuationsInDb[i]);
            realArray.push(attenuations[i])
          }

          // /////////////////////////////////////////////////////////////////////////
          // Set real array
          // /////////////////////////////////////////////////////////////////////////
          NgAudioEngineService.getInstance()
            .getMemoryService()
            .setRealArray(realArray)

          var inverseFilterlength: number = lengthOfFeed * 1

          // INITIAL BAND LIMITED
          // self.daspXtcArccParameters.setFeed(
          //     xtcArccUserParameters.angle,
          //     xtcArccUserParameters.distance,
          //     audioContext.sampleRate,
          //     self.getMemoryService().getRealHeap() + 0,
          //     self.getMemoryService().getRealHeap() + 1 * BPE * lengthOfFeed,
          //     lengthOfFeed);

          // EXP WITH FILTER BANK
          self.daspXtcArccParameters.setForFilterBank(
            args.angle,
            args.distance,
            audioContext.sampleRate,
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealHeap() + 0,
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealHeap() +
              1 * BPE * lengthOfFeed,
            lengthOfFeed,
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealHeap() +
              2 * BPE * lengthOfFeed +
              0,
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealHeap() +
              2 * BPE * lengthOfFeed +
              1 * BPE * lengthOfFilterBank,
            lengthOfFilterBank
          )

          // CURRENT BAND LIMITED
          // self.daspXtcArccParameters.setForBandLimited(
          //     xtcArccUserParameters.angle,
          //     xtcArccUserParameters.distance,
          //     audioContext.sampleRate,
          //     self.getMemoryService().getRealHeap() + 0,
          //     self.getMemoryService().getRealHeap() + 1 * BPE * lengthOfFeed,
          //     lengthOfFeed,
          //     self.getMemoryService().getRealHeap() + 2 * BPE * lengthOfFeed + 0,
          //     self.getMemoryService().getRealHeap() + 2 * BPE * lengthOfFeed + 1 * BPE * lengthOfFilterBank,
          //     lengthOfFilterBank
          // );

          // CALC
          self.daspXtcArcc.calculate(
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealHeap() +
              2 * BPE * lengthOfFeed +
              0,
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealHeap() +
              2 * BPE * lengthOfFeed +
              1 * BPE * inverseFilterlength,
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealHeap() +
              2 * BPE * lengthOfFeed +
              2 * BPE * inverseFilterlength,
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealHeap() +
              2 * BPE * lengthOfFeed +
              3 * BPE * inverseFilterlength,
            inverseFilterlength,
            self.daspXtcArccParameters
          )

          // ATTENTION! Without BPE for getRealArray;
          var offset: number = 2 * lengthOfFeed + 0
          var inverseIpsilateralLeftSamples: Array<number> =
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealArray(offset, inverseFilterlength)

          offset = offset + inverseFilterlength
          var inverseContralateralRightSamples: Array<number> =
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealArray(offset, inverseFilterlength)

          offset = offset + inverseFilterlength
          var inverseContralateralLeftSamples: Array<number> =
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealArray(offset, inverseFilterlength)

          offset = offset + inverseFilterlength
          var inverseIpsilateralRightSamples: Array<number> =
            NgAudioEngineService.getInstance()
              .getMemoryService()
              .getRealArray(offset, inverseFilterlength)

          // console.log(inverseIpsilateralLeftSamples);
          // console.log(inverseContralateralLeftSamples);
          // console.log(inverseIpsilateralRightSamples);
          // console.log(inverseContralateralRightSamples);

          // TEST
          let myGain = 1
          if (args.numberOfBands === 11) {
            myGain = 0.09
          } else if (args.numberOfBands === 21) {
            myGain = 0.047
          }
          // myGain = 1;

          for (let i = 0; i < inverseIpsilateralLeftSamples.length; i++) {
            inverseIpsilateralLeftSamples[i] =
              myGain * inverseIpsilateralLeftSamples[i]
            inverseContralateralRightSamples[i] =
              myGain * inverseContralateralRightSamples[i]
            inverseContralateralLeftSamples[i] =
              myGain * inverseContralateralLeftSamples[i]
            inverseIpsilateralRightSamples[i] =
              myGain * inverseIpsilateralRightSamples[i]
          }

          var xtcFilters: XtcFilters = new XtcFilters(
            inverseIpsilateralLeftSamples,
            inverseContralateralRightSamples,
            inverseContralateralLeftSamples,
            inverseIpsilateralRightSamples
          )

          xtcFilters.createAudioBuffers(audioContext)

          resolve(xtcFilters)
        },
        (result: any) => {
          console.error('calculateFeed failed:')
          reject(result)
        }
      )
    })
  }

  /*
   * FEED
   */
  private calculateFeed(audioContext: AudioContext, args: any): Promise<any> {
    return this.calculateDeltaFeed(args)
    // return this.calculateHrtfFeed('https://data.aria3d.com', audioContext, xtcArccUserParameters);
  }

  /*
   * DELTA FEED
   *
   * @returns {Promise<T>|Promise}
   */
  private calculateDeltaFeed(args: any): Promise<any> {
    return new Promise((resolve, reject) => {
      var feed = []
      var left = []
      var right = []

      // ipsilateral Left
      var i: number = 0
      var lengthOfFeed: number = args.filterLength

      for (i = 0; i < lengthOfFeed; i++) {
        if (i === 0) {
          left.push(1)
        } else {
          left.push(0)
        }
      }

      // contralateral Left
      for (i = 0; i < lengthOfFeed; i++) {
        right.push(0)
      }

      feed.push({
        left: left,
        right: right,
        length: lengthOfFeed
      })

      resolve(feed)
    })
  }
}

export default ArccDspService
