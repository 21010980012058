/*
 * An Audio Node that supports any type of Media Content.
 * Wraps all(?) the available audio input variations.
 */
export class MySpecialAudioInputNode {
  public htmlMediaElement: HTMLMediaElement
  public mediaElementAudioSourceNode: MediaElementAudioSourceNode

  private audioBuffer: AudioBuffer
  public audioBufferSourceNode: AudioBufferSourceNode

  public mediaStream: MediaStream
  public mediaStreamAudioSourceNode: MediaStreamAudioSourceNode

  public videoPlaceholder: HTMLElement

  constructor() {
    this.htmlMediaElement = null
    this.mediaElementAudioSourceNode = null

    this.audioBuffer = null
    this.audioBufferSourceNode = null

    this.mediaStream = null
    this.mediaStreamAudioSourceNode = null

    this.videoPlaceholder = null
  }

  /*
   * setAudioBuffer.
   *
   * @param audioBuffer
   * @param audioContext
   */
  public setAudioBuffer(audioBuffer: AudioBuffer, audioContext: AudioContext) {
    this.audioBuffer = audioBuffer
    this.createAudioBufferSourceNode(audioContext)
  }

  /*
   * createAudioBufferSourceNode
   *
   * @param audioContext
   */
  private createAudioBufferSourceNode(audioContext: AudioContext) {
    this.audioBufferSourceNode = audioContext.createBufferSource()
    this.audioBufferSourceNode.buffer = this.audioBuffer

    var self = this
    this.audioBufferSourceNode.onended = (event: Event) => {}
  }

  /*
   * getAudioNode.
   *
   * @returns {any}
   */
  public getAudioNode(): AudioNode {
    if (
      this.htmlMediaElement != null &&
      this.mediaElementAudioSourceNode != null
    ) {
      return this.mediaElementAudioSourceNode
    } else if (this.audioBuffer != null && this.audioBufferSourceNode != null) {
      return this.audioBufferSourceNode
    } else if (
      this.mediaStream != null &&
      this.mediaStreamAudioSourceNode != null
    ) {
      return this.mediaStreamAudioSourceNode
    } else {
      console.error(
        'SEVERE: Cannot getAudioNode - Both mediaElementAudioSourceNode and audioBufferSourceNode are null'
      )
      alert(
        'SEVERE: Cannot getAudioNode - Both mediaElementAudioSourceNode and audioBufferSourceNode are null'
      )
      return null
    }
  }

  /*
   * getDuration.
   *
   * @returns {number}
   */
  public getDuration(): number {
    if (this.htmlMediaElement != null) {
      return this.htmlMediaElement.duration
    } else if (this.audioBuffer != null) {
      return this.audioBuffer.duration
    } else {
      return -1
    }
  }

  /*
   * Disconnects AudioBufferSourceNode
   */
  public disconnectMediaElementAudioSourceNode() {
    if (this.mediaElementAudioSourceNode != null) {
      this.mediaElementAudioSourceNode.disconnect(0)
      this.mediaElementAudioSourceNode = null
    }

    if (this.htmlMediaElement != null) {
      this.htmlMediaElement.pause()
      this.htmlMediaElement = null
    }
  }

  /*
   * Disconnects AudioBufferSourceNode
   */
  public disconnectAudioBufferSourceNode() {
    if (this.audioBufferSourceNode != null) {
      this.audioBufferSourceNode.disconnect(0)
      this.audioBufferSourceNode = null
    }
  }

  /*
   * Disconnects AudioBufferSourceNode
   */
  public disconnectMediaStreamSourceNode() {
    if (this.mediaStream != null) {
      this.mediaStream.getAudioTracks()[0].stop()
      this.mediaStream = null
    }

    if (this.mediaStreamAudioSourceNode != null) {
      this.mediaStreamAudioSourceNode.disconnect(0)
      this.mediaStreamAudioSourceNode = null
    }
  }
}
