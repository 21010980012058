import { ParameterKeyValue } from '../../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../../audio-core/web-audio-api/audio-node/IAudioNode'
import { DynamicConvolver } from '../dynamic-convolver/DynamicConvolver'

export class VirtualSourceAudioNode
  extends AbstractAudioNode
  implements IAudioNode
{
  private binauralImpulseResponse: AudioBuffer
  private binauralConvolver: DynamicConvolver
  private useCrossOver: boolean
  private crossOverFrequency: number
  private input: GainNode
  private output: GainNode
  private lowPass: BiquadFilterNode
  private highPass: BiquadFilterNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>,
    binauralImpulseResponse: AudioBuffer
  ) {
    super(audioContext, parameters)

    this.binauralImpulseResponse = binauralImpulseResponse
    this.useCrossOver = true
    this.crossOverFrequency = 200

    this.wire()
  }

  wire(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.binauralConvolver = new DynamicConvolver(
        this.audioContext,
        null,
        this.binauralImpulseResponse
      )

      if (this.useCrossOver) {
        this.input = this.audioContext.createGain()
        this.input.gain.setValueAtTime(1, this.audioContext.currentTime)

        this.output = this.audioContext.createGain()
        this.output.gain.setValueAtTime(1, this.audioContext.currentTime)

        this.lowPass = this.audioContext.createBiquadFilter()
        this.lowPass.type = 'lowpass'
        this.lowPass.frequency.setValueAtTime(
          this.crossOverFrequency,
          this.audioContext.currentTime
        )

        this.highPass = this.audioContext.createBiquadFilter()
        this.highPass.type = 'highpass'
        this.highPass.frequency.setValueAtTime(
          this.crossOverFrequency,
          this.audioContext.currentTime
        )

        // Connect
        this.input.connect(this.lowPass)
        this.input.connect(this.highPass)

        this.lowPass.connect(this.output)
        this.highPass.connect(this.binauralConvolver.getInput())
        this.binauralConvolver.getOutput().connect(this.output)
      } else {
      }

      resolve()
    })
  }

  getInput(): AudioNode {
    if (this.useCrossOver) {
      return this.input
    } else {
      return this.binauralConvolver.getInput()
    }
  }

  getOutput(): AudioNode {
    if (this.useCrossOver) {
      return this.output
    } else {
      return this.binauralConvolver.getOutput()
    }
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    if (this.useCrossOver) {
      this.output.connect(destination)
    } else {
      this.binauralConvolver.getOutput().connect(destination)
    }
  }

  disconnect(output?: number): void {
    if (this.useCrossOver) {
      this.output.disconnect(output)
    } else {
      this.binauralConvolver.getOutput().disconnect(output)
    }
  }

  /*
   * Update BinauralImpulseResponse.
   *
   * @param binauralImpulseResponse
   */
  public updateBinauralImpulseResponse(
    binauralImpulseResponse: AudioBuffer
  ): void {
    // console.log('updateBinauralImpulseResponse');
    this.binauralImpulseResponse = binauralImpulseResponse
    this.binauralConvolver.updateImpulseResponse(binauralImpulseResponse)
  }
}
