import { PluginInstance } from './PluginInstance'

export default class PluginInstanceService {
  public getValue(pluginInstance: PluginInstance | null, key: string): any {
    if (!pluginInstance) {
      return null
    }
    return pluginInstance?.parameters?.find((p) => p.parameter.key === key)
      ?.parameter.value
  }
}
