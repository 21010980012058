import { HrtfDatabase } from '../../../audio-core/hrtf/HrtfDatabase'
import { HrtfLoader } from '../../../audio-core/hrtf/HrtfLoader'
import { VirtualStereoFilters } from './VirtualStereoFilters'

export class VirtualStereoFilterCalculatorService {
  private hrtfLoader: HrtfLoader

  constructor(hrtfLoader: HrtfLoader) {
    this.hrtfLoader = hrtfLoader
  }

  /*
   * Calculate.
   *
   * @param audioContext
   * @param virtualStereoUserParameters
   * @returns {Promise<T>|Promise|Promise<R>}
   */
  public calculate(
    baseUrlData: string,
    audioContext: AudioContext,
    args: any,
    browser: any
  ): Promise<VirtualStereoFilters> {
    var self = this
    return new Promise(function (resolve, reject) {
      if (!args) {
        reject()
        return
      }

      self.hrtfLoader.getHrtf(baseUrlData, args.hrtf, 'full', browser).then(
        (result: any) => {
          var hrtfDatabase: HrtfDatabase = result
          hrtfDatabase.createAudioBuffers(audioContext)

          var leftAngle = args.frontAngle
          var rightAngle = 360 - leftAngle
          var binauralImpulseResponseForLeftVirtualSource: AudioBuffer =
            hrtfDatabase.getHrtfRecord(audioContext, leftAngle, 0).buffer
          var binauralImpulseResponseForRightVirtualSource: AudioBuffer =
            hrtfDatabase.getHrtfRecord(audioContext, rightAngle, 0).buffer

          var virtualStereoFilters: VirtualStereoFilters =
            new VirtualStereoFilters(
              binauralImpulseResponseForLeftVirtualSource,
              binauralImpulseResponseForRightVirtualSource
            )

          resolve(virtualStereoFilters)
        },
        (result: any) => {
          reject(result)
        }
      )
    })
  }
}
