import { PluginGraphNode } from './PluginGraphNode'
import { PluginInstanceParameterKeyValue } from '../plugins/PluginInstanceParameterKeyValue'
import PluginInstance from '../plugins/PluginInstance'

export class PluginGraph {
  nodes: Array<PluginGraphNode> = []
  sorted: Array<number>
  sortedAndEnabledOrder: Array<number>
  previousSorted: Array<number>

  constructor(public audioContext: AudioContext) {}

  addNode(pluginGraphNode: PluginGraphNode): void {
    // console.log('addNode')
    // console.log(pluginGraphNode)

    const nodesWithTheSameOrder = this.nodes.filter(
      (node: PluginGraphNode) =>
        node.pluginInstance.order === pluginGraphNode.pluginInstance.order
    )

    if (nodesWithTheSameOrder.length) {
      const message = `Order must be unique for the pluginGraphNodes. Problem with order: ${pluginGraphNode.pluginInstance.order}`

      throw new Error(message)
    }

    this.nodes.push(pluginGraphNode)
    this.sorted = this.nodes.map((p) => p.pluginInstance.order).sort()
    this.calculateSortedAndEnabledOrder()

    // console.log(`this.nodes`, this.nodes)
  }

  removeNode(pluginGraphNode: PluginGraphNode): void {
    // console.log('removeNode');
    // console.log(pluginGraphNode);
    // TODO
  }

  removeAllNodes(): void {
    // console.log('audio-core, PluginGraph, removeAllNodes');

    this.nodes = []
    this.sorted = []
    this.sortedAndEnabledOrder = []
  }

  hasNodes(): boolean {
    this.calculateSortedAndEnabledOrder()
    return this.sortedAndEnabledOrder.length > 0
  }

  wire(): Promise<any> {
    return new Promise<void>((resolve) => {
      // console.log('wire in PluginGraph');

      this.calculateSortedAndEnabledOrder()
      // console.log(this.sortedAndEnabledOrder);

      this.disconnectAll()

      if (this.sortedAndEnabledOrder.length === 1) {
        // console.log('this.sorted.length === 1');
        // console.log(this.getFirstGraphNode());
        // console.log(this.getLastGraphNode());
      } else if (this.sortedAndEnabledOrder.length > 1) {
        // console.log('this.sorted.length > 1');
        for (var i = 1; i < this.sortedAndEnabledOrder.length; i++) {
          const p1 = this.getPluginGraphNodeWithOrder(
            this.sortedAndEnabledOrder[i - 1]
          )
          const p2 = this.getPluginGraphNodeWithOrder(
            this.sortedAndEnabledOrder[i]
          )

          if (p1 && p2 && p2.audioNode && p2.audioNode.getInput()) {
            const p2AudioNodeInput = p2.audioNode.getInput()
            if (p2AudioNodeInput) {
              p1.audioNode.connect(p2AudioNodeInput)
            }
          }

          // console.log(p1.pluginInstance.pluginId + ' (' + p1.pluginInstance.order + ') -> ' + p2.pluginInstance.pluginId + '(' + p2.pluginInstance.order + ')');
        }
      }

      resolve()
    })
  }

  private disconnectAll() {
    for (var i = 0; i < this.nodes.length; i++) {
      this.nodes[i].audioNode.disconnect()
    }
  }

  private calculateSortedAndEnabledOrder() {
    this.sortedAndEnabledOrder = []
    this.sorted = this.nodes.map((p) => p.pluginInstance.order).sort()

    for (var i = 0; i < this.sorted.length; i++) {
      const p = this.getPluginGraphNodeWithOrder(this.sorted[i])
      // TODO
      // if (p.audioNode.isEnabled()) {
      if (p && p.pluginInstance.enabled) {
        this.sortedAndEnabledOrder.push(p.pluginInstance.order)
      }
    }
  }

  private getPluginGraphNodeWithOrder(
    order: number
  ): PluginGraphNode | undefined {
    return this.nodes.find((p) => p.pluginInstance.order === order)
  }

  getFirstGraphNode(): PluginGraphNode | undefined {
    if (this.hasNodes()) {
      // console.log('getFirstGraphNode');
      // console.log(this.nodes[0]);
      return this.getPluginGraphNodeWithOrder(this.sortedAndEnabledOrder[0])
    } else {
      return undefined
    }
  }

  getLastGraphNode(): PluginGraphNode | undefined {
    if (this.hasNodes()) {
      // console.log('getLastGraphNode');
      // console.log(this.nodes[this.nodes.length - 1]);
      return this.getPluginGraphNodeWithOrder(
        this.sortedAndEnabledOrder[this.sortedAndEnabledOrder.length - 1]
      )
    } else {
      return undefined
    }
  }

  public setPluginGraphNodeParameter(
    audioContext: AudioContext,
    pluginInstanceParameterKeyValue: PluginInstanceParameterKeyValue,
    browser: any
  ): Promise<PluginInstance> {
    return new Promise<PluginInstance>((resolve, reject) => {
      var pluginGraphNode: PluginGraphNode | undefined = this.nodes.find(
        (p: PluginGraphNode) =>
          p.pluginInstance.pluginId ===
            pluginInstanceParameterKeyValue.pluginId &&
          p.pluginInstance.instanceId ===
            pluginInstanceParameterKeyValue.instanceId
      )

      // console.log(pluginGraphNode);

      if (pluginGraphNode) {
        pluginGraphNode.pluginService
          .setParameterValue(
            audioContext,
            pluginGraphNode.audioNode,
            pluginInstanceParameterKeyValue.parameter,
            browser
          )
          .then(
            (result) => {
              // TODO the following is redundant
              // Update - additionally - the pluginInstance inside Plugin Graph Node

              const pIpKv: PluginInstanceParameterKeyValue | undefined =
                pluginGraphNode?.pluginInstance?.parameters?.find(
                  (p) =>
                    p.parameter.key ===
                    pluginInstanceParameterKeyValue.parameter.key
                )

              if (pIpKv) {
                const newPluginInstanceParameterKeyValue = {
                  pluginId: pIpKv.pluginId,
                  instanceId: pIpKv.instanceId,
                  parameter: {
                    key: pIpKv.parameter.key,
                    value: pluginInstanceParameterKeyValue.parameter.value
                  },
                  debounceTime: pIpKv.debounceTime
                }

                // console.log(
                //   `pluginGraphNode?.pluginInstance 1`,
                //   pluginGraphNode?.pluginInstance
                // )
                // console.log(
                //   `newPluginInstanceParameterKeyValue`,
                //   newPluginInstanceParameterKeyValue
                // )

                // This Works
                pluginGraphNode.pluginInstance = {
                  ...pluginGraphNode.pluginInstance,
                  parameters: [
                    ...pluginGraphNode.pluginInstance.parameters.filter(
                      (piPkv) =>
                        piPkv.parameter.key !==
                        newPluginInstanceParameterKeyValue.parameter.key
                    ),
                    newPluginInstanceParameterKeyValue
                  ]
                }

                // This ???
                // const newPluginInstance = {
                //   ...pluginGraphNode.pluginInstance,
                //   parameters: [
                //     ...pluginGraphNode.pluginInstance.parameters,
                //     newPluginInstanceParameterKeyValue
                //   ]
                // }

                // pluginGraphNode.pluginInstance = { ...newPluginInstance }

                // THIS DOES NOT WORK
                // const paremeterChanged =
                //   pluginGraphNode?.pluginInstance.parameters.find(
                //     (piPkv) =>
                //       piPkv.parameter.key ===
                //       newPluginInstanceParameterKeyValue.parameter.key
                //   )
                // console.log(`paremeterChanged`, paremeterChanged)
                // paremeterChanged.parameter =
                //   newPluginInstanceParameterKeyValue.parameter

                // console.log(
                //   `pluginGraphNode?.pluginInstance 2`,
                //   pluginGraphNode?.pluginInstance
                // )

                // pIpKv.parameter.value =
                //   pluginInstanceParameterKeyValue.parameter.value
                // console.log(`pIpKv`, pIpKv)
              }

              resolve(pluginGraphNode?.pluginInstance)
            },
            (error) => {
              reject(error)
            }
          )
      } else {
        reject(new Error('No pluginGraphNode'))
      }
    })
  }
}

export default PluginGraph
