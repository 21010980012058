import { ArccDspService } from './../domain/audio-plugins-background/arcc/services/arcc-dsp.service'
import PluginInstanceFactoryService from '../domain/audio-background/plugin-instance-factory.service'
import { ArccPluginService } from '../domain/audio-plugins-background/arcc/arcc-plugin.service'
import { AreiPluginService } from '../domain/audio-plugins-background/arei/arei-plugin.service'
import { CapturePluginService } from '../domain/audio-plugins-background/capture/capture-plugin.service'
import { ConvolverPluginService } from '../domain/audio-plugins-background/convolver/convolver-plugin.service'
import { DynamicsCompressorPluginService } from '../domain/audio-plugins-background/dynamics-compressor/dynamics-compressor-plugin.service'
import { EqBiquadPluginService } from '../domain/audio-plugins-background/eq-biquad/eq-biquad-plugin.service'
import { GainPluginService } from '../domain/audio-plugins-background/gain/gain-plugin.service'
import { RacePluginService } from '../domain/audio-plugins-background/race/race-plugin.service'
import { ReverbPluginService } from '../domain/audio-plugins-background/reverb/reverb-plugin.service'
import { StereoWideningPluginService } from '../domain/audio-plugins-background/stereo-widening/stereo-widening-plugin.service'
import ArccPluginParametersService from '../domain/audio-plugins-parameters/arcc/arcc-plugin-parameters.service'
import AreiPluginParametersService from '../domain/audio-plugins-parameters/arei/arei-plugin-parameters.service'
import { CapturePluginParametersService } from '../domain/audio-plugins-parameters/capture/capture-plugin-parameters.service'
import { DynamicsCompressorPluginParametersService } from '../domain/audio-plugins-parameters/dynamics-compressor/dynamics-compressor-plugin-parameters.service'
import { EqBiquadPluginParametersService } from '../domain/audio-plugins-parameters/eq-biquad/eq-biquad-plugin-parameters.service'
import { PluginGainParametersService } from '../domain/audio-plugins-parameters/gain/plugin-gain-parameters.service'
import { RaceDspService } from '../domain/audio-plugins-background/arei/race/race-dsp.service'
import { WebWorkerService } from '../domain/audio-plugins-background/race/web-worker.service'
import { PluginRaceParametersService } from '../domain/audio-plugins-parameters/race/plugin-race-parameters.service'
import { StereoWideningPluginParametersService } from '../domain/audio-plugins-parameters/stereo-widening/stereo-widening-plugin-parameters.service'
import { ConvolverPluginParametersService } from '../domain/audio-plugins-parameters/convolver/convolver-plugin-parameters.service'
import { ReverbPluginParametersService } from '../domain/audio-plugins-parameters/reverb/reverb-plugin-parameters.service'
import BackgroundMediaPlayerService from '../domain/audio-background/background-media-player.service'
import BackgroundStreamPlayerService from '../domain/audio-background/background-stream-player.service'
import PluginGraphUiService from '../domain/audio-foreground/plugin-graph-ui.service'
import ExtensionPluginBackgroundControllerImpl from '../domain/audio-background/extension/ExtensionPluginBackgroundControllerImpl'
import { IPluginBackgroundController } from '../domain/audio-core/plugins/IPluginBackgroundController'
import PluginInstanceService from '../domain/audio-core/plugins/PluginInstanceService'
import AudioInputNodeFactory from '../domain/audio-core/web-audio-api/audio-input/AudioInputNodeFactory'
import ExtensionContentPluginBackgroundControllerImpl from '../domain/audio-background/extension/ExtensionContentPluginBackgroundControllerImpl'

import DefaultSettingsService from '../domain/settings/DefaultSettingsService'

/**
 * Do it yourself (DIY) Dependency Injection (DI) mechanism
 */
export default class ApiRegistry {
  // ===============================================================================================
  // STATE
  // ===============================================================================================

  private static instance: ApiRegistry
  private static isLoggingEnabled: boolean

  // ===============================================================================================
  // SINGLETON
  // ===============================================================================================

  public static getInstance(): ApiRegistry {
    if (!ApiRegistry.instance) {
      // console.log(`New instance for ApiRegistry`)
      ApiRegistry.instance = new ApiRegistry()
      this.isLoggingEnabled = process.env.IS_LOGGING_ENABLED === 'true'
      // console.log('Logging is enabled:', this.isLoggingEnabled)
    }

    return ApiRegistry.instance
  }

  // ===============================================================================================
  // PROVIDED SERVICES
  // ===============================================================================================
  public arccPluginParametersService: ArccPluginParametersService
  public areiPluginParametersService: AreiPluginParametersService
  public capturePluginParametersService: CapturePluginParametersService
  public dynamicsCompressorPluginParametersService: DynamicsCompressorPluginParametersService
  public eqBiquadPluginParametersService: EqBiquadPluginParametersService
  public pluginGainParametersService: PluginGainParametersService
  public pluginRaceParametersService: PluginRaceParametersService
  public convolverPluginParametersService: ConvolverPluginParametersService
  public reverbPluginParametersService: ReverbPluginParametersService
  public stereoWideningPluginParametersService: StereoWideningPluginParametersService

  public arccPluginService: ArccPluginService
  public arccDspService: ArccDspService
  public areiPluginService: AreiPluginService
  public capturePluginService: CapturePluginService
  public dynamicsCompressorPluginService: DynamicsCompressorPluginService
  public eqBiquadPluginService: EqBiquadPluginService
  public gainPluginService: GainPluginService
  public racePluginService: RacePluginService
  public convolverPluginService: ConvolverPluginService
  public reverbPluginService: ReverbPluginService
  public stereoWideningPluginService: StereoWideningPluginService

  public pluginInstanceFactoryService: PluginInstanceFactoryService

  public backgroundMediaPlayerService: BackgroundMediaPlayerService
  public backgroundStreamPlayerService: BackgroundStreamPlayerService

  public pluginGraphUiService: PluginGraphUiService

  public extensionPluginBackgroundController: ExtensionPluginBackgroundControllerImpl
  public extensionContentPluginBackgroundControllerImpl: ExtensionContentPluginBackgroundControllerImpl

  public pluginInstanceService: PluginInstanceService

  public audioInputNodeFactory: AudioInputNodeFactory

  public defaultSettingsService: DefaultSettingsService

  // ===============================================================================================
  // CONSTRUCTOR
  // ===============================================================================================
  private constructor() {
    // Plugin Parameter Services
    this.arccPluginParametersService = new ArccPluginParametersService()
    this.areiPluginParametersService = new AreiPluginParametersService()
    this.capturePluginParametersService = new CapturePluginParametersService()
    this.dynamicsCompressorPluginParametersService =
      new DynamicsCompressorPluginParametersService()
    this.eqBiquadPluginParametersService = new EqBiquadPluginParametersService()
    this.pluginGainParametersService = new PluginGainParametersService()
    this.pluginRaceParametersService = new PluginRaceParametersService()
    this.convolverPluginParametersService =
      new ConvolverPluginParametersService()
    this.reverbPluginParametersService = new ReverbPluginParametersService()
    this.stereoWideningPluginParametersService =
      new StereoWideningPluginParametersService()

    // Plugin Services
    this.arccDspService = new ArccDspService()
    this.arccPluginService = new ArccPluginService(
      this.arccPluginParametersService,
      this.arccDspService
    )
    this.areiPluginService = new AreiPluginService(
      this.areiPluginParametersService
    )
    this.capturePluginService = new CapturePluginService(
      this.capturePluginParametersService
    )
    this.dynamicsCompressorPluginService = new DynamicsCompressorPluginService(
      this.dynamicsCompressorPluginParametersService
    )
    this.eqBiquadPluginService = new EqBiquadPluginService(
      this.eqBiquadPluginParametersService
    )
    this.gainPluginService = new GainPluginService(
      this.pluginGainParametersService
    )
    this.racePluginService = new RacePluginService(
      this.pluginRaceParametersService,
      new WebWorkerService(),
      new RaceDspService()
    )
    this.convolverPluginService = new ConvolverPluginService(
      this.convolverPluginParametersService
    )
    this.reverbPluginService = new ReverbPluginService(
      this.reverbPluginParametersService
    )
    this.stereoWideningPluginService = new StereoWideningPluginService(
      this.stereoWideningPluginParametersService
    )

    this.pluginInstanceFactoryService = new PluginInstanceFactoryService(
      this.arccPluginService,
      this.areiPluginService,
      this.capturePluginService,
      this.dynamicsCompressorPluginService,
      this.eqBiquadPluginService,
      this.gainPluginService,
      this.racePluginService,
      this.convolverPluginService,
      this.reverbPluginService,
      this.stereoWideningPluginService
    )

    this.pluginGraphUiService = new PluginGraphUiService(
      this.arccPluginParametersService,
      this.areiPluginParametersService,
      this.capturePluginParametersService,
      this.dynamicsCompressorPluginParametersService,
      this.eqBiquadPluginParametersService,
      this.pluginGainParametersService,
      this.pluginRaceParametersService,
      this.convolverPluginParametersService,
      this.reverbPluginParametersService,
      this.stereoWideningPluginParametersService
    )

    this.pluginInstanceService = new PluginInstanceService()

    this.audioInputNodeFactory = new AudioInputNodeFactory()

    this.backgroundMediaPlayerService = new BackgroundMediaPlayerService(
      this.pluginInstanceFactoryService,
      this.audioInputNodeFactory
    )

    this.backgroundStreamPlayerService = new BackgroundStreamPlayerService(
      this.pluginInstanceFactoryService,
      this.audioInputNodeFactory
    )

    this.defaultSettingsService = new DefaultSettingsService()
  }

  getExtensionPluginBackgroundController(browser: any) {
    if (typeof this.extensionPluginBackgroundController === `undefined`) {
      this.extensionPluginBackgroundController =
        new ExtensionPluginBackgroundControllerImpl(browser)
    }
    return this.extensionPluginBackgroundController
  }

  getExtensionContentPluginBackgroundControllerImpl(browser: any) {
    if (
      typeof this.extensionContentPluginBackgroundControllerImpl === `undefined`
    ) {
      this.extensionContentPluginBackgroundControllerImpl =
        new ExtensionContentPluginBackgroundControllerImpl(browser)
    }
    return this.extensionContentPluginBackgroundControllerImpl
  }

  getBackgroundController(browser: any): IPluginBackgroundController {
    // console.log(`getBackgroundController - typeof window:`, typeof window)
    // console.log(`getBackgroundController - typeof browser:`, typeof browser)

    if (typeof window !== 'undefined') {
      if (typeof browser !== 'undefined') {
        const useBackgroundPage = false // !!browser.tabCapture

        // console.log(
        //   `ApiRegistry - Inside Extension, useBackgroundPage`,
        //   useBackgroundPage
        // )

        if (useBackgroundPage) {
          return ApiRegistry.getInstance().getExtensionPluginBackgroundController(
            browser
          )
        } else {
          return ApiRegistry.getInstance().getExtensionContentPluginBackgroundControllerImpl(
            browser
          )
        }
      } else {
        // console.log(`ApiRegistry - Not Extension`)
        return ApiRegistry.getInstance().backgroundMediaPlayerService
      }
    } else {
      return null
    }
  }
}
