import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'

export class AreiPluginParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static METHOD: string = 'method'

  public static FRONT_HRTF: string = 'frontHrtf'
  public static SIDE_HRTF: string = 'sideHrtf'
  public static REAR_HRTF: string = 'rearHrtf'

  public static FRONT_ANGLE: string = 'frontAngle'
  public static SIDE_ANGLE: string = 'sideAngle'
  public static REAR_ANGLE: string = 'rearAngle'

  public static FRONT_ELEVATION: string = 'frontElevation'
  public static SIDE_ELEVATION: string = 'sideElevation'
  public static REAR_ELEVATION: string = 'rearElevation'

  public static FRONT_GAIN: string = 'frontGain'
  public static SIDE_GAIN: string = 'sideGain'
  public static REAR_GAIN: string = 'rearGain'

  public static XTC_FILTER_LENGTH: string = 'xtcFilterLength'
  public static XTC_FREQUENCIES_IN_HZ: string = 'xtcFrequencies'
  public static XTC_ATTENUATIONS_IN_DB: string = 'xtcAttenuations'
  public static XTC_ANGLE: string = 'xtcAngle'
  public static XTC_DELAY_IN_SAMPLES: string = 'xtcDelayInSamples'

  // private static DEFAULT_METHOD: string = 'virtualAmbio';
  // private static DEFAULT_METHOD: string = 'virtualStereo'
  // private static DEFAULT_METHOD: string = 'virtualCtc'
  private static DEFAULT_METHOD: string = 'resonanceAudio'
  // private static DEFAULT_HRTF: string = 'HRIR_CIRC360.aria3d.hrtf';
  private static DEFAULT_FRONT_HRTF: string = 'HRIR_CIRC360.aria3d.hrtf'
  private static DEFAULT_SIDE_HRTF: string = 'dry.aria3d.hrtf'
  private static DEFAULT_REAR_HRTF: string = 'HRIR_CIRC360.aria3d.hrtf'
  private static DEFAULT_FRONT_ANGLE: number = 10
  private static DEFAULT_SIDE_ANGLE: number = 60
  private static DEFAULT_REAR_ANGLE: number = 170

  private static DEFAULT_FRONT_ELEVATION: number = 0
  private static DEFAULT_SIDE_ELEVATION: number = 0
  private static DEFAULT_REAR_ELEVATION: number = 0

  private static DEFAULT_FRONT_GAIN: number = 1
  private static DEFAULT_SIDE_GAIN: number = 1
  private static DEFAULT_REAR_GAIN: number = 1

  private static DEFAULT_XTC_FILTER_LENGTH: number = 512
  private static DEFAULT_XTC_FREQUENCIES_IN_HZ: Array<number> = [1000]
  private static DEFAULT_XTC_ATTENUATIONS_IN_DB: Array<number> = [-3]
  private static DEFAULT_XTC_ANGLE: number = 9
  private static DEFAULT_XTC_DELAY_IN_SAMPLES: number = 3

  constructor() {
    super()

    this.parameters.push({
      key: AreiPluginParametersService.METHOD,
      value: AreiPluginParametersService.DEFAULT_METHOD
    })

    this.parameters.push({
      key: AreiPluginParametersService.FRONT_HRTF,
      value: AreiPluginParametersService.DEFAULT_FRONT_HRTF
    })

    this.parameters.push({
      key: AreiPluginParametersService.SIDE_HRTF,
      value: AreiPluginParametersService.DEFAULT_SIDE_HRTF
    })

    this.parameters.push({
      key: AreiPluginParametersService.REAR_HRTF,
      value: AreiPluginParametersService.DEFAULT_REAR_HRTF
    })

    this.parameters.push({
      key: AreiPluginParametersService.FRONT_ANGLE,
      value: AreiPluginParametersService.DEFAULT_FRONT_ANGLE
    })

    this.parameters.push({
      key: AreiPluginParametersService.SIDE_ANGLE,
      value: AreiPluginParametersService.DEFAULT_SIDE_ANGLE
    })

    this.parameters.push({
      key: AreiPluginParametersService.REAR_ANGLE,
      value: AreiPluginParametersService.DEFAULT_REAR_ANGLE
    })

    this.parameters.push({
      key: AreiPluginParametersService.FRONT_ELEVATION,
      value: AreiPluginParametersService.DEFAULT_FRONT_ELEVATION
    })

    this.parameters.push({
      key: AreiPluginParametersService.SIDE_ELEVATION,
      value: AreiPluginParametersService.DEFAULT_SIDE_ELEVATION
    })

    this.parameters.push({
      key: AreiPluginParametersService.REAR_ELEVATION,
      value: AreiPluginParametersService.DEFAULT_REAR_ELEVATION
    })

    this.parameters.push({
      key: AreiPluginParametersService.FRONT_GAIN,
      value: AreiPluginParametersService.DEFAULT_FRONT_GAIN
    })

    this.parameters.push({
      key: AreiPluginParametersService.SIDE_GAIN,
      value: AreiPluginParametersService.DEFAULT_SIDE_GAIN
    })

    this.parameters.push({
      key: AreiPluginParametersService.REAR_GAIN,
      value: AreiPluginParametersService.DEFAULT_REAR_GAIN
    })

    // XTC
    this.parameters.push({
      key: AreiPluginParametersService.XTC_FILTER_LENGTH,
      value: AreiPluginParametersService.DEFAULT_XTC_FILTER_LENGTH
    })

    this.parameters.push({
      key: AreiPluginParametersService.XTC_FREQUENCIES_IN_HZ,
      value: AreiPluginParametersService.DEFAULT_XTC_FREQUENCIES_IN_HZ
    })

    this.parameters.push({
      key: AreiPluginParametersService.XTC_ATTENUATIONS_IN_DB,
      value: AreiPluginParametersService.DEFAULT_XTC_ATTENUATIONS_IN_DB
    })

    this.parameters.push({
      key: AreiPluginParametersService.XTC_ANGLE,
      value: AreiPluginParametersService.DEFAULT_XTC_ANGLE
    })

    this.parameters.push({
      key: AreiPluginParametersService.XTC_DELAY_IN_SAMPLES,
      value: AreiPluginParametersService.DEFAULT_XTC_DELAY_IN_SAMPLES
    })
  }

  public getPluginId(): any {
    return 'AREI'
  }

  public getPresetDiscriminator(): string {
    return null
  }

  public static getDefaultVirtualSpace(): string {
    return AreiPluginParametersService.DEFAULT_SIDE_HRTF
  }

  public static getDefaultFrontAngle(): number {
    return AreiPluginParametersService.DEFAULT_FRONT_ANGLE
  }

  public static getDefaultSideAngle(): number {
    return AreiPluginParametersService.DEFAULT_SIDE_ANGLE
  }

  public static getDefaultRearAngle(): number {
    return AreiPluginParametersService.DEFAULT_REAR_ANGLE
  }

  public static getDefaultFrontGain(): number {
    return AreiPluginParametersService.DEFAULT_FRONT_GAIN
  }

  public static getDefaultSideGain(): number {
    return AreiPluginParametersService.DEFAULT_SIDE_GAIN
  }

  public static getDefaultRearGain(): number {
    return AreiPluginParametersService.DEFAULT_REAR_GAIN
  }
}

export default AreiPluginParametersService
