import { ParameterKeyValue } from '../../plugins/ParameterKeyValue'

export abstract class AbstractAudioNode {
  public audioContext: AudioContext
  public enabled: boolean
  public parameters: Array<ParameterKeyValue>

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    this.audioContext = audioContext
    this.enabled = true
    this.parameters = parameters
  }

  enable(): void {
    this.enabled = true
  }

  disable(): void {
    this.enabled = false
  }

  isEnabled(): boolean {
    return this.enabled
  }

  getParameters(): Array<ParameterKeyValue> {
    if (!this.parameters) {
      console.error('Parameters are not defined!')
    }
    return this.parameters
  }

  getParameter(key: string): ParameterKeyValue | undefined {
    if (!this.parameters) {
      console.error('Parameters are not defined!')
    }

    var parameterKeyValue: ParameterKeyValue | undefined = this.parameters.find(
      (p) => p.key === key
    )
    if (!parameterKeyValue) {
      console.error('Could not find parameter with key: ' + key)
    }
    return parameterKeyValue
  }

  getParameterValue(key: string): any {
    return this.getParameter(key) ? this.getParameter(key)?.value : ``
  }

  setParameter(parameterKeyValue: ParameterKeyValue) {
    // console.log('xxxxxxxxxxx - 1');
    // console.log(parameterKeyValue);
    // console.log(this.parameters);

    const parameterKeyValueFound: ParameterKeyValue | undefined =
      this.getParameter(parameterKeyValue.key)

    // console.log('xxxxxxxxxxx - 2');
    // console.log(ParameterKeyValueFound);

    if (!parameterKeyValueFound) {
      console.error(
        'No ParameterKeyValue found in audioNode with parameter name: ' +
          parameterKeyValue.key
      )
      return
    }

    parameterKeyValueFound.value = parameterKeyValue.value
  }
}
