import { VirtualStereoAudioNode } from './virtual-stereo/VirtualStereoAudioNode'
import { VirtualStereoFilters } from './virtual-stereo/VirtualStereoFilters'
import { VirtualAmbioAudioNode } from './virtual-ambio/VirtualAmbioAudioNode'
import { VirtualAmbioFilters } from './virtual-ambio/VirtualAmbioFilters'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'

export class AreiAudioNode extends AbstractAudioNode implements IAudioNode {
  public input: GainNode
  public output: GainNode
  public virtualStereoAudioNode: VirtualStereoAudioNode
  public virtualAmbioAudioNode: VirtualAmbioAudioNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>,
    virtualStereoFilters: VirtualStereoFilters,
    virtualAmbioFilters: VirtualAmbioFilters
  ) {
    super(audioContext, parameters)

    this.input = audioContext.createGain()
    this.output = audioContext.createGain()

    if (virtualStereoFilters != null) {
      this.virtualStereoAudioNode = new VirtualStereoAudioNode(
        audioContext,
        parameters,
        virtualStereoFilters
      )
    }

    if (virtualAmbioFilters != null) {
      this.virtualAmbioAudioNode = new VirtualAmbioAudioNode(
        audioContext,
        parameters,
        virtualAmbioFilters
      )
    }

    this.wire()
  }

  wire(): Promise<any> {
    if (this.virtualStereoAudioNode != null) {
      this.input.connect(this.virtualStereoAudioNode.getInput())
      this.virtualStereoAudioNode.getOutput().connect(this.output)
    } else {
      this.input.connect(this.virtualAmbioAudioNode.getInput())
      this.virtualAmbioAudioNode.getOutput().connect(this.output)
    }

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.input
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }

  applyVirtualStereoFilters(virtualStereoFilters: VirtualStereoFilters) {
    if (this.virtualStereoAudioNode != null) {
      this.virtualStereoAudioNode.applyVirtualStereoFilters(
        virtualStereoFilters
      )
    } else {
      this.input.disconnect()
      this.virtualAmbioAudioNode = null
      this.virtualStereoAudioNode = new VirtualStereoAudioNode(
        this.audioContext,
        this.getParameters(),
        virtualStereoFilters
      )

      this.wire()
    }
  }

  applyVirtualAmbioFilters(virtualAmbioFilters: VirtualAmbioFilters) {
    if (this.virtualAmbioAudioNode != null) {
      this.virtualAmbioAudioNode.applyVirtualAmbioFilters(virtualAmbioFilters)
    } else {
      this.input.disconnect()
      this.virtualStereoAudioNode = null
      this.virtualAmbioAudioNode = new VirtualAmbioAudioNode(
        this.audioContext,
        this.getParameters(),
        virtualAmbioFilters
      )

      this.wire()
    }
  }
}
