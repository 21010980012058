import { MediaContentType } from './MediaContentType'

/*
 * The abstract base class only defines concrete methods & properties.
 */
export class MediaContent {
  private _mediaContentType: MediaContentType
  private _title: string
  private _thumbnail: string

  /*
   * Here comes the clever part: by letting the constructor take an
   * implementation of IMediaContent as argument MediaContent cannot be instantiated
   * without a valid implementation of the abstract methods.
   */
  constructor(
    mediaContentType: MediaContentType,
    title: string,
    thumbnail: string
  ) {
    this._mediaContentType = mediaContentType
    this._title = title
    this._thumbnail = thumbnail
  }

  /*
   * Answers the thumbnail path
   *
   * @returns {string}
   */
  getThumbnail(): string {
    // console.log(this._thumbnail);
    return this._thumbnail
  }

  getTitle(): string {
    return this._title
  }

  public isStream(): boolean {
    return (
      this._mediaContentType === MediaContentType.AUDIO_STREAM ||
      this._mediaContentType === MediaContentType.VIDEO_STREAM
    )
  }

  public isLocalFile(): boolean {
    return (
      this._mediaContentType === MediaContentType.AUDIO_LOCAL_FILE ||
      this._mediaContentType === MediaContentType.VIDEO_LOCAL_FILE
    )
  }

  public isRemoteFile(): boolean {
    return (
      this._mediaContentType === MediaContentType.AUDIO_REMOTE_FILE ||
      this._mediaContentType === MediaContentType.VIDEO_REMOTE_FILE
    )
  }

  public isAudio(): boolean {
    return (
      this._mediaContentType === MediaContentType.AUDIO_STREAM ||
      this._mediaContentType === MediaContentType.AUDIO_LOCAL_FILE ||
      this._mediaContentType === MediaContentType.AUDIO_REMOTE_FILE
    )
  }

  public isAudioStream(): boolean {
    return this._mediaContentType === MediaContentType.AUDIO_STREAM
  }

  public isAudioLocalFile(): boolean {
    return this._mediaContentType === MediaContentType.AUDIO_LOCAL_FILE
  }

  public isAudioRemoteFile(): boolean {
    return this._mediaContentType === MediaContentType.AUDIO_REMOTE_FILE
  }

  public isAudioDom(): boolean {
    return this._mediaContentType === MediaContentType.AUDIO_DOM
  }

  public isVideo(): boolean {
    return (
      this._mediaContentType === MediaContentType.VIDEO_STREAM ||
      this._mediaContentType === MediaContentType.VIDEO_LOCAL_FILE ||
      this._mediaContentType === MediaContentType.VIDEO_REMOTE_FILE
    )
  }

  public isVideoStream(): boolean {
    return this._mediaContentType === MediaContentType.VIDEO_STREAM
  }

  public isVideoLocalFile(): boolean {
    return this._mediaContentType === MediaContentType.VIDEO_LOCAL_FILE
  }

  public isVideoRemoteFile(): boolean {
    return this._mediaContentType === MediaContentType.VIDEO_REMOTE_FILE
  }

  public isVideoDom(): boolean {
    return this._mediaContentType === MediaContentType.VIDEO_DOM
  }

  public isMediaStream(): boolean {
    return this._mediaContentType === MediaContentType.MEDIA_STREAM
  }
}

export default MediaContent
