// declare var browser: any
// import * as browser from 'webextension-polyfill'

import { IPluginBackgroundController } from '../../audio-core/plugins/IPluginBackgroundController'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { PluginInstanceParameterKeyValue } from '../../audio-core/plugins/PluginInstanceParameterKeyValue'
import ExtensionAction from './extension-action'

export default class ExtensionPluginBackgroundControllerImpl
  implements IPluginBackgroundController
{
  constructor(private browser: any) {}

  async createPluginInstance(
    tabId: any,
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<PluginInstance> {
    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.CREATE_PLUGIN_INSTANCE,
      payload: pluginInstance,
      tabId: tabId,
      browser: null
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async removePluginInstance(
    tabId: any,
    pluginInstance: PluginInstance
  ): Promise<any> {
    // console.log(pluginInstance)

    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.REMOVE_PLUGIN_INSTANCE,
      payload: pluginInstance,
      tabId: tabId
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async removeAllPluginInstances(tabId: any): Promise<void> {
    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.REMOVE_ALL_PLUGIN_INSTANCES,
      tabId: tabId
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async setPluginInstanceParameterKeyValue(
    tabId: any,
    audioContext: AudioContext,
    pluginInstanceParameterKeyValue: PluginInstanceParameterKeyValue
  ): Promise<PluginInstance> {
    // console.log('PluginBackgroundControllerImplService - setPluginInstanceParameterKeyValue');
    // console.log(pluginInstanceParameterKeyValue);

    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.SET_PLUGIN_INSTANCE_PARAMETER_VALUE,
      payload: pluginInstanceParameterKeyValue,
      tabId: tabId
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async getAllPluginInstances(tabId: any): Promise<Array<PluginInstance>> {
    // console.log('PluginBackgroundControllerImplService - getAllPluginInstances')

    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.GET_ALL_PLUGIN_INSTANCES,
      tabId: tabId
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async setProcessingOn(tabId: any): Promise<void> {
    await this.browser.runtime.sendMessage({
      action: ExtensionAction.PROCESSING_ON,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve()
    })
  }

  async setProcessingOff(tabId: any): Promise<void> {
    await this.browser.runtime.sendMessage({
      action: ExtensionAction.PROCESSING_OFF,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve()
    })
  }

  async queryProcessingState(tabId: any): Promise<boolean> {
    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.QUERY_PROCESSING_STATE,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }

  async setListeningThroughSpeakers(tabId: any): Promise<void> {
    await this.browser.runtime.sendMessage({
      action: ExtensionAction.LISTENING_THROUGH_SPEAKERS,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve()
    })
  }

  async setListeningThroughHeadphones(tabId: any): Promise<void> {
    await this.browser.runtime.sendMessage({
      action: ExtensionAction.LISTENING_THROUGH_HEADPHONES,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve()
    })
  }

  async queryListeningDevice(tabId: any): Promise<string> {
    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.QUERY_LISTENING_DEVICE,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }

  async enablePluginInstance(
    tabId: any,
    instanceId: string
  ): Promise<PluginInstance> {
    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.ENABLE_PLUGIN_INSTANCE,
      payload: instanceId,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }

  async disablePluginInstance(
    tabId: any,
    instanceId: string
  ): Promise<PluginInstance> {
    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.DISABLE_PLUGIN_INSTANCE,
      payload: instanceId,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }

  async getAnalyserData(tabId: any, playerId: string): Promise<any> {
    const result = await this.browser.runtime.sendMessage({
      action: ExtensionAction.GET_ANALYZER_DATA,
      payload: playerId,
      tabId: tabId
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }
}
