import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'
import { StereoWideningMethod } from './StereoWideningMethod'

export class StereoWideningPluginParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static METHOD: string = 'method'
  public static DELAY_LEFT: string = 'delayLeft'
  public static DELAY_RIGHT: string = 'delayRight'

  private static DEFAULT_METHOD: string = StereoWideningMethod.DELAY
  private static DEFAULT_DELAY_LEFT_VALUE: number = 0
  private static DEFAULT_DELAY_RIGHT_VALUE: number = 0

  constructor() {
    super()

    this.parameters.push({
      key: StereoWideningPluginParametersService.METHOD,
      value: StereoWideningPluginParametersService.DEFAULT_METHOD
    })

    this.parameters.push({
      key: StereoWideningPluginParametersService.DELAY_LEFT,
      value: StereoWideningPluginParametersService.DEFAULT_DELAY_LEFT_VALUE
    })

    this.parameters.push({
      key: StereoWideningPluginParametersService.DELAY_RIGHT,
      value: StereoWideningPluginParametersService.DEFAULT_DELAY_RIGHT_VALUE
    })
  }

  public getPluginId(): any {
    return 'STEREO_WIDENING'
  }

  public getPresetDiscriminator(): string {
    return null
  }
}
