import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'

export class ArccPluginParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static VERSION: number = 2

  public static FILTER_LENGTH: string = 'filterLength'
  public static ANGLE: string = 'angle'
  public static DELAY: string = 'delay'
  public static USE_ANGLE_OR_DELAY: string = 'useAngleOrDelay'
  public static DISTANCE: string = 'distance'
  public static NUMBER_OF_BANDS: string = 'numberOfBands'

  public static FREQUENCIES_1: string = 'frequencies1'
  public static ATTENUATIONS_1: string = 'attenuations1'
  public static FREQUENCIES_3: string = 'frequencies3'
  public static ATTENUATIONS_3: string = 'attenuations3'
  public static FREQUENCIES_11: string = 'frequencies11'
  public static ATTENUATIONS_11: string = 'attenuations11'
  public static FREQUENCIES_21: string = 'frequencies21'
  public static ATTENUATIONS_21: string = 'attenuations21'

  private static DEFAULT_FILTER_LENGTH: number = 1024 * 2
  private static DEFAULT_ANGLE: number = 6
  private static DEFAULT_DELAY: number = 3
  private static DEFAULT_USE_ANGLE_OR_DELAY: string = 'angle'
  private static DEFAULT_DISTANCE: number = 1
  private static DEFAULT_NUMBER_OF_BANDS: number = 3

  private static DEFAULT_FREQUENCIES_1: Array<number> = [1000]
  private static DEFAULT_ATTENUATIONS_1: Array<number> = [-4]

  private static DEFAULT_FREQUENCIES_3: Array<number> = [250, 2000, 8000]

  // private static DEFAULT_ATTENUATIONS_3: Array<number> = [-10, -4, -13]
  private static DEFAULT_ATTENUATIONS_3: Array<number> = [-20, -5, -20]

  private static DEFAULT_FREQUENCIES_11: Array<number> = [
    16, 31.5, 63, 125, 250, 500, 1000, 2000, 4000, 8000, 16000
  ]

  private static DEFAULT_ATTENUATIONS_11: Array<number> = [
    -10.5, -10.5, -10.5, -10.5, -10.5, -3.5, -3.5, -3.5, -3.5, -13, -13
  ]

  private static DEFAULT_FREQUENCIES_21: Array<number> = [
    16, 22, 31.5, 44, 63, 88, 125, 177, 250, 354, 500, 707, 1000, 1414, 2000,
    2828, 4000, 5656, 8000, 11314, 16000
  ]

  private static DEFAULT_ATTENUATIONS_21: Array<number> = [
    -10.5, -10.5, -10.5, -10.5, -10.5, -10.5, -10.5, -3.5, -3.5, -3.5, -3.5,
    -3.5, -3.5, -3.5, -3.5, -3.5, -3.5, -13, -13, -13, -13
  ]

  constructor() {
    super()

    this.parameters.push({
      key: ArccPluginParametersService.FILTER_LENGTH,
      value: ArccPluginParametersService.DEFAULT_FILTER_LENGTH
    })

    this.parameters.push({
      key: ArccPluginParametersService.ANGLE,
      value: ArccPluginParametersService.DEFAULT_ANGLE
    })

    this.parameters.push({
      key: ArccPluginParametersService.DELAY,
      value: ArccPluginParametersService.DEFAULT_DELAY
    })

    this.parameters.push({
      key: ArccPluginParametersService.USE_ANGLE_OR_DELAY,
      value: ArccPluginParametersService.DEFAULT_USE_ANGLE_OR_DELAY
    })

    this.parameters.push({
      key: ArccPluginParametersService.DISTANCE,
      value: ArccPluginParametersService.DEFAULT_DISTANCE
    })

    this.parameters.push({
      key: ArccPluginParametersService.NUMBER_OF_BANDS,
      value: ArccPluginParametersService.DEFAULT_NUMBER_OF_BANDS
    })

    this.parameters.push({
      key: ArccPluginParametersService.FREQUENCIES_1,
      value: ArccPluginParametersService.DEFAULT_FREQUENCIES_1
    })

    this.parameters.push({
      key: ArccPluginParametersService.ATTENUATIONS_1,
      value: ArccPluginParametersService.DEFAULT_ATTENUATIONS_1
    })

    this.parameters.push({
      key: ArccPluginParametersService.FREQUENCIES_3,
      value: ArccPluginParametersService.DEFAULT_FREQUENCIES_3
    })

    this.parameters.push({
      key: ArccPluginParametersService.ATTENUATIONS_3,
      value: ArccPluginParametersService.DEFAULT_ATTENUATIONS_3
    })

    this.parameters.push({
      key: ArccPluginParametersService.FREQUENCIES_11,
      value: ArccPluginParametersService.DEFAULT_FREQUENCIES_11
    })

    this.parameters.push({
      key: ArccPluginParametersService.ATTENUATIONS_11,
      value: ArccPluginParametersService.DEFAULT_ATTENUATIONS_11
    })

    this.parameters.push({
      key: ArccPluginParametersService.FREQUENCIES_21,
      value: ArccPluginParametersService.DEFAULT_FREQUENCIES_21
    })

    this.parameters.push({
      key: ArccPluginParametersService.ATTENUATIONS_21,
      value: ArccPluginParametersService.DEFAULT_ATTENUATIONS_21
    })

    this.initializePresets()
  }

  public getPluginId(): any {
    return 'ARCC'
  }

  public getPresetDiscriminator(): string {
    return ArccPluginParametersService.DEFAULT_NUMBER_OF_BANDS.toString()
  }

  public static getDefaultAttenuations1(): Array<number> {
    return ArccPluginParametersService.DEFAULT_ATTENUATIONS_1.slice(0)
  }

  public static getDefaultAttenuations3(): Array<number> {
    return ArccPluginParametersService.DEFAULT_ATTENUATIONS_3.slice(0)
  }

  public static getDefaultAttenuations11(): Array<number> {
    return ArccPluginParametersService.DEFAULT_ATTENUATIONS_11.slice(0)
  }

  public static getDefaultAttenuations21(): Array<number> {
    return ArccPluginParametersService.DEFAULT_ATTENUATIONS_21.slice(0)
  }

  private initializePresets() {
    this.presets = []

    this.initializePresets3()
  }

  private initializePresets3() {
    const eqPreset3 = {
      moderate: [-10, -4, -13],
      low: [-15, -9, -15],
      high: [-6, -2.5, -5]
    }

    for (var key in eqPreset3) {
      if (eqPreset3[key].length !== 3) {
        console.error('eqPreset3[key].length != 3')
      }
      this.presets.push({
        pluginId: this.getPluginId(),
        presetId: key + '3',
        type: 'system',
        name: this.makePresetLabel(key),
        discriminator: '3',
        parameters: [
          {
            key: 'frequencies',
            value: ArccPluginParametersService.DEFAULT_FREQUENCIES_3
          },
          {
            key: 'attenuations',
            value: eqPreset3[key]
          }
        ]
      })
    }
  }

  private makePresetLabel(presetName: string): string {
    const str = presetName
    const result = str.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)

    return finalResult
  }
}

export default ArccPluginParametersService
