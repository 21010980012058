import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { PizzicatoReverb } from './audio-node/pizzicato-reverb'
import { TunaJsReverb } from './audio-node/tuna-js-reverb'

export class ReverbAudioNode extends AbstractAudioNode implements IAudioNode {
  private input: GainNode
  private tunaJsReverb: TunaJsReverb
  private pizzicatoReverb: PizzicatoReverb
  private output: GainNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    super(audioContext, parameters)

    this.input = this.audioContext.createGain()
    this.output = this.audioContext.createGain()

    // if (this.getParameterValue(ReverbPluginParametersService.METHOD) === ReverbMethod.SIMULATION) {
    //     this.pizzicatoReverb = new PizzicatoReverb(audioContext, parameters);
    // }

    // TODO:
    this.wire()
  }

  wire(): Promise<any> {
    // if (this.getParameterValue(ReverbPluginParametersService.METHOD) === ReverbMethod.SIMULATION) {
    //     this.input.connect(this.pizzicatoReverb.getInput());
    //     this.pizzicatoReverb.getOutput().connect(this.output);
    // } else {
    //     this.input.connect(this.output);
    // }

    this.input.connect(this.output)

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.input
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }
}
