import { VirtualStereoAudioNode } from './virtual-stereo/VirtualStereoAudioNode'
import { VirtualStereoFilters } from './virtual-stereo/VirtualStereoFilters'
import { VirtualAmbioAudioNode } from './virtual-ambio/VirtualAmbioAudioNode'
import { VirtualAmbioFilters } from './virtual-ambio/VirtualAmbioFilters'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'

import ResonanceAudio from 'magic-audio-resonance-audio'

class ResonanceAudioNode extends AbstractAudioNode implements IAudioNode {
  public channelSplitter: ChannelSplitterNode
  public output: GainNode
  public resonanceAudioScene: any
  public resonanceSourceLeft: any
  public resonanceSourceRight: any

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    super(audioContext, parameters)
    // console.log(`ResonanceAudio`, ResonanceAudio)

    // const xxx = ResonanceAudio
    // console.log(`xxx`, xxx)

    this.channelSplitter = this.audioContext.createChannelSplitter(2)
    this.output = audioContext.createGain()

    this.resonanceAudioScene = new ResonanceAudio(audioContext, {
      ambisonicOrder: 3
    })

    // console.log(`this.resonanceAudioScene`, this.resonanceAudioScene)

    // const roomDimensions = {
    //   width: 3.1,
    //   height: 2.5,
    //   depth: 3.4
    // }

    // const roomMaterials = {
    //   // Room wall materials
    //   left: 'brick-bare',
    //   right: 'brick-bare',
    //   front: 'curtain-heavy',
    //   back: 'curtain-heavy',
    //   // Room floor
    //   down: 'marble',
    //   // Room ceiling
    //   up: 'brick-bare'
    // }

    // this.resonanceAudioScene.setRoomProperties(roomDimensions, roomMaterials)

    this.resonanceSourceLeft = this.resonanceAudioScene.createSource()
    // this.resonanceSourceLeft.setPosition(-0.707, -0.707, 0)
    // this.resonanceSourceLeft.setPosition(-1, 0, 0)
    this.resonanceSourceLeft.setPosition(1, 0.5, 0)
    // console.log(`this.resonanceSourceLeft`, this.resonanceSourceLeft)

    this.resonanceSourceRight = this.resonanceAudioScene.createSource()
    // this.resonanceSourceRight.setPosition(0.707, -0.707, 0)
    // this.resonanceSourceRight.setPosition(1, 0, 0)
    this.resonanceSourceRight.setPosition(-1, 0.5, 0)
    // console.log(`this.resonanceSourceRight`, this.resonanceSourceRight)

    // // Splitter
    // this.channelSplitter.connect(this.resonanceSourceLeft.input, 0)
    // this.channelSplitter.connect(this.resonanceSourceRight.input, 1)

    // // Connect the scene’s binaural output to stereo out.
    // this.resonanceAudioScene.output.connect(this.output)

    this.wire()
  }

  wire(): Promise<void> {
    // Splitter
    this.channelSplitter.connect(this.resonanceSourceLeft.input, 0)
    this.channelSplitter.connect(this.resonanceSourceRight.input, 1)

    // Connect the scene’s binaural output to stereo out.
    this.resonanceAudioScene.output.connect(this.output)

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.channelSplitter
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }
}

export default ResonanceAudioNode
