import { MySpecialAudioInputNode } from './MySpecialAudioInputNode'
import { BaseAudioInputNode } from './BaseAudioInputNode'
import { IMediaContent } from '../../media-content/IMediaContent'

export class InputAudioRemoteFile extends BaseAudioInputNode {
  constructor() {
    super()
  }

  /*
   * Create Node.
   *
   * @param mediaContent
   * @param audioContext
   * @returns {any}
   */
  public createNode(
    mediaContent: IMediaContent,
    audioContext: AudioContext,
    useStreamForRemote: boolean
  ): Promise<MySpecialAudioInputNode> {
    if (useStreamForRemote) {
      return this.createAudioStream(mediaContent.getSource(), audioContext)
    } else {
      return this.createAudioRemoteFileWithAjax(
        mediaContent.getSource(),
        audioContext
      )
    }
  }

  /*
   * CreateAudioLocalFileWithFileApi.
   *
   * @param url
   * @returns {IPromise<T>}
   */
  private createAudioRemoteFileWithAjax(
    url: string,
    audioContext: AudioContext
  ): Promise<any> {
    var mySpecialAudioInputNode: MySpecialAudioInputNode =
      new MySpecialAudioInputNode()

    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'arraybuffer'

      xhr.onload = function () {
        if (xhr.status === 200) {
          audioContext.decodeAudioData(
            xhr.response,
            function (buffer) {
              mySpecialAudioInputNode.setAudioBuffer(buffer, audioContext)
              resolve(mySpecialAudioInputNode)
            },
            function () {
              console.error('Cannot audioContext.decodeAudioData')
              reject()
            }
          )
        } else {
          console.error('Request failed.  Returned status of ' + xhr.status)
          reject()
        }
      }

      xhr.send()
    })
  }
}
