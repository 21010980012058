export default class ExtensionAction {
  static CAPTURE_STREAM = 'captureStream'

  static GET_ANALYZER_DATA = 'getAnalyzerData'

  static START_VISUALIZER = 'startVisualizer'

  static CREATE_PLUGIN_INSTANCE = 'createPluginInstance'

  static REMOVE_PLUGIN_INSTANCE = 'removePluginInstance'

  static REMOVE_ALL_PLUGIN_INSTANCES = 'removeAllPluginInstances'

  static SET_PLUGIN_INSTANCE_PARAMETER_VALUE =
    'setPluginInstanceParameterKeyValue'

  static GET_ALL_PLUGIN_INSTANCES = 'getAllPluginInstances'

  static PROCESSING_ON = 'processingOn'

  static PROCESSING_OFF = 'processingOff'

  static QUERY_PROCESSING_STATE = 'queryProcessingState'

  static LISTENING_THROUGH_SPEAKERS = 'listeningThroughSpeakers'

  static LISTENING_THROUGH_HEADPHONES = 'listeningThroughHeadphones'

  static QUERY_LISTENING_DEVICE = 'queryListeningDevice'

  static GET_SAMPLE_RATE = 'getSampleRate'

  static ENABLE_PLUGIN_INSTANCE = 'enablePluginInstance'

  static DISABLE_PLUGIN_INSTANCE = 'disablePluginInstance'

  static SET_IS_CONTINUE_AS_GUEST = 'setIsContinueAsGuest'

  static QUERY_IS_CONTINUE_AS_GUEST = 'queryIsContinueAsGuest'

  static QUERY_IS_CONTINUE_AS_GUEST_EXPIRED = 'queryIsContinueAsGuestExpired'

  static QUERY_SETTINGS = 'querySettings'

  static NOTIFY_GUEST_SESSION_EXPIRED = 'notifyGuestSessionExpired'

  static NOTIFY_NO_AUDIOVISUAL_CONTENT_FOUND = 'notifyNoAudioVisualContentFound'
}
