import AudioContextProvider from '../audio-core/web-audio-api/AudioContextProvider'
import IMediaContent from '../audio-core/media-content/IMediaContent'
import MediaPlayer from '../audio-core/player/media-player'
import PluginGraph from '../audio-core/plugin/PluginGraph'
import PluginGraphNode from '../audio-core/plugin/PluginGraphNode'
import PluginProcessorAudioNode from '../audio-core/plugin/PluginProcessorAudioNode'
import { PluginInstance } from '../audio-core/plugins/PluginInstance'
import { PluginInstanceParameterKeyValue } from '../audio-core/plugins/PluginInstanceParameterKeyValue'
import { MySpecialAudioInputNode } from '../audio-core/web-audio-api/audio-input/MySpecialAudioInputNode'
import { IAudioNode } from '../audio-core/web-audio-api/audio-node/IAudioNode'
import { PluginInstanceFactoryService } from './plugin-instance-factory.service'
import { IPluginBackgroundController } from '../audio-core/plugins/IPluginBackgroundController'
import AudioInputNodeFactory from '../audio-core/web-audio-api/audio-input/AudioInputNodeFactory'

export class BackgroundMediaPlayerService
  implements IPluginBackgroundController
{
  private pluginInstanceFactoryService: PluginInstanceFactoryService
  private audioInputNodeFactory: AudioInputNodeFactory

  private mediaPlayerArray: any = {}

  constructor(
    pluginInstanceFactoryService: PluginInstanceFactoryService,
    audioInputNodeFactory: AudioInputNodeFactory
  ) {
    this.pluginInstanceFactoryService = pluginInstanceFactoryService
    this.audioInputNodeFactory = audioInputNodeFactory
  }

  createPluginInstance(
    tabId: any,
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<PluginInstance> {
    return this.addNewPluginInstanceInGraph(
      tabId,
      audioContext,
      pluginInstance,
      browser
    )
  }

  removePluginInstance(
    tabId: any,
    pluginInstance: PluginInstance
  ): Promise<void> {
    // TODO
    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  removeAllPluginInstances(tabId: any): Promise<void> {
    return this.getMediaPlayer(tabId).removeAllPluginInstances()
  }

  public play(
    mediaPlayerId: any,
    mediaContent: IMediaContent,
    selectedIndex: number
  ): Promise<MySpecialAudioInputNode> {
    // console.log('play');
    // console.log(this.getMediaPlayer(mediaPlayerId));
    return this.getMediaPlayer(mediaPlayerId).play(mediaContent, selectedIndex)
  }

  public pause(
    mediaPlayerId: any,
    selectedIndex: number
  ): Promise<MySpecialAudioInputNode> {
    // console.log('play');
    // console.log(this.getMediaPlayer(mediaPlayerId));
    return this.getMediaPlayer(mediaPlayerId).pause(selectedIndex)
  }

  public async appendInputMediaContent(
    mediaPlayerId: any,
    mediaContent: IMediaContent
  ): Promise<MySpecialAudioInputNode> {
    // console.log(
    //   `mediaPlayer`,
    //   mediaPlayerId,
    //   this.getMediaPlayer(mediaPlayerId)
    // )
    return this.getMediaPlayer(mediaPlayerId).appendInputMediaContent(
      mediaContent
    )
  }

  createMediaPlayer(
    mediaPlayerId: any,
    audioContext: AudioContext,
    callbackOnFinish?: any
  ): MediaPlayer {
    // console.log(`createMediaPlayer`)
    // console.log(`mediaPlayerId`, mediaPlayerId)
    // console.log(`callbackOnFinish`, callbackOnFinish)

    if (!this.mediaPlayerArray[mediaPlayerId]) {
      var mediaPlayer: MediaPlayer = new MediaPlayer(
        audioContext,
        this.pluginInstanceFactoryService,
        this.audioInputNodeFactory,
        callbackOnFinish
      )

      this.mediaPlayerArray[mediaPlayerId] = mediaPlayer
    }

    return this.mediaPlayerArray[mediaPlayerId]
    // console.log(`this.mediaPlayerArray`, this.mediaPlayerArray)
  }

  getMediaPlayer(mediaPlayerId: any): MediaPlayer {
    if (this.mediaPlayerArray[mediaPlayerId]) {
      // console.log('Yes MediaPlayer with id: ' + mediaPlayerId);
      // console.log(this.mediaPlayerArray[mediaPlayerId]);
      return this.mediaPlayerArray[mediaPlayerId]
    } else {
      // console.warn('No MediaPlayer with id: ' + mediaPlayerId);
      console.error(`No player found for id=${mediaPlayerId}`)
      throw new Error(`No player found for id=${mediaPlayerId}`)
    }
  }

  getAnalyserData(tabId: any, playerId: any): Promise<any> {
    // console.log(
    //   'BackgroundMediaPlayerService - getAnalyserData - tabId, playerId',
    //   tabId,
    //   playerId
    // )
    var mediaPlayer = this.getMediaPlayer(playerId)
    if (mediaPlayer) {
      // console.log('mediaPlayer exists!')
      return Promise.resolve(mediaPlayer.getAnalyserData())
    } else {
      return Promise.reject(
        new Error(`mediaPlayer does not exist for id=` + playerId)
      )
    }
  }

  addNewPluginInstanceInGraph(
    tabId: any,
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<PluginInstance> {
    // console.log(
    //   'BackgroundMediaPlayerService - addNewPluginInstanceInGraph, tabId, pluginInstance:',
    //   tabId,
    //   pluginInstance
    // )
    // console.log(this.getMediaPlayer(tabId))

    return this.getMediaPlayer(tabId).addNewPluginInstanceInGraph(
      audioContext,
      pluginInstance,
      browser
    )
  }

  setPluginInstanceParameterKeyValue(
    tabId: any,
    audioContext: AudioContext,
    pluginInstanceParameterKeyValue: PluginInstanceParameterKeyValue,
    browser: any
  ): Promise<PluginInstance> {
    // console.log(
    //   'BackgroundMediaPlayerService - setPluginInstanceParameterKeyValue, tabId',
    //   tabId
    // )
    // console.log(pluginInstanceParameterKeyValue)

    return this.getMediaPlayer(tabId).setPluginInstanceParameterKeyValue(
      audioContext,
      pluginInstanceParameterKeyValue,
      browser
    )
  }

  getAllPluginInstances(tabId: any): Promise<Array<PluginInstance>> {
    // console.log(
    //   'BackgroundMediaPlayerService - getAllPluginInstances, tabId',
    //   tabId
    // )

    return this.getMediaPlayer(tabId).getAllPluginInstances()
  }

  getSampleRate(audioContext: AudioContext): Promise<number> {
    return new Promise((resolve, reject) => {
      resolve(audioContext.sampleRate)
    })
  }

  setProcessingOn(tabId: any): Promise<void> {
    return this.getMediaPlayer(tabId).setProcessingOn()
  }

  setProcessingOff(tabId: any): Promise<void> {
    return this.getMediaPlayer(tabId).setProcessingOff()
  }

  queryProcessingState(tabId: any): Promise<boolean> {
    return this.getMediaPlayer(tabId).queryProcessingState()
  }

  // ONLY FOR ARIA-3D
  setListeningThroughSpeakers(tabId: any): Promise<void> {
    return this.getMediaPlayer(tabId).setListeningThroughSpeakers()
  }

  // ONLY FOR ARIA-3D
  setListeningThroughHeadphones(tabId: any): Promise<void> {
    return this.getMediaPlayer(tabId).setListeningThroughHeadphones()
  }

  queryListeningDevice(tabId: any): Promise<string> {
    // console.log(`queryListeningDevice - tabId`, tabId)
    return this.getMediaPlayer(tabId).queryListeningDevice()
  }

  enablePluginInstance(
    tabId: any,
    instanceId: string
  ): Promise<PluginInstance> {
    return this.getMediaPlayer(tabId).enablePluginInstance(instanceId)
  }

  disablePluginInstance(
    tabId: any,
    instanceId: string
  ): Promise<PluginInstance> {
    return this.getMediaPlayer(tabId).disablePluginInstance(instanceId)
  }

  // /////////////////////////////////////////////////////////////////////////////////
  // PLAYLIST RELATED
  // /////////////////////////////////////////////////////////////////////////////////
}

export default BackgroundMediaPlayerService
