import { IPluginService } from '../../audio-core/plugin/IPluginService'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { DynamicsCompressorPluginParametersService } from '../../audio-plugins-parameters/dynamics-compressor/dynamics-compressor-plugin-parameters.service'
import { DynamicsCompressorAudioNode } from './dynamics-compressor-audio-node'

export class DynamicsCompressorPluginService implements IPluginService {
  constructor(
    private dynamicsCompressorPluginParametersService: DynamicsCompressorPluginParametersService
  ) {}

  createAudioNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<IAudioNode> {
    return new Promise((resolve, reject) => {
      pluginInstance =
        this.dynamicsCompressorPluginParametersService.ensurePluginInstanceParameterKeyValues(
          pluginInstance
        )

      var audioNode: DynamicsCompressorAudioNode =
        new DynamicsCompressorAudioNode(
          audioContext,
          this.dynamicsCompressorPluginParametersService.getParameterKeyValues(
            pluginInstance
          )
        )

      resolve(audioNode)
    })
  }

  setParameterValue(
    audioContext: AudioContext,
    audioNode: IAudioNode,
    parameterKeyValue: ParameterKeyValue,
    browser: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      audioNode.setParameter(parameterKeyValue)

      switch (parameterKeyValue.key) {
        case DynamicsCompressorPluginParametersService.THRESHOLD:
          ;(<DynamicsCompressorAudioNode>audioNode).applyThreshold()
          resolve()
          break
        case DynamicsCompressorPluginParametersService.KNEE:
          ;(<DynamicsCompressorAudioNode>audioNode).applyKnee()
          resolve()
          break
        case DynamicsCompressorPluginParametersService.RATIO:
          ;(<DynamicsCompressorAudioNode>audioNode).applyRatio()
          resolve()
          break
        case DynamicsCompressorPluginParametersService.ATTACK:
          ;(<DynamicsCompressorAudioNode>audioNode).applyAttack()
          resolve()
          break
        case DynamicsCompressorPluginParametersService.RELEASE:
          ;(<DynamicsCompressorAudioNode>audioNode).applyRelease()
          resolve()
          break
        default:
          console.error(
            'No available parameter with name: ' + parameterKeyValue.key
          )
          reject()
      }
    })
  }
}
