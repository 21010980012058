import { Complex } from '../math/Complex'
import { MemoryService } from './MemoryService'
import { DaspService } from './DaspService'

export class FftService {
  private daspService: DaspService
  private memoryService: MemoryService
  private fftProcessor: any

  constructor(daspService: DaspService, memoryService: MemoryService) {
    this.daspService = daspService
    this.memoryService = memoryService
    this.fftProcessor = new (this.daspService.getInstance().FftProcessor)()
  }

  forward(input: Array<number>): Array<Complex> {
    this.memoryService.setRealArray(input)

    this.fftProcessor.forward(
      this.memoryService.getRealHeap(),
      this.memoryService.getComplexHeap(),
      input.length
    )

    return this.memoryService.getComplexArray(input.length)
  }

  inverse(input: Array<Complex>): Array<number> {
    this.memoryService.setComplexArray(input)

    this.fftProcessor.inverse(
      this.memoryService.getRealHeap(),
      this.memoryService.getComplexHeap(),
      input.length
    )

    var offset: number = 0
    return this.memoryService.getRealArray(offset, input.length)
  }
}
