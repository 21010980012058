import { XtcFilters } from '../race/XtcFilters'

export class VirtualAmbioFilters {
  constructor(
    public binauralIRFrontLeft: AudioBuffer,
    public binauralIRFrontRight: AudioBuffer,
    public binauralIRRearLeft: AudioBuffer,
    public binauralIRRearRight: AudioBuffer,
    public binauralIRSideLeft: AudioBuffer,
    public binauralIRSideRight: AudioBuffer,
    public xtcFilters: XtcFilters
  ) {}
}
