import { IPluginService } from '../../audio-core/plugin/IPluginService'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { StereoWideningPluginParametersService } from '../../audio-plugins-parameters/stereo-widening/stereo-widening-plugin-parameters.service'
import { StereoWideningAudioNode } from './stereo-widening-audio-node'

export class StereoWideningPluginService implements IPluginService {
  constructor(
    private stereoWideningPluginParametersService: StereoWideningPluginParametersService
  ) {}

  createAudioNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<IAudioNode> {
    return new Promise((resolve, reject) => {
      pluginInstance =
        this.stereoWideningPluginParametersService.ensurePluginInstanceParameterKeyValues(
          pluginInstance
        )

      var audioNode: StereoWideningAudioNode = new StereoWideningAudioNode(
        audioContext,
        this.stereoWideningPluginParametersService.getParameterKeyValues(
          pluginInstance
        )
      )

      resolve(audioNode)
    })
  }

  setParameterValue(
    audioContext: AudioContext,
    audioNode: IAudioNode,
    parameterKeyValue: ParameterKeyValue,
    browser: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      audioNode.setParameter(parameterKeyValue)

      switch (parameterKeyValue.key) {
        case StereoWideningPluginParametersService.DELAY_LEFT:
          ;(<StereoWideningAudioNode>audioNode).applyDelayLeft()
          resolve()
          break
        case StereoWideningPluginParametersService.DELAY_RIGHT:
          ;(<StereoWideningAudioNode>audioNode).applyDelayRight()
          resolve()
          break
        default:
          console.error(
            'No available parameter with name: ' + parameterKeyValue.key
          )
          reject()
      }
    })
  }
}
