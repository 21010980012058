import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { EqBiquadPluginParametersService } from '../../audio-plugins-parameters/eq-biquad/eq-biquad-plugin-parameters.service'

export class EqBiquadAudioNode extends AbstractAudioNode implements IAudioNode {
  input: GainNode
  output: GainNode
  biquadFilterNodeList: Array<BiquadFilterNode>

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    super(audioContext, parameters)

    this.input = this.audioContext.createGain()

    this.output = this.audioContext.createGain()
    this.output.gain.setValueAtTime(1, this.audioContext.currentTime)

    this.createBiquadFilters()

    this.applyPreampGain()

    this.wire()
  }

  wire(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.input.connect(this.biquadFilterNodeList[0])

      for (var i = 1; i < this.biquadFilterNodeList.length; i++) {
        this.biquadFilterNodeList[i - 1].connect(this.biquadFilterNodeList[i])
      }

      this.biquadFilterNodeList[this.biquadFilterNodeList.length - 1].connect(
        this.output
      )

      resolve()
    })
  }

  getInput(): AudioNode {
    return this.input
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }

  disconnectAll() {
    this.input.disconnect()

    for (var i = 1; i < this.biquadFilterNodeList.length; i++) {
      this.biquadFilterNodeList[i - 1].disconnect()
    }

    this.biquadFilterNodeList[this.biquadFilterNodeList.length - 1].disconnect()
  }

  /*
   * createBiquadFilters
   */
  createBiquadFilters() {
    this.biquadFilterNodeList = []

    let numberOfBands = this.getParameter(
      EqBiquadPluginParametersService.NUMBER_OF_BANDS
    ).value

    for (var i: number = 0; i < numberOfBands; i++) {
      let biquadFilter = this.audioContext.createBiquadFilter()

      // type BiquadFilterType = "lowpass" | "highpass" | "bandpass" | "lowshelf" | "highshelf" | "peaking" | "notch" | "allpass";

      if (i == 0) {
        biquadFilter.type = 'lowshelf'
      } else if (
        i ==
        this.getParameter(EqBiquadPluginParametersService.NUMBER_OF_BANDS)
          .value -
          1
      ) {
        biquadFilter.type = 'highshelf'
      } else {
        biquadFilter.type = 'peaking'
      }

      if (numberOfBands === 3) {
        biquadFilter.frequency.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.FREQUENCIES_3)
            .value[i],
          this.audioContext.currentTime,
          0.015
        )
        biquadFilter.gain.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.GAIN_RATIOS_3)
            .value[i],
          this.audioContext.currentTime,
          0.015
        )
        biquadFilter.Q.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.QS_3).value[i],
          this.audioContext.currentTime,
          0.015
        )
      } else if (numberOfBands === 11) {
        biquadFilter.frequency.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.FREQUENCIES_11)
            .value[i],
          this.audioContext.currentTime,
          0.015
        )
        biquadFilter.gain.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.GAIN_RATIOS_11)
            .value[i],
          this.audioContext.currentTime,
          0.015
        )
        biquadFilter.Q.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.QS_11).value[i],
          this.audioContext.currentTime,
          0.015
        )
      } else if (numberOfBands === 21) {
        biquadFilter.frequency.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.FREQUENCIES_21)
            .value[i],
          this.audioContext.currentTime,
          0.015
        )
        biquadFilter.gain.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.GAIN_RATIOS_21)
            .value[i],
          this.audioContext.currentTime,
          0.015
        )
        biquadFilter.Q.setTargetAtTime(
          this.getParameter(EqBiquadPluginParametersService.QS_21).value[i],
          this.audioContext.currentTime,
          0.015
        )
      }

      this.biquadFilterNodeList.push(biquadFilter)
    }
  }

  applyNewFilters(): Promise<any> {
    this.disconnectAll()
    this.createBiquadFilters()
    return this.wire()
  }

  applyPreampGain() {
    this.input.gain.setValueAtTime(
      this.getParameterValue(EqBiquadPluginParametersService.PREAMP_GAIN),
      this.audioContext.currentTime
    )
  }
}
