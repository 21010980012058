import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { PluginGainParametersService } from '../../audio-plugins-parameters/gain/plugin-gain-parameters.service'

export class GainAudioNode extends AbstractAudioNode implements IAudioNode {
  public gain: GainNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    super(audioContext, parameters)

    this.gain = audioContext.createGain()
    this.gain.gain.setValueAtTime(
      this.getParameter(PluginGainParametersService.GAIN).value,
      this.audioContext.currentTime
    )
  }

  wire(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.gain
  }

  getOutput(): AudioNode {
    return this.gain
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.gain.connect(destination)
  }

  disconnect(output?: number): void {
    this.gain.disconnect(output)
  }
}
