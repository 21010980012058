import { Complex } from '../math/Complex'
import { DaspService } from './DaspService'

export class MemoryService {
  private daspService: DaspService

  public BPE: number
  private MAX_SIZE: number
  private realHeap: any
  private complexHeap: any

  private realArray: Array<number>
  private complexFlatArray: Array<number>

  constructor(daspService: DaspService) {
    this.daspService = daspService

    this.BPE = Float32Array.BYTES_PER_ELEMENT
    this.MAX_SIZE = this.BPE * 81920

    this.realHeap = this.daspService
      .getInstance()
      ._malloc(this.MAX_SIZE * this.BPE)
    this.complexHeap = this.daspService
      .getInstance()
      ._malloc(2 * this.MAX_SIZE * this.BPE)

    this.realArray = this.daspService
      .getInstance()
      .HEAPF32.subarray(
        this.realHeap / this.BPE,
        this.realHeap / this.BPE + this.MAX_SIZE
      )
    this.complexFlatArray = this.daspService
      .getInstance()
      .HEAPF32.subarray(
        this.complexHeap / this.BPE,
        this.complexHeap / this.BPE + this.MAX_SIZE
      )
  }

  getRealHeap(): any {
    return this.realHeap
  }

  getRealArray(offset: number, length: number): Array<number> {
    var ar: Array<number> = []
    for (var i = offset; i < offset + length; i++) {
      ar.push(this.realArray[i])
    }
    return ar
  }

  setRealArray(input: Array<number>): any {
    var length: number = input.length
    for (var i = 0; i < length; i++) {
      this.realArray[i] = input[i]
    }
  }

  getComplexHeap(): any {
    return this.complexHeap
  }

  getComplexArray(length: number): Array<Complex> {
    var ar: Array<Complex> = []
    for (var i = 0; i < length; i++) {
      var c: Complex = new Complex(
        this.complexFlatArray[2 * i + 0],
        this.complexFlatArray[2 * i + 1]
      )
      ar.push(c)
    }
    return ar
  }

  setComplexArray(input: Array<Complex>) {
    var length: number = input.length
    for (var i = 0; i < length; i++) {
      this.complexFlatArray[2 * i + 0] = input[i].real
      this.complexFlatArray[2 * i + 1] = input[i].imag
    }
  }
}
