// declare var browser: any
// import * as browser from 'webextension-polyfill'

import { IPluginBackgroundController } from '../../audio-core/plugins/IPluginBackgroundController'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { PluginInstanceParameterKeyValue } from '../../audio-core/plugins/PluginInstanceParameterKeyValue'
import ExtensionAction from './extension-action'

export default class ExtensionContentPluginBackgroundControllerImpl
  implements IPluginBackgroundController
{
  constructor(private browser: any) {}

  async createPluginInstance(
    tabId: any,
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<PluginInstance> {
    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.CREATE_PLUGIN_INSTANCE,
      payload: pluginInstance,
      browser: null
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async removePluginInstance(
    tabId: any,
    pluginInstance: PluginInstance
  ): Promise<any> {
    // console.log(pluginInstance)

    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.REMOVE_PLUGIN_INSTANCE,
      payload: pluginInstance
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async removeAllPluginInstances(tabId: any): Promise<void> {
    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.REMOVE_ALL_PLUGIN_INSTANCES
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async setPluginInstanceParameterKeyValue(
    tabId: any,
    audioContext: AudioContext,
    pluginInstanceParameterKeyValue: PluginInstanceParameterKeyValue
  ): Promise<PluginInstance> {
    // console.log('PluginBackgroundControllerImplService - setPluginInstanceParameterKeyValue');
    // console.log(pluginInstanceParameterKeyValue);

    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.SET_PLUGIN_INSTANCE_PARAMETER_VALUE,
      payload: pluginInstanceParameterKeyValue
    })

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async getAllPluginInstances(tabId: any): Promise<Array<PluginInstance>> {
    // console.log(
    //   'ExtensionContentPluginBackgroundControllerImpl - getAllPluginInstances, tabId:',
    //   tabId
    // )

    // console.log(`XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX`)
    // console.log(`typeof browser`, typeof browser)
    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.GET_ALL_PLUGIN_INSTANCES,
      tabId: tabId,
      tabUrl: undefined
    })
    // console.log(`QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ`)
    // console.log(`ExtensionAction.GET_ALL_PLUGIN_INSTANCES result: `, result)

    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  async setProcessingOn(tabId: any): Promise<void> {
    await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.PROCESSING_ON
    })

    return new Promise((resolve) => {
      resolve()
    })
  }

  async setProcessingOff(tabId: any): Promise<void> {
    await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.PROCESSING_OFF
    })

    return new Promise((resolve) => {
      resolve()
    })
  }

  async queryProcessingState(tabId: any): Promise<boolean> {
    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.QUERY_PROCESSING_STATE
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }

  async setListeningThroughSpeakers(tabId: any): Promise<void> {
    await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.LISTENING_THROUGH_SPEAKERS
    })

    return new Promise((resolve) => {
      resolve()
    })
  }

  async setListeningThroughHeadphones(tabId: any): Promise<void> {
    await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.LISTENING_THROUGH_HEADPHONES
    })

    return new Promise((resolve) => {
      resolve()
    })
  }

  async queryListeningDevice(tabId: any): Promise<string> {
    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.QUERY_LISTENING_DEVICE
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }

  async enablePluginInstance(
    tabId: any,
    instanceId: string
  ): Promise<PluginInstance> {
    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.ENABLE_PLUGIN_INSTANCE,
      payload: instanceId
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }

  async disablePluginInstance(
    tabId: any,
    instanceId: string
  ): Promise<PluginInstance> {
    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.DISABLE_PLUGIN_INSTANCE,
      payload: instanceId
    })

    return new Promise((resolve) => {
      resolve(result)
    })
  }

  async getAnalyserData(tabId: any, playerId: string): Promise<any> {
    // console.log(
    //   `ExtensionContentPluginBackgroundControllerImpl, getAnalyserData`
    // )
    const result = await this.browser.tabs.sendMessage(tabId, {
      action: ExtensionAction.GET_ANALYZER_DATA,
      payload: playerId
    })
    // console.log(`result`, result)
    // console.log(`result.timeData`, result.timeData)
    // console.log(`result.frequencyData`, result.frequencyData)

    const analyserData = {
      bufferSize: result.frequencyBinCount,
      frequencyData: new Uint8Array(result.frequencyBinCount),
      timeData: new Uint8Array(result.frequencyBinCount)
    }

    for (let i = 0; i < result.frequencyBinCount; i++) {
      analyserData.frequencyData[i] = result.frequencyData[i]
      analyserData.timeData[i] = result.timeData[i]
    }

    // console.log(`analyserData`, analyserData)

    return new Promise((resolve) => {
      resolve(analyserData)
    })
  }
}
