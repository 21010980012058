import Settings from './Settings'
class DefaultSettingsService {
  public getDefaultSettings(): Settings {
    const settings: Settings = {} as Settings

    settings.audioOutputDevice = `SPEAKERS`
    settings.processingEnabled = true

    return settings
  }
}

export default DefaultSettingsService
