import { IPluginService } from '../../audio-core/plugin/IPluginService'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { PluginGainParametersService } from '../../audio-plugins-parameters/gain/plugin-gain-parameters.service'
import { GainAudioNode } from './gain-audio-node'

export class GainPluginService implements IPluginService {
  constructor(
    private pluginGainParametersService: PluginGainParametersService
  ) {}

  createAudioNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<IAudioNode> {
    return new Promise((resolve, reject) => {
      pluginInstance =
        this.pluginGainParametersService.ensurePluginInstanceParameterKeyValues(
          pluginInstance
        )

      var audioNode: GainAudioNode = new GainAudioNode(
        audioContext,
        this.pluginGainParametersService.getParameterKeyValues(pluginInstance)
      )

      resolve(audioNode)
    })
  }

  setParameterValue(
    audioContext: AudioContext,
    audioNode: IAudioNode,
    parameterKeyValue: ParameterKeyValue,
    browser: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      audioNode.setParameter(parameterKeyValue)

      switch (parameterKeyValue.key) {
        case PluginGainParametersService.GAIN:
          ;(<GainAudioNode>audioNode).gain.gain.setValueAtTime(
            parameterKeyValue.value,
            audioContext.currentTime
          )
          resolve()
          break
        default:
          console.error(
            'No available parameter with name: ' + parameterKeyValue.key
          )
          reject()
      }
    })
  }
}
