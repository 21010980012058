export class Complex {
  private _real: number
  private _imag: number

  constructor(real: number, imag: number) {
    this._real = real
    this._imag = imag
  }

  conj(): Complex {
    return new Complex(this.real, -this.imag)
  }

  mul(complex: Complex): Complex {
    var newReal = this.real * complex.real - this.imag * complex.imag
    var newImag = this.real * complex.imag + this.imag * complex.real
    return new Complex(newReal, newImag)
  }

  mulWithRealNumber(aRealNumber: number): Complex {
    return new Complex(this.real * aRealNumber, this.imag * aRealNumber)
  }

  negative(): Complex {
    return new Complex(-this.real, -this.imag)
  }

  div(complex: Complex): Complex {
    var denom = complex.real * complex.real + complex.imag * complex.imag
    var tmp = (this.real * complex.real + this.imag * complex.imag) / denom
    var newImag = (this.imag * complex.real - this.real * complex.imag) / denom
    var newReal = tmp

    return new Complex(newReal, newImag)
  }

  add(complex: Complex): Complex {
    return new Complex(this.real + complex.real, this.imag + complex.imag)
  }

  sub(complex: Complex): Complex {
    return new Complex(this.real - complex.real, this.imag - complex.imag)
  }

  get real(): number {
    return this._real
  }

  get imag(): number {
    return this._imag
  }
}
