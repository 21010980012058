import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'

export class ConvolverAudioNode
  extends AbstractAudioNode
  implements IAudioNode
{
  private input: GainNode
  private output: GainNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    super(audioContext, parameters)

    this.input = this.audioContext.createGain()
    this.output = this.audioContext.createGain()

    // TODO:
    this.wire()
  }

  wire(): Promise<any> {
    this.input.connect(this.output)

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.input
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }
}
