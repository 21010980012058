import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'

export class PluginRaceParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static FILTER_LENGTH: string = 'filterLength'
  public static ATTENUATION: string = 'attenuation'
  public static DELAY: string = 'delay'

  private static DEFAULT_FILTER_LENGTH: number = 2048
  private static DEFAULT_ATTENUATION_VALUE: number = 0.794328212
  private static DEFAULT_DELAY_VALUE: number = 3

  constructor() {
    super()

    this.parameters.push({
      key: PluginRaceParametersService.FILTER_LENGTH,
      value: PluginRaceParametersService.DEFAULT_FILTER_LENGTH
    })

    this.parameters.push({
      key: PluginRaceParametersService.ATTENUATION,
      value: PluginRaceParametersService.DEFAULT_ATTENUATION_VALUE
    })

    this.parameters.push({
      key: PluginRaceParametersService.DELAY,
      value: PluginRaceParametersService.DEFAULT_DELAY_VALUE
    })
  }

  public getPluginId(): any {
    return 'RACE_TWO_CHANNELS'
  }

  public getPresetDiscriminator(): string {
    return null
  }
}
