import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'

export class ConvolverPluginParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  constructor() {
    super()
  }

  public getPluginId(): any {
    return 'CONVOLVER'
  }

  public getPresetDiscriminator(): string {
    return null
  }
}
