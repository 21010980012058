import { IPluginParameters } from './IPluginParameters'
import { ParameterKeyValue } from './ParameterKeyValue'
import { PluginInstanceParameterKeyValue } from './PluginInstanceParameterKeyValue'
import { PluginInstance } from './PluginInstance'
import { Preset } from './Preset'

export abstract class AbstractPluginParametersService
  implements IPluginParameters
{
  public static ENABLED: string = 'enabled'
  public static PRESET: string = 'preset'

  private static DEFAULT_ENABLED: boolean = true
  private static DEFAULT_PRESET: string = 'default'

  protected parameters: Array<ParameterKeyValue> = []
  protected presets: Array<Preset> = []

  constructor() {
    this.parameters.push({
      key: AbstractPluginParametersService.ENABLED,
      value: AbstractPluginParametersService.DEFAULT_ENABLED
    })

    this.parameters.push({
      key: AbstractPluginParametersService.PRESET,
      value: AbstractPluginParametersService.DEFAULT_PRESET
    })
  }

  public abstract getPluginId(): string

  public abstract getPresetDiscriminator(): string

  public getPresets(discriminator?: string): Array<Preset> {
    return this.presets
  }

  getPluginInstanceParameterKeyValues(
    pluginId: string,
    instanceId: string
  ): Array<PluginInstanceParameterKeyValue> {
    return this.parameters.map((p) => {
      return {
        pluginId: pluginId,
        instanceId: instanceId,
        parameter: p
      }
    })
  }

  public ensureLocalStorage(): void {
    if (!this.parameters) {
      console.error('Default Parameters are not defined!')
      return
    }

    const key = 'activePreset'
    const item = localStorage.getItem(key)

    let obj: any = null
    if (item) {
      obj = JSON.parse(item)

      if (obj.pluginInstances) {
        obj.pluginInstances.forEach((pluginInstance: PluginInstance) => {
          if (pluginInstance.pluginId === this.getPluginId()) {
            // Append missing default parameters
            this.parameters.forEach((p) => {
              const foundInstanceParameter = pluginInstance.parameters?.find(
                (pi) => pi.parameter.key === p.key
              )

              if (!foundInstanceParameter && pluginInstance.parameters) {
                // console.log('ensureLocalStorage - Appending Parameter with key: ' + p.key + ' and value: ' + p.value);
                pluginInstance.parameters.push({
                  pluginId: pluginInstance.pluginId,
                  instanceId: pluginInstance.instanceId,
                  parameter: p
                })

                localStorage.setItem(key, JSON.stringify(obj))
              }
            })

            // Remove unnecessary parameters
            // TODO

            // Ensure also presets
            pluginInstance.presets = this.ensurePresets(pluginInstance.presets)
            localStorage.setItem(key, JSON.stringify(obj))
          }
        })
      }
    }
  }

  public ensurePresets(presetsInStorage: Array<Preset> | undefined) {
    if (!presetsInStorage) {
      presetsInStorage = []
    }

    this.presets.forEach((preset) => {
      const foundPresetInStorage = presetsInStorage?.find(
        (ps) => ps.presetId === preset.presetId
      )

      if (foundPresetInStorage) {
        foundPresetInStorage.parameters = preset.parameters
      } else {
        if (presetsInStorage) {
          presetsInStorage.push(preset)
        }
      }
    })

    // Remove unnecessary presets
    const presetsToReturn: Array<Preset> = []
    presetsInStorage.forEach((preset) => {
      const foundPresetInPlugin = this.presets.find(
        (ps) => ps.presetId === preset.presetId
      )

      if (foundPresetInPlugin) {
        presetsToReturn.push(preset)
      }
    })

    return presetsToReturn
  }

  public ensurePluginInstanceParameterKeyValues(
    pluginInstance: PluginInstance
  ): PluginInstance | undefined {
    if (!this.parameters) {
      console.error('Default Parameters are not defined!')
      return undefined
    }

    if (pluginInstance.parameters) {
      // console.log('ensurePluginInstanceParameterKeyValues - Parameters exist - updating');

      // Append missing default parameters
      this.parameters.forEach((p) => {
        const foundInstanceParameter = pluginInstance.parameters?.find(
          (pi) => pi.parameter.key === p.key
        )
        if (!foundInstanceParameter) {
          // console.log('ensurePluginInstanceParameterKeyValues - Appending Parameter with key: ' + p.key + ' and value: ' + p.value);
          if (pluginInstance.parameters) {
            pluginInstance.parameters.push({
              pluginId: pluginInstance.pluginId,
              instanceId: pluginInstance.instanceId,
              parameter: p
            })
          }
        }
      })

      // Remove unnecessary parameters
      // TODO
    } else {
      // console.log('ensurePluginInstanceParameterKeyValues - No parameters exist - setting default');
      pluginInstance.parameters = this.getPluginInstanceParameterKeyValues(
        pluginInstance.pluginId,
        pluginInstance.instanceId
      )
    }

    return pluginInstance
  }

  public getParameterKeyValues(
    pluginInstance: PluginInstance
  ): Array<ParameterKeyValue> {
    return pluginInstance && pluginInstance.parameters
      ? pluginInstance.parameters.map((p) => {
          return {
            key: p.parameter.key,
            value: p.parameter.value
          }
        })
      : []
  }

  public getParameterValue(pluginInstance: PluginInstance, key: string): any {
    var pluginInstanceParameterKeyValue:
      | PluginInstanceParameterKeyValue
      | undefined = pluginInstance.parameters?.find(
      (p) => p.parameter.key === key
    )
    if (!pluginInstanceParameterKeyValue) {
      console.error('Could not find parameter in instance with key: ' + key)
    }
    return pluginInstanceParameterKeyValue
      ? pluginInstanceParameterKeyValue.parameter.value
      : undefined
  }

  private getDefaultParameterValue(key: string): any {
    if (!this.parameters) {
      console.error('Parameters are not defined!')
    }

    const parameterKeyValue: ParameterKeyValue | undefined =
      this.parameters.find((p) => p.key === key)
    if (!parameterKeyValue) {
      console.error('Could not find parameter with key: ' + key)
    }
    return parameterKeyValue ? parameterKeyValue.value : ``
  }
}
