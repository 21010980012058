import { VirtualAmbioFilters } from './VirtualAmbioFilters'
import { XtcFilters } from '../race/XtcFilters'
import { RaceDspService } from '../race/race-dsp.service'
import { HrtfDatabase } from '../../../audio-core/hrtf/HrtfDatabase'
import { HrtfLoader } from '../../../audio-core/hrtf/HrtfLoader'

export class VirtualAmbioFilterCalculatorService {
  private hrtfLoader: HrtfLoader
  private raceDspService: RaceDspService

  constructor(hrtfLoader: HrtfLoader) {
    this.hrtfLoader = hrtfLoader
    this.raceDspService = new RaceDspService()
  }

  /*
   * Calculate.
   *
   * @param audioContext
   * @param virtualAmbioUserParameters
   * @returns {Promise<T>|Promise|Promise<R>}
   */
  public calculate(
    baseUrlData: string,
    audioContext: AudioContext,
    args: any,
    browser: any
  ): Promise<VirtualAmbioFilters> {
    // console.log('VirtualAmbioFilterCalculatorService calculate');
    // console.log(args);

    var self = this
    return new Promise(function (resolve, reject) {
      if (!args) {
        reject()
        return
      }

      // CALCULATE XTC
      self.raceDspService
        .calculateWithPromise({
          filterLength: args.xtcFilterLength,
          attenuation: Math.pow(10, args.xtcAttenuations[0] / 20),
          delay: args.xtcDelay
        })
        .then(
          (result: any) => {
            var xtcFilters: XtcFilters = new XtcFilters(
              Array.from(result.left),
              Array.from(result.right),
              Array.from(result.right),
              Array.from(result.left)
            )
            xtcFilters.createAudioBuffers(audioContext)
            // console.log('VirtualAmbioFilterCalculatorService');
            // console.log(xtcFilters);

            // CALCULATE SIDE HRTF
            self.hrtfLoader
              .getHrtf(baseUrlData, args.sideHrtf, 'full', browser)
              .then(
                (resultSide: any) => {
                  var hrtfDatabaseSide: HrtfDatabase = resultSide
                  hrtfDatabaseSide.createAudioBuffers(audioContext)
                  // console.log(resultSide);

                  var sideLeftAngle = args.sideAnglePerSpeaker
                  var sideRightAngle = 360 - sideLeftAngle
                  var binauralIRSideLeft: AudioBuffer =
                    hrtfDatabaseSide.getHrtfRecord(
                      audioContext,
                      sideLeftAngle,
                      0
                    ).buffer
                  var binauralIRSideRight: AudioBuffer =
                    hrtfDatabaseSide.getHrtfRecord(
                      audioContext,
                      sideRightAngle,
                      0
                    ).buffer

                  // CALCULATE HRTF FRONT BACK
                  self.hrtfLoader
                    .getHrtf(baseUrlData, args.frontBackHrtf, 'full', browser)
                    .then(
                      (resultFrontBack: any) => {
                        var hrtfDatabaseFrontBack: HrtfDatabase =
                          resultFrontBack
                        hrtfDatabaseFrontBack.createAudioBuffers(audioContext)
                        // console.log(resultFrontBack);

                        // FRONT
                        var frontLeftAngle = args.frontAnglePerSpeaker
                        var frontRightAngle = 360 - frontLeftAngle
                        var binauralIRFrontLeft: AudioBuffer =
                          hrtfDatabaseFrontBack.getHrtfRecord(
                            audioContext,
                            frontLeftAngle,
                            0
                          ).buffer
                        var binauralIRFrontRight: AudioBuffer =
                          hrtfDatabaseFrontBack.getHrtfRecord(
                            audioContext,
                            frontRightAngle,
                            0
                          ).buffer

                        // BACK
                        var backLeftAngle = args.backAnglePerSpeaker
                        var backRightAngle = 360 - backLeftAngle
                        var binauralIRBackLeft: AudioBuffer =
                          hrtfDatabaseFrontBack.getHrtfRecord(
                            audioContext,
                            backLeftAngle,
                            0
                          ).buffer
                        var binauralIRBackRight: AudioBuffer =
                          hrtfDatabaseFrontBack.getHrtfRecord(
                            audioContext,
                            backRightAngle,
                            0
                          ).buffer

                        var virtualAmbioFilters: VirtualAmbioFilters =
                          new VirtualAmbioFilters(
                            binauralIRFrontLeft,
                            binauralIRFrontRight,
                            binauralIRBackLeft,
                            binauralIRBackRight,
                            binauralIRSideLeft,
                            binauralIRSideRight,
                            xtcFilters
                          )

                        resolve(virtualAmbioFilters)
                      },
                      (result: any) => {
                        reject(result)
                      }
                    )
                },
                (result: any) => {
                  reject(result)
                }
              )
          },
          (result: any) => {
            reject(result)
          }
        )
    })
  }
}
