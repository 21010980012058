import { IPluginService } from '../../audio-core/plugin/IPluginService'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { PluginRaceParametersService } from '../../audio-plugins-parameters/race/plugin-race-parameters.service'
import { RaceAudioNode } from './race-audio-node'
import { RaceDspService } from './services/race-dsp.service'
import { WebWorkerService } from './web-worker.service'
import { XtcFilters } from './XtcFilters'

export class RacePluginService implements IPluginService {
  constructor(
    private pluginRaceParametersService: PluginRaceParametersService,
    private webWorkerService: WebWorkerService,
    private raceDspService: RaceDspService
  ) {}

  createAudioNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<IAudioNode> {
    return new Promise((resolve, reject) => {
      pluginInstance =
        this.pluginRaceParametersService.ensurePluginInstanceParameterKeyValues(
          pluginInstance
        )

      this.webWorkerService
        .run(this.raceDspService.calculate, {
          filterLength: this.pluginRaceParametersService.getParameterValue(
            pluginInstance,
            PluginRaceParametersService.FILTER_LENGTH
          ),
          attenuation: this.pluginRaceParametersService.getParameterValue(
            pluginInstance,
            PluginRaceParametersService.ATTENUATION
          ),
          delay: this.pluginRaceParametersService.getParameterValue(
            pluginInstance,
            PluginRaceParametersService.DELAY
          )
        })
        .then(
          (result) => {
            // console.log(result);

            var xtcFilters: XtcFilters = new XtcFilters(
              Array.from(result.left),
              Array.from(result.right),
              Array.from(result.right),
              Array.from(result.left)
            )

            var audioNode: RaceAudioNode = new RaceAudioNode(
              audioContext,
              this.pluginRaceParametersService.getParameterKeyValues(
                pluginInstance
              ),
              xtcFilters
            )
            resolve(audioNode)
          },
          (error) => {
            reject()
          }
        )
    })
  }

  setParameterValue(
    audioContext: AudioContext,
    audioNode: IAudioNode,
    parameterKeyValue: ParameterKeyValue,
    browser: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      audioNode.setParameter(parameterKeyValue)

      this.webWorkerService
        .run(this.raceDspService.calculate, {
          filterLength: audioNode.getParameter(
            PluginRaceParametersService.FILTER_LENGTH
          ).value,
          attenuation: audioNode.getParameter(
            PluginRaceParametersService.ATTENUATION
          ).value,
          delay: audioNode.getParameter(PluginRaceParametersService.DELAY).value
        })
        .then(
          (result) => {
            console.log(result)

            var xtcFilters: XtcFilters = new XtcFilters(
              Array.from(result.left),
              Array.from(result.right),
              Array.from(result.right),
              Array.from(result.left)
            )

            ;(<RaceAudioNode>audioNode).updateXtcFilters(xtcFilters)

            resolve(audioNode)
          },
          (error) => {
            reject()
          }
        )
    })
  }

  private race(args: any) {
    var left: Float32Array = new Float32Array(args.filterLength).fill(0)
    var right: Float32Array = new Float32Array(args.filterLength).fill(0)

    left[0] = 1
    right[0] = 0

    for (var i = 0; i < args.filterLength; i++) {
      left[i] =
        left[i] -
        args.attenuation * (i - args.delay < 0 ? 0 : right[i - args.delay])
      right[i] =
        right[i] -
        args.attenuation * (i - args.delay < 0 ? 0 : left[i - args.delay])
    }

    return {
      left: left,
      right: right
    }
  }
}
