import { ArccDspService } from './services/arcc-dsp.service'
import { IPluginService } from '../../audio-core/plugin/IPluginService'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import ArccPluginParametersService from '../../audio-plugins-parameters/arcc/arcc-plugin-parameters.service'
import { ArccAudioNode } from './arcc-audio-node'
import { XtcFilters } from './XtcFilters'

export class ArccPluginService implements IPluginService {
  constructor(
    private arccPluginParametersService: ArccPluginParametersService,
    private arccDspService: ArccDspService
  ) {}

  createAudioNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<IAudioNode> {
    return new Promise((resolve, reject) => {
      pluginInstance =
        this.arccPluginParametersService.ensurePluginInstanceParameterKeyValues(
          pluginInstance
        )

      const numberOfBands = this.arccPluginParametersService.getParameterValue(
        pluginInstance,
        ArccPluginParametersService.NUMBER_OF_BANDS
      )
      let frequencies = null
      let attenuations = null

      // console.log('createAudioNode in ArccPluginService');
      // console.log(numberOfBands);
      // console.log(pluginInstance);

      if (numberOfBands === 1) {
        frequencies = this.arccPluginParametersService.getParameterValue(
          pluginInstance,
          ArccPluginParametersService.FREQUENCIES_1
        )
        attenuations = this.arccPluginParametersService.getParameterValue(
          pluginInstance,
          ArccPluginParametersService.ATTENUATIONS_1
        )
      } else if (numberOfBands === 3) {
        frequencies = this.arccPluginParametersService.getParameterValue(
          pluginInstance,
          ArccPluginParametersService.FREQUENCIES_3
        )
        attenuations = this.arccPluginParametersService.getParameterValue(
          pluginInstance,
          ArccPluginParametersService.ATTENUATIONS_3
        )
      } else if (numberOfBands === 11) {
        frequencies = this.arccPluginParametersService.getParameterValue(
          pluginInstance,
          ArccPluginParametersService.FREQUENCIES_11
        )
        attenuations = this.arccPluginParametersService.getParameterValue(
          pluginInstance,
          ArccPluginParametersService.ATTENUATIONS_11
        )
      } else if (numberOfBands === 21) {
        frequencies = this.arccPluginParametersService.getParameterValue(
          pluginInstance,
          ArccPluginParametersService.FREQUENCIES_21
        )
        attenuations = this.arccPluginParametersService.getParameterValue(
          pluginInstance,
          ArccPluginParametersService.ATTENUATIONS_21
        )
      }

      this.arccDspService
        .calculate(audioContext, {
          filterLength: this.arccPluginParametersService.getParameterValue(
            pluginInstance,
            ArccPluginParametersService.FILTER_LENGTH
          ),
          angle: this.arccPluginParametersService.getParameterValue(
            pluginInstance,
            ArccPluginParametersService.ANGLE
          ),
          distance: this.arccPluginParametersService.getParameterValue(
            pluginInstance,
            ArccPluginParametersService.DISTANCE
          ),
          numberOfBands: numberOfBands,
          frequencies: frequencies,
          attenuations: attenuations
        })
        .then(
          (result) => {
            // console.log('createAudioNode calculate result:');
            // console.log(result);

            var xtcFilters: XtcFilters = result

            var audioNode: ArccAudioNode = new ArccAudioNode(
              audioContext,
              this.arccPluginParametersService.getParameterKeyValues(
                pluginInstance
              ),
              xtcFilters
            )

            resolve(audioNode)
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  async setParameterValue(
    audioContext: AudioContext,
    audioNode: IAudioNode,
    parameterKeyValue: ParameterKeyValue,
    browser: any
  ): Promise<any> {
    // console.log('setParameterValue in ArccPluginService')
    // console.log(parameterKeyValue)

    await audioNode.setParameter(parameterKeyValue)

    return new Promise((resolve, reject) => {
      const numberOfBands = audioNode.getParameter(
        ArccPluginParametersService.NUMBER_OF_BANDS
      ).value
      let frequencies = null
      let attenuations = null

      // console.log(numberOfBands);

      if (numberOfBands === 1) {
        frequencies = audioNode.getParameter(
          ArccPluginParametersService.FREQUENCIES_1
        ).value
        attenuations = audioNode.getParameter(
          ArccPluginParametersService.ATTENUATIONS_1
        ).value
      } else if (numberOfBands === 3) {
        frequencies = audioNode.getParameter(
          ArccPluginParametersService.FREQUENCIES_3
        ).value
        attenuations = audioNode.getParameter(
          ArccPluginParametersService.ATTENUATIONS_3
        ).value
        // console.log(`attenuations3`, attenuations)
      } else if (numberOfBands === 11) {
        frequencies = audioNode.getParameter(
          ArccPluginParametersService.FREQUENCIES_11
        ).value
        attenuations = audioNode.getParameter(
          ArccPluginParametersService.ATTENUATIONS_11
        ).value
      } else if (numberOfBands === 21) {
        frequencies = audioNode.getParameter(
          ArccPluginParametersService.FREQUENCIES_21
        ).value
        attenuations = audioNode.getParameter(
          ArccPluginParametersService.ATTENUATIONS_21
        ).value
      }

      this.arccDspService
        .calculate(audioContext, {
          filterLength: audioNode.getParameter(
            ArccPluginParametersService.FILTER_LENGTH
          ).value,
          angle: audioNode.getParameter(ArccPluginParametersService.ANGLE)
            .value,
          distance: audioNode.getParameter(ArccPluginParametersService.DISTANCE)
            .value,
          numberOfBands: numberOfBands,
          frequencies: frequencies,
          attenuations: attenuations
        })
        .then(
          (result) => {
            // console.log('setParameterValue calculate result:');
            // console.log(result);

            const xtcFilters: XtcFilters = result
            // console.log(xtcFilters);

            ;(<ArccAudioNode>audioNode).updateXtcFilters(xtcFilters)

            // let xtcParameterKeyValue: ParameterKeyValue = <ParameterKeyValue>({
            //     key: ArccPluginParametersService.XTC_FILTERS,
            //     value: xtcFilters
            // });
            // audioNode.setParameter(xtcParameterKeyValue);

            resolve(audioNode)
          },
          (error) => {
            reject(error)
          }
        )
    })
  }
}
