import { VirtualStereoFilters } from './VirtualStereoFilters'
import { VirtualSourceAudioNode } from '../virtual-source/VirtualSourceAudioNode'
import { ParameterKeyValue } from '../../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../../audio-core/web-audio-api/audio-node/IAudioNode'

export class VirtualStereoAudioNode
  extends AbstractAudioNode
  implements IAudioNode
{
  private virtualStereoFilters: VirtualStereoFilters
  private virtualSourceLeft: VirtualSourceAudioNode
  private virtualSourceRight: VirtualSourceAudioNode
  private channelSplitter: ChannelSplitterNode
  private output: GainNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>,
    virtualStereoFilters: VirtualStereoFilters
  ) {
    super(audioContext, parameters)

    this.virtualStereoFilters = virtualStereoFilters

    this.wire()
  }

  wire(): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.log('VirtualStereoAudioNode wire');

      this.channelSplitter = this.audioContext.createChannelSplitter(2)
      this.virtualSourceLeft = new VirtualSourceAudioNode(
        this.audioContext,
        null,
        this.virtualStereoFilters.getBinauralImpulseResponseForLeftVirtualSource()
      )
      this.virtualSourceRight = new VirtualSourceAudioNode(
        this.audioContext,
        null,
        this.virtualStereoFilters.getBinauralImpulseResponseForRightVirtualSource()
      )
      this.output = this.audioContext.createGain()

      this.channelSplitter.connect(this.virtualSourceLeft.getInput(), 0)
      this.channelSplitter.connect(this.virtualSourceRight.getInput(), 1)

      this.virtualSourceLeft.connect(this.output)
      this.virtualSourceRight.connect(this.output)

      resolve()
    })
  }

  getInput(): AudioNode {
    return this.channelSplitter
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }

  applyVirtualStereoFilters(virtualStereoFilters: VirtualStereoFilters) {
    // console.log('applyVirtualStereoFilters');
    // console.log(virtualStereoFilters);

    this.virtualStereoFilters = virtualStereoFilters
    this.virtualSourceLeft.updateBinauralImpulseResponse(
      this.virtualStereoFilters.getBinauralImpulseResponseForLeftVirtualSource()
    )
    this.virtualSourceRight.updateBinauralImpulseResponse(
      this.virtualStereoFilters.getBinauralImpulseResponseForRightVirtualSource()
    )
  }
}
