// declare var ARIA3DDSP: any

import ARIA3DDSP from 'aria3d-dsp'
// const ARIA3DDSP = require('aria3d-dsp')

export class DaspService {
  private daspInstance: any = null

  constructor() {
    // console.log('ARIA3DDSP', ARIA3DDSP)
    this.daspInstance = ARIA3DDSP({ noInitialRun: true })
  }

  getInstance(): any {
    return this.daspInstance
  }
}
