import { MySpecialAudioInputNode } from './MySpecialAudioInputNode'

declare var Video: { new (src?: string): HTMLVideoElement }

export class BaseAudioInputNode {
  constructor() {}

  /*
   * Reads a file as Array Buffer.
   * @param aFile
   * @returns {Promise<T>|Promise<R>|Promise}
   */
  public readFileAsArrayBuffer(aFile: File): Promise<any> {
    var reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        resolve(reader.result)
      }

      reader.onerror = function () {
        reject()
      }

      try {
        reader.readAsArrayBuffer(aFile)
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  /*
   * Creates AudioStream.
   *
   * @param url
   * @param audioContext
   * @returns {Promise<T>|Promise<R>|Promise}
   */
  public createAudioStream(
    url: string,
    audioContext: AudioContext
  ): Promise<MySpecialAudioInputNode> {
    var mySpecialAudioInputNode: MySpecialAudioInputNode =
      new MySpecialAudioInputNode()

    mySpecialAudioInputNode.htmlMediaElement = new Audio()
    mySpecialAudioInputNode.htmlMediaElement.setAttribute('eq-attached', 'true')
    mySpecialAudioInputNode.htmlMediaElement.setAttribute(
      'aria3d-attached',
      'true'
    )
    mySpecialAudioInputNode.htmlMediaElement['crossOrigin'] = 'anonymous'
    mySpecialAudioInputNode.htmlMediaElement.src = url
    mySpecialAudioInputNode.htmlMediaElement.autoplay = false
    mySpecialAudioInputNode.htmlMediaElement.controls = true
    mySpecialAudioInputNode.htmlMediaElement.loop = false

    try {
      mySpecialAudioInputNode.htmlMediaElement.load()
    } catch (err) {
      console.error('mySpecialAudioInputNode.htmlMediaElement.load(); FAILED!')
      console.error(err)
    }

    return new Promise((resolve, reject) => {
      // error
      mySpecialAudioInputNode.htmlMediaElement.addEventListener(
        'error',
        function (err) {
          console.error(err)
          reject()
        }
      )

      // canplay
      mySpecialAudioInputNode.htmlMediaElement.addEventListener(
        'canplay',
        function () {
          if (mySpecialAudioInputNode.mediaElementAudioSourceNode === null) {
            try {
              mySpecialAudioInputNode.mediaElementAudioSourceNode =
                audioContext.createMediaElementSource(
                  mySpecialAudioInputNode.htmlMediaElement
                )
            } catch (err) {
              console.error(err)
              reject(new Error(err))
            }
          }

          resolve(mySpecialAudioInputNode)
        }
      )
    })
  }

  /*
   *
   * @param url
   * @param audioContext
   * @param index
   * @returns {Promise<T>|Promise<R>|Promise}
   */
  public createVideoStream(
    url: string,
    audioContext: AudioContext,
    index: number
  ): Promise<MySpecialAudioInputNode> {
    var mySpecialAudioInputNode: MySpecialAudioInputNode =
      new MySpecialAudioInputNode()

    mySpecialAudioInputNode.videoPlaceholder = document.getElementById(
      'videoPlaceholder-' + index
    )
    while (mySpecialAudioInputNode.videoPlaceholder.firstChild) {
      mySpecialAudioInputNode.videoPlaceholder.removeChild(
        mySpecialAudioInputNode.videoPlaceholder.firstChild
      )
    }

    mySpecialAudioInputNode.htmlMediaElement = document.createElement('video')
    mySpecialAudioInputNode.htmlMediaElement.setAttribute('eq-attached', 'true')
    mySpecialAudioInputNode.htmlMediaElement.setAttribute(
      'aria3d-attached',
      'true'
    )
    mySpecialAudioInputNode.videoPlaceholder.appendChild(
      mySpecialAudioInputNode.htmlMediaElement
    )

    mySpecialAudioInputNode.htmlMediaElement['crossOrigin'] = 'anonymous'
    mySpecialAudioInputNode.htmlMediaElement.src = url
    mySpecialAudioInputNode.htmlMediaElement.autoplay = false
    mySpecialAudioInputNode.htmlMediaElement.controls = false
    mySpecialAudioInputNode.htmlMediaElement.loop = false

    var htmlVideoElement: HTMLVideoElement = <HTMLVideoElement>(
      mySpecialAudioInputNode.htmlMediaElement
    )
    htmlVideoElement.width =
      mySpecialAudioInputNode.videoPlaceholder.clientWidth
    htmlVideoElement.height =
      mySpecialAudioInputNode.videoPlaceholder.clientHeight

    // mySpecialAudioInputNode.htmlmediaElement.type = 'video/mp4';
    // TODO -> autobuffer

    mySpecialAudioInputNode.htmlMediaElement.load()

    return new Promise((resolve, reject) => {
      mySpecialAudioInputNode.htmlMediaElement.addEventListener(
        'canplay',
        function () {
          if (mySpecialAudioInputNode.mediaElementAudioSourceNode === null) {
            try {
              mySpecialAudioInputNode.mediaElementAudioSourceNode =
                audioContext.createMediaElementSource(
                  mySpecialAudioInputNode.htmlMediaElement
                )
            } catch (err) {
              console.error(err)
              reject(new Error(err))
            }
          }

          resolve(mySpecialAudioInputNode)
        }
      )
    })
  }
}
