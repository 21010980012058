import { IPluginService } from '../../audio-core/plugin/IPluginService'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { ConvolverPluginParametersService } from '../../audio-plugins-parameters/convolver/convolver-plugin-parameters.service'
import { ConvolverAudioNode } from './convolver-audio-node'

export class ConvolverPluginService implements IPluginService {
  constructor(
    private convolverPluginParametersService: ConvolverPluginParametersService
  ) {}

  createAudioNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<IAudioNode> {
    return new Promise((resolve, reject) => {
      pluginInstance =
        this.convolverPluginParametersService.ensurePluginInstanceParameterKeyValues(
          pluginInstance
        )

      var audioNode: ConvolverAudioNode = new ConvolverAudioNode(
        audioContext,
        this.convolverPluginParametersService.getParameterKeyValues(
          pluginInstance
        )
      )

      resolve(audioNode)
    })
  }

  setParameterValue(
    audioContext: AudioContext,
    audioNode: IAudioNode,
    parameterKeyValue: ParameterKeyValue,
    browser: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      audioNode.setParameter(parameterKeyValue)

      switch (parameterKeyValue.key) {
        default:
          console.error(
            'No available parameter with name: ' + parameterKeyValue.key
          )
          reject()
      }
    })
  }
}
