export default class AudioContextProvider {
  private static audioContext: any = null
  private static graphAudioContext: any = null

  constructor() {
    AudioContextProvider.audioContext = null
  }

  public static getAudioContext(): any {
    AudioContextProvider.createAudioContext()
    return AudioContextProvider.audioContext
  }

  public static getOfflineAudioContext(): any {
    AudioContextProvider.createGraphAudioContext()
    return AudioContextProvider.graphAudioContext
  }

  private static createAudioContext() {
    if (typeof window === 'undefined') {
      return
    }

    if (AudioContextProvider.audioContext == null) {
      // console.log('AudioContextProvider.audioContext IS null');
      var contextClass =
        window['AudioContext'] ||
        window['webkitAudioContext'] ||
        window['mozAudioContext'] ||
        window['mozAudioContext'] ||
        window['oAudioContext']

      if (contextClass) {
        // console.log('Found a class for AudioContext construction.');
        AudioContextProvider.audioContext = new contextClass()

        // Hack for iOS when you first boot the iPhone
        var desiredSampleRate: number = 44100
        var buffer = AudioContextProvider.audioContext.createBuffer(
          1,
          1,
          desiredSampleRate
        )
        var dummy = AudioContextProvider.audioContext.createBufferSource()
        dummy.buffer = buffer
        dummy.connect(AudioContextProvider.audioContext.destination)
        if (typeof dummy['start'] === 'function') {
          // console.log('dummy start was called');
          dummy.start(0)
        } else {
          console.warn(
            'start(0) is not a function for dummy AudioBufferSourceNode.'
          )
        }
        dummy.disconnect()

        if (typeof AudioContextProvider.audioContext['close'] === 'function') {
          // console.log('AudioContextProvider.audioContext close was called');
          AudioContextProvider.audioContext.close()
        } else {
          console.warn('close() is not a function for audioContext.')
        }
        AudioContextProvider.audioContext = null
        AudioContextProvider.audioContext = new contextClass()
      } else {
        console.error('Could not find a class for AudioContext construction.')
        AudioContextProvider.audioContext = null
      }
    }

    if (AudioContextProvider.audioContext != null) {
      // console.log(AudioContextProvider.audioContext);
      // Prevent suspending state in IOS
      if (AudioContextProvider.audioContext.state === 'suspended') {
        if (typeof AudioContextProvider.audioContext['resume'] === 'function') {
          // console.log('state is suspended. resume() will be called!');
          AudioContextProvider.audioContext.resume()
        } else {
          console.warn('resume() is not a function for audioContext.')
        }
      }
      // console.log(AudioContextProvider.audioContext);
    } else {
      console.error(
        'Sorry. WebAudio API is not supported on your browser. Try using Google Chrome.'
      )
      alert(
        'Sorry. WebAudio API is not supported on your browser. Try using Google Chrome.'
      )
    }
  }

  private static createGraphAudioContext() {
    if (typeof window === 'undefined') {
      return
    }

    if (AudioContextProvider.graphAudioContext == null) {
      var contextClass =
        window['AudioContext'] ||
        window['webkitAudioContext'] ||
        window['mozAudioContext'] ||
        window['mozAudioContext'] ||
        window['oAudioContext']

      if (contextClass) {
        AudioContextProvider.graphAudioContext = new contextClass()
      } else {
        AudioContextProvider.graphAudioContext = null
      }
    }

    if (AudioContextProvider.graphAudioContext == null) {
      console.error(
        'Sorry. WebAudio API not supported on your browser. Try using the Google Chrome.'
      )
      alert(
        'Sorry. WebAudio API not supported on your browser. Try using the Google Chrome.'
      )
    }
  }
}
