export enum MediaContentType {
  NONE,
  AUDIO_STREAM,
  AUDIO_LOCAL_FILE,
  AUDIO_REMOTE_FILE,
  AUDIO_DOM,
  VIDEO_STREAM,
  VIDEO_LOCAL_FILE,
  VIDEO_REMOTE_FILE,
  VIDEO_DOM,
  MEDIA_STREAM
}

export default MediaContentType
