import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'

export class EqBiquadPluginParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static FILTER_LENGTH: string = 'filterLength'
  public static NUMBER_OF_BANDS: string = 'numberOfBands'
  public static PREAMP_GAIN: string = 'preampGain'

  public static FREQUENCIES_3: string = 'frequencies3'
  public static GAIN_RATIOS_3: string = 'gainRatios3'
  public static QS_3: any = 'qs3'

  public static FREQUENCIES_11: string = 'frequencies11'
  public static GAIN_RATIOS_11: string = 'gainRatios11'
  public static QS_11: any = 'qs11'

  public static FREQUENCIES_21: string = 'frequencies21'
  public static GAIN_RATIOS_21: string = 'gainRatios21'
  public static QS_21: any = 'qs21'

  private static DEFAULT_FILTER_LENGTH: number = 2048 * 1
  private static DEFAULT_NUMBER_OF_BANDS: number = 11
  private static DEFAULT_PREAMP_GAIN: number = 1

  private static DEFAULT_FREQUENCIES_3: Array<number> = new Array<number>(
    250,
    2000,
    8000
  )
  private static DEFAULT_GAIN_RATIOS_3: Array<number> = new Array<number>(
    0,
    0,
    0
  )
  private static DEFAULT_QS_3: Array<number> = new Array<number>(
    0.7071,
    0.7071,
    0.7071
  )

  private static DEFAULT_FREQUENCIES_11: Array<number> = new Array<number>(
    16,
    31.5,
    63,
    125,
    250,
    500,
    1000,
    2000,
    4000,
    8000,
    16000
  )
  private static DEFAULT_GAIN_RATIOS_11: Array<number> = new Array<number>(
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0
  )
  private static DEFAULT_QS_11: Array<number> = new Array<number>(
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071
  )

  private static DEFAULT_FREQUENCIES_21: Array<number> = new Array<number>(
    16,
    22,
    31.5,
    44,
    63,
    88,
    125,
    177,
    250,
    354,
    500,
    707,
    1000,
    1414,
    2000,
    2828,
    4000,
    5656,
    8000,
    11314,
    16000
  )
  private static DEFAULT_GAIN_RATIOS_21: Array<number> = new Array<number>(
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0
  )
  private static DEFAULT_QS_21: Array<number> = new Array<number>(
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071,
    0.7071
  )

  constructor() {
    super()

    this.parameters.push({
      key: EqBiquadPluginParametersService.FILTER_LENGTH,
      value: EqBiquadPluginParametersService.DEFAULT_FILTER_LENGTH
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.NUMBER_OF_BANDS,
      value: EqBiquadPluginParametersService.DEFAULT_NUMBER_OF_BANDS
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.PREAMP_GAIN,
      value: EqBiquadPluginParametersService.DEFAULT_PREAMP_GAIN
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.FREQUENCIES_3,
      value: EqBiquadPluginParametersService.DEFAULT_FREQUENCIES_3
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.GAIN_RATIOS_3,
      value: EqBiquadPluginParametersService.DEFAULT_GAIN_RATIOS_3
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.QS_3,
      value: EqBiquadPluginParametersService.DEFAULT_QS_3
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.FREQUENCIES_11,
      value: EqBiquadPluginParametersService.DEFAULT_FREQUENCIES_11
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.GAIN_RATIOS_11,
      value: EqBiquadPluginParametersService.DEFAULT_GAIN_RATIOS_11
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.QS_11,
      value: EqBiquadPluginParametersService.DEFAULT_QS_11
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.FREQUENCIES_21,
      value: EqBiquadPluginParametersService.DEFAULT_FREQUENCIES_21
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.GAIN_RATIOS_21,
      value: EqBiquadPluginParametersService.DEFAULT_GAIN_RATIOS_21
    })

    this.parameters.push({
      key: EqBiquadPluginParametersService.QS_21,
      value: EqBiquadPluginParametersService.DEFAULT_QS_21
    })

    this.initializePresets()
  }

  public getPluginId(): any {
    return 'EQ_BIQUAD'
  }

  public getPresetDiscriminator(): string {
    return EqBiquadPluginParametersService.DEFAULT_NUMBER_OF_BANDS.toString()
  }

  public static getDefaultGainRatios3(): Array<number> {
    return EqBiquadPluginParametersService.DEFAULT_GAIN_RATIOS_3.slice(0)
  }

  public static getDefaultGainRatios11(): Array<number> {
    return EqBiquadPluginParametersService.DEFAULT_GAIN_RATIOS_11.slice(0)
  }

  public static getDefaultGainRatios21(): Array<number> {
    return EqBiquadPluginParametersService.DEFAULT_GAIN_RATIOS_21.slice(0)
  }

  private initializePresets() {
    this.presets = []

    this.initializePresets21()
    this.initializePresets11()
  }

  private initializePresets21() {
    let eqPreset21 = {
      acoustic: [
        5, 5, 5, 4.75, 4.5, 4.25, 4, 2.5, 1, 1.5, 2, 2, 2, 2.75, 3.5, 3.75, 4,
        3.75, 3.5, 2.75, 2
      ],
      bassBooster: [
        5, 5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ],
      bassReducer: [
        -5, -5, -5, -4.5, -4, -3.5, -3, -2.5, -2, -1.5, 1, 0.5, 0, 0, 0, 0, 0,
        0, 0, 0, 0
      ],
      classical: [
        4.5, 4.5, 4.5, 4.25, 4, 3.5, 3, 2.5, 2, 0.5, -1, -1, -1, -0.5, 0, 1, 2,
        2.5, 3, 3.5, 4
      ],
      dance: [
        4, 4, 4, 5.5, 7, 6, 5, 2.5, 0, 1, 2, 2.5, 3, 4, 5, 4.5, 4, 3.5, 3, 1.5,
        0
      ],
      deep: [
        5, 5, 5, 4, 3, 2.5, 2, 1.5, 1, 2, 3, 2.5, 2, 1.5, 1, -0.5, -2, -3, -4,
        -4.5, -5
      ],
      electronic: [
        4.5, 4.5, 4.5, 4, 3.5, 2.25, 1, 0.5, 0, -1, -2, 0, 2, 1.25, 0.5, 0.75,
        1, 2.5, 4, 4.5, 5
      ],
      flat: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      hipHop: [
        5, 5, 5, 4.5, 4, 2.5, 1, 2, 3, 1, -1, -1, -1, 0, 1, 0, -1, 0.5, 2, 2.5,
        3
      ],
      jazz: [
        4, 4, 4, 3.5, 3, 2, 1, 1.5, 2, 0, -2, -2, -2, -1, 0, 0.5, 1, 2, 3, 3.5,
        4
      ],
      latin: [
        4.5, 4.5, 4.5, 3.75, 3, 1.5, 0, 0, 0, -0.75, -1.5, -1.5, -1.5, -1.5,
        -1.5, -0.75, 0, 1.5, 3, 3.75, 4.5
      ],
      loudness: [
        6, 6, 6, 5, 4, 2, 0, 0, 0, -1, -2, -1, 0, -0.5, -1, -3, -5, 0, 5, 3, 1
      ],
      lounge: [
        -3, -3, -3, -2.25, -1.5, -1, -0.5, 0.75, 1.5, 2.75, 4, 3, 2, 1, 0,
        -0.75, -1.5, 1.75, 2, 1.5, 1
      ],
      piano: [
        3, 3, 3, 2.5, 2, 1, 0, 1.25, 2.5, 2.75, 3, 2, 1, 2.25, 3.5, 3.75, 4,
        3.5, 3, 3.75, 3.5
      ],
      pop: [
        -2, -2, -2, -1.5, -1, -0.5, 0, 1, 2, 3, 4, 4, 4, 3, 2, 1, 0, -0.5, -1,
        -1.5, -2
      ],
      rnb: [
        3, 3, 3, 5, 7, 6.5, 6, 4, 2, -0.5, -3, -2.5, -2, 0, 2, 2.5, 3, 3, 3,
        3.5, 4
      ],
      rock: [
        5, 5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 0.5, -1, -1, -1, 0, 1, 2, 3, 3.5, 4,
        4.5, 5
      ],
      smallSpeakers: [
        5, 5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0.5, 0, -0.5, -1, -1.5, -2,
        -2.5, -3, -3.5, -4
      ],
      spokenWord: [
        -4, -4, -4, -2.5, -1, -0.5, 0, 0.5, 1, 2.4, 4, 4.5, 5, 5, 5, 4.5, 4, 3,
        2, 1, 0
      ],
      trebleBooster: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5
      ],
      trebleReducer: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -0.5, -1, -1.5, -2, -2.5, -3, -3.5, -4,
        -4.5, -5
      ],
      vocalBooster: [
        -2, -2, -2, -2.5, -3, -3, -3, -1, 1, 2.5, 4, 4, 4, 3.5, 3, 2, 1, 0.5, 0,
        -1, -2
      ]
      // headphones: [2.88, 2.88, 6.72, 3.36, -1.92, -1.44, 0.96, 2.88, 5.76, 7.68, 8.64],
      // perfect: [3, 3, 6, 9, 7, 6, 5, 7, 9, 11, 8],
      // rocker: [0, 0, 0, 0, 7, 3, 8, 5, 9, 11, 8],
    }

    for (var key in eqPreset21) {
      if (eqPreset21[key].length != 21) {
        console.error('eqPreset21[key].length != 21')
        console.log(key)
      }
      this.presets.push({
        pluginId: this.getPluginId(),
        presetId: key + '21',
        type: 'system',
        name: this.makePresetLabel(key),
        discriminator: '21',
        parameters: [
          {
            key: 'frequencies',
            value: EqBiquadPluginParametersService.DEFAULT_FREQUENCIES_21
          },
          {
            key: 'q',
            value: EqBiquadPluginParametersService.DEFAULT_QS_21
          },
          {
            key: 'gain',
            value: eqPreset21[key]
          }
        ]
      })
    }
  }

  private initializePresets11() {
    let eqPreset11 = {
      acoustic: [
        5 * 1,
        5 * 1,
        4.5 * 1,
        4 * 1,
        1 * 1,
        2 * 1,
        2 * 1,
        3.5 * 1,
        4 * 1,
        3.5 * 1,
        2 * 1
      ],
      bassBooster: [
        5 * 1,
        5 * 1,
        4 * 1,
        3 * 1,
        2 * 1,
        1 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1
      ],
      bassReducer: [
        -5 * 1,
        -5 * 1,
        -4 * 1,
        -3 * 1,
        -2 * 1,
        1 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1
      ],
      classical: [
        4.5 * 1,
        4.5 * 1,
        4 * 1,
        3 * 1,
        2 * 1,
        -1 * 1,
        -1 * 1,
        0 * 1,
        2 * 1,
        3 * 1,
        4 * 1
      ],
      dance: [
        4 * 1,
        4 * 1,
        7 * 1,
        5 * 1,
        0 * 1,
        2 * 1,
        3 * 1,
        5 * 1,
        4 * 1,
        3 * 1,
        0 * 1
      ],
      deep: [
        5 * 1,
        5 * 1,
        3 * 1,
        2 * 1,
        1 * 1,
        3 * 1,
        2 * 1,
        1 * 1,
        -2 * 1,
        -4 * 1,
        -5 * 1
      ],
      electronic: [
        4.5 * 1,
        4.5 * 1,
        3.5 * 1,
        1 * 1,
        0 * 1,
        -2 * 1,
        2 * 1,
        0.5 * 1,
        1 * 1,
        4 * 1,
        5 * 1
      ],
      flat: [
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1
      ],
      hipHop: [
        5 * 1,
        5 * 1,
        4 * 1,
        1 * 1,
        3 * 1,
        -1 * 1,
        -1 * 1,
        1 * 1,
        -1 * 1,
        2 * 1,
        3 * 1
      ],
      jazz: [
        4 * 1,
        4 * 1,
        3 * 1,
        1 * 1,
        2 * 1,
        -2 * 1,
        -2 * 1,
        0 * 1,
        1 * 1,
        3 * 1,
        4 * 1
      ],
      latin: [
        4.5 * 1,
        4.5 * 1,
        3 * 1,
        0 * 1,
        0 * 1,
        -1.5 * 1,
        -1.5 * 1,
        -1.5 * 1,
        0 * 1,
        3 * 1,
        4.5 * 1
      ],
      loudness: [
        6 * 1,
        6 * 1,
        4 * 1,
        0 * 1,
        0 * 1,
        -2 * 1,
        0 * 1,
        -1 * 1,
        -5 * 1,
        5 * 1,
        1 * 1
      ],
      lounge: [
        -3 * 1,
        -3 * 1,
        -1.5 * 1,
        -0.5 * 1,
        1.5 * 1,
        4 * 1,
        2 * 1,
        0 * 1,
        -1.5 * 1,
        2 * 1,
        1 * 1
      ],
      piano: [
        3 * 1,
        3 * 1,
        2 * 1,
        0 * 1,
        2.5 * 1,
        3 * 1,
        1 * 1,
        3.5 * 1,
        4 * 1,
        3 * 1,
        3.5 * 1
      ],
      pop: [
        -2 * 1,
        -2 * 1,
        -1 * 1,
        0 * 1,
        2 * 1,
        4 * 1,
        4 * 1,
        2 * 1,
        0 * 1,
        -1 * 1,
        -2 * 1
      ],
      rnb: [
        3 * 1,
        3 * 1,
        7 * 1,
        6 * 1,
        2 * 1,
        -3 * 1,
        -2 * 1,
        2 * 1,
        3 * 1,
        3 * 1,
        4 * 1
      ],
      rock: [
        5 * 1,
        5 * 1,
        4 * 1,
        3 * 1,
        2 * 1,
        -1 * 1,
        -1 * 1,
        1 * 1,
        3 * 1,
        4 * 1,
        5 * 1
      ],
      smallSpeakers: [
        5 * 1,
        5 * 1,
        4 * 1,
        3 * 1,
        2 * 1,
        1 * 1,
        0 * 1,
        -1 * 1,
        -2 * 1,
        -3 * 1,
        -4 * 1
      ],
      spokenWord: [
        -4 * 1,
        -4 * 1,
        -1 * 1,
        0 * 1,
        1 * 1,
        4 * 1,
        5 * 1,
        5 * 1,
        4 * 1,
        2 * 1,
        0 * 1
      ],
      trebleBooster: [
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        1 * 1,
        2 * 1,
        3 * 1,
        4 * 1,
        5 * 1
      ],
      trebleReducer: [
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        0 * 1,
        -1 * 1,
        -2 * 1,
        -3 * 1,
        -4 * 1,
        -5 * 1
      ],
      vocalBooster: [
        -2 * 1,
        -2 * 1,
        -3 * 1,
        -3 * 1,
        1 * 1,
        4 * 1,
        4 * 1,
        3 * 1,
        1 * 1,
        0 * 1,
        -2 * 1
      ]
      // headphones: [2.88 * 1, 2.88 * 1, 6.72 * 1, 3.36 * 1, -1.92 * 1, -1.44 * 1, 0.96 * 1, 2.88 * 1, 5.76 * 1, 7.68 * 1, 8.64 * 1],
      // perfect: [3 * 1, 3 * 1, 6 * 1, 9 * 1, 7 * 1, 6 * 1, 5 * 1, 7 * 1, 9 * 1, 11 * 1, 8 * 1],
      // rocker: [0 * 1, 0 * 1, 0 * 1, 0 * 1, 7 * 1, 3 * 1, 8 * 1, 5 * 1, 9 * 1, 11 * 1, 8 * 1],
    }

    for (var key in eqPreset11) {
      if (eqPreset11[key].length != 11) {
        console.error('eqPreset11[key].length != 11')
      }
      this.presets.push({
        pluginId: this.getPluginId(),
        presetId: key + '11',
        type: 'system',
        name: this.makePresetLabel(key),
        discriminator: '11',
        parameters: [
          {
            key: 'frequencies',
            value: EqBiquadPluginParametersService.DEFAULT_FREQUENCIES_11
          },
          {
            key: 'q',
            value: EqBiquadPluginParametersService.DEFAULT_QS_11
          },
          {
            key: 'gain',
            value: eqPreset11[key]
          }
        ]
      })
    }
  }

  private makePresetLabel(presetName: string): string {
    let str = presetName
    let result = str.replace(/([A-Z])/g, ' $1')
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1)

    return finalResult
  }
}
