import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'
import { ReverbMethod } from './ReverbMethod'

export class ReverbPluginParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static METHOD: string = 'method'

  private static DEFAULT_METHOD: string = ReverbMethod.SIMULATION

  constructor() {
    super()

    this.parameters.push({
      key: ReverbPluginParametersService.METHOD,
      value: ReverbPluginParametersService.DEFAULT_METHOD
    })
  }

  public getPluginId(): any {
    return 'REVERB'
  }

  public getPresetDiscriminator(): string {
    return null
  }
}
