// interface Map<K, V> {
//     clear(): void;
//     delete(key: K): boolean;
//     forEach(callbackfn: (value: V, index: K, map: Map<K, V>) => void, thisArg?: any): void;
//     get(key: K): V;
//     has(key: K): boolean;
//     set(key: K, value: V): Map<K, V>;
//     size: number;
// }
//
// declare var Map: {
//     new <K, V>(): Map<K, V>;
//     prototype: Map<any, any>;
// };
//
// interface Set<T> {
//     add(value: T): Set<T>;
//     clear(): void;
//     delete(value: T): boolean;
//     forEach(callbackfn: (value: T, index: T, set: Set<T>) => void, thisArg?: any): void;
//     has(value: T): boolean;
//     size: number;
// }
//
// declare var Set: {
//     new <T>(): Set<T>;
//     prototype: Set<any>;
// };

import { HrtfRecord } from './HrtfRecord'
import { Resampler } from './Resampler'

/*
 * Defines an HRTF database, composed of HRTF Records;
 */
export class HrtfDatabase {
  public hrtfRecordArray: HrtfRecord[]
  public resampledNumberOfTaps: number

  public name: string
  public version: number
  public description: string
  public gain: number
  public filterType: string
  public measuredChannels: string
  public numberOfChannels: number
  public sampleRate: number
  public numberOfTaps: number
  public dataType: string
  public numberOfDirections: number
  public mapArray: Array<number>
  public mapArrayClosest: Array<number>
  public ir: Float32Array
  public shortIr: Int16Array

  // public myMapToHrtfRecordArray = new Map();

  constructor() {
    this.hrtfRecordArray = []
    this.resampledNumberOfTaps = this.numberOfTaps
  }

  /*
   *
   * @param anHrtfRecord
   */
  addHrtfRecord(anHrtfRecord: HrtfRecord) {
    this.hrtfRecordArray.push(anHrtfRecord)
  }

  /*
   * getHrtfRecord
   *
   * @param azimuth
   * @param elevation
   * @returns {HrtfRecord}
   */
  getHrtfRecord(
    audioContext: AudioContext,
    azimuth: number,
    elevation: number
  ): HrtfRecord {
    // console.log('getHrtfRecord');

    azimuth = parseInt(azimuth.toFixed(0))
    elevation = parseInt(elevation.toFixed(0))

    var hrtfRecord: HrtfRecord = null

    // TODO replace with my indices.
    // for (var i = 0; i < this.hrtfRecordArray.length; i++) {
    //     if (Math.abs(this.hrtfRecordArray[i].azimuth - azimuth) < 2
    //         && this.hrtfRecordArray[i].elevation === elevation) {
    //         // console.log('index');
    //         // console.log(i);
    //         hrtfRecord = this.hrtfRecordArray[i];
    //         return hrtfRecord;
    //     }
    // }
    // if(hrtfRecord === null) {
    //     alert('SEVERE! Cannot getHrtfRecord in HrtfDatabase for azimuth: ' + azimuth + ', elevation: ' + elevation);
    // }

    if (this.measuredChannels === 'LEFT') {
      hrtfRecord = this.getHrtfForLeftMeasured(
        this.mapArrayClosest,
        azimuth,
        elevation,
        this.ir,
        this.numberOfTaps
      )
    } else if (this.measuredChannels === 'RIGHT') {
      hrtfRecord = this.getHrtfForRightMeasured(
        this.mapArrayClosest,
        azimuth,
        elevation,
        this.ir,
        this.numberOfTaps
      )
    } else if (this.measuredChannels === 'BOTH') {
      hrtfRecord = this.getHrtfForBothMeasured(
        this.mapArrayClosest,
        azimuth,
        elevation,
        this.ir,
        this.numberOfTaps
      )
    } else {
      console.error('Unknown this.measuredChannels: ' + this.measuredChannels)
    }

    this.createAudioBuffersForHrtfRecord(audioContext, hrtfRecord)
    // console.log(hrtfRecord);
    return hrtfRecord
  }

  /*
   *
   * @returns {Array}
   */
  getAvailableAzimuthElevations() {
    var availableAzimuthElevations = []

    for (var i = 0; i < this.hrtfRecordArray.length; i++) {
      var azimuth = this.hrtfRecordArray[i].azimuth
      var elevation = this.hrtfRecordArray[i].elevation

      availableAzimuthElevations.push({
        id: azimuth + ',' + elevation,
        name: 'Azimuth: ' + azimuth + ', Elevation: ' + elevation
      })
    }

    return availableAzimuthElevations
  }

  /*
   *
   * @param audioContext
   */
  createAudioBuffers(audioContext: AudioContext) {
    var playbackSampleRate: number = audioContext.sampleRate

    for (var i = 0; i < this.hrtfRecordArray.length; i++) {
      var hrtfRecord: HrtfRecord = this.hrtfRecordArray[i]
      var buffer: AudioBuffer

      if (this.sampleRate !== playbackSampleRate) {
        var tempBuffer: AudioBuffer = audioContext.createBuffer(
          2,
          this.numberOfTaps,
          this.sampleRate
        )

        var tempBufferChannelLeft = tempBuffer.getChannelData(0)
        var tempBufferChannelRight = tempBuffer.getChannelData(1)
        for (var e = 0; e < hrtfRecord.fir_coeffs_left.length; e++) {
          tempBufferChannelLeft[e] = hrtfRecord.fir_coeffs_left[e]
          tempBufferChannelRight[e] = hrtfRecord.fir_coeffs_right[e]
        }

        var resamplerLeft = new Resampler(
          this.sampleRate,
          playbackSampleRate,
          1,
          tempBuffer.getChannelData(0)
        )
        var resamplerRight = new Resampler(
          this.sampleRate,
          playbackSampleRate,
          1,
          tempBuffer.getChannelData(1)
        )

        var resampledLeft = resamplerLeft.resamplerFunc(this.numberOfTaps)
        // NOT USED var resampledRight = resamplerRight.resamplerFunc(this.numberOfTaps)

        // Set Resampled Values
        this.resampledNumberOfTaps = resampledLeft

        buffer = audioContext.createBuffer(
          2,
          this.resampledNumberOfTaps,
          playbackSampleRate
        )

        var bufferChannelLeft = buffer.getChannelData(0)
        var bufferChannelRight = buffer.getChannelData(1)
        for (var e = 0; e < resampledLeft; e++) {
          bufferChannelLeft[e] = resamplerLeft.outputBuffer[e]
          bufferChannelRight[e] = resamplerRight.outputBuffer[e]
        }
      } else {
        buffer = audioContext.createBuffer(
          2,
          this.numberOfTaps,
          playbackSampleRate
        )

        var bufferChannelLeft = buffer.getChannelData(0)
        var bufferChannelRight = buffer.getChannelData(1)
        for (var e = 0; e < hrtfRecord.fir_coeffs_left.length; e++) {
          bufferChannelLeft[e] = hrtfRecord.fir_coeffs_left[e]
          bufferChannelRight[e] = hrtfRecord.fir_coeffs_right[e]
        }
      }

      // Set buffer in HRTF Record
      hrtfRecord.buffer = buffer
    }

    if (this.sampleRate !== playbackSampleRate) {
      // console.log("New number of Taps due to re-sampling: " + this.resampledNumberOfTaps);
    }
  }

  getHrtfForLeftMeasured(
    mapArray: any,
    Azimuth: any,
    Elevation: any,
    ir: any,
    numberOfTaps: any
  ): HrtfRecord {
    var index1 = mapArray[Azimuth * 181 + (Elevation + 90)]
    var index2 = mapArray[(359 - Azimuth) * 181 + (Elevation + 90)]

    // console.log('getHrtfForLeftMeasured, ' + index1 + ' - ' + index2 + ', for Az,El ' + Azimuth, +', ' + Elevation);

    var l = this.getFilter(
      ir,
      index1 * numberOfTaps,
      index1 * numberOfTaps + numberOfTaps
    )
    var r = this.getFilter(
      ir,
      index2 * numberOfTaps,
      index2 * numberOfTaps + numberOfTaps
    )

    return new HrtfRecord(Azimuth, Elevation, 1, l, r)
  }

  getHrtfForRightMeasured(
    mapArray: any,
    Azimuth: any,
    Elevation: any,
    ir: any,
    numberOfTaps: any
  ): HrtfRecord {
    var index1 = mapArray[Azimuth * 181 + (Elevation + 90)]
    var index2 = mapArray[(359 - Azimuth) * 181 + (Elevation + 90)]

    // console.log('getHrtfForLeftMeasured, ' + index1 + ' - ' + index2 + ', for Az,El ' + Azimuth, +', ' + Elevation);

    // Inverse l - r compared to getHrtfForLeftMeasured
    var r = this.getFilter(
      ir,
      index1 * numberOfTaps,
      index1 * numberOfTaps + numberOfTaps
    )
    var l = this.getFilter(
      ir,
      index2 * numberOfTaps,
      index2 * numberOfTaps + numberOfTaps
    )

    return new HrtfRecord(Azimuth, Elevation, 1, l, r)
  }

  getHrtfForBothMeasured(
    mapArray: any,
    Azimuth: any,
    Elevation: any,
    ir: any,
    numberOfTaps: any
  ): HrtfRecord {
    var switchIrs: boolean = false
    var azimuthOriginal: number = Azimuth
    if (Azimuth > 180) {
      switchIrs = true
      Azimuth = 360 - Azimuth
    }

    // console.log(mapArray.length);
    // console.log('(Azimuth * 181) + (Elevation + 90): ' + (Azimuth * 181) + (Elevation + 90));
    var index = mapArray[Azimuth * 181 + (Elevation + 90)]

    // console.log('getHrtfForBothMeasured, Azimuth: ' + Azimuth + ', Elevation: ' + Elevation + ', numberOfTaps: ' + numberOfTaps + ', index: ' + index + ', switchIrs: ' + switchIrs);

    var l = this.getFilter(
      ir,
      2 * index * numberOfTaps,
      2 * index * numberOfTaps + numberOfTaps
    )
    var r = this.getFilter(
      ir,
      (2 * index + 1) * numberOfTaps,
      (2 * index + 1) * numberOfTaps + numberOfTaps
    )

    if (switchIrs) {
      return new HrtfRecord(azimuthOriginal, Elevation, 1, r, l)
    } else {
      return new HrtfRecord(Azimuth, Elevation, 1, l, r)
    }
  }

  getFilter(ir: any, startIndex: number, endIndex: number) {
    var filter = new Array(endIndex - startIndex)
    var counter = 0
    for (var i = startIndex; i < endIndex; i++) {
      filter[counter] = ir[i]
      counter++
    }
    return filter
  }

  createAudioBuffersForHrtfRecord(
    audioContext: AudioContext,
    hrtfRecord: HrtfRecord
  ) {
    var playbackSampleRate: number = audioContext.sampleRate

    var buffer: AudioBuffer

    if (this.sampleRate !== playbackSampleRate) {
      var tempBuffer: AudioBuffer = audioContext.createBuffer(
        2,
        this.numberOfTaps,
        this.sampleRate
      )

      var tempBufferChannelLeft = tempBuffer.getChannelData(0)
      var tempBufferChannelRight = tempBuffer.getChannelData(1)
      for (var e = 0; e < hrtfRecord.fir_coeffs_left.length; e++) {
        tempBufferChannelLeft[e] = hrtfRecord.fir_coeffs_left[e]
        tempBufferChannelRight[e] = hrtfRecord.fir_coeffs_right[e]
      }

      var resamplerLeft = new Resampler(
        this.sampleRate,
        playbackSampleRate,
        1,
        tempBuffer.getChannelData(0)
      )
      var resamplerRight = new Resampler(
        this.sampleRate,
        playbackSampleRate,
        1,
        tempBuffer.getChannelData(1)
      )

      var resampledLeft = resamplerLeft.resamplerFunc(this.numberOfTaps)
      // NOT USED var resampledRight = resamplerRight.resamplerFunc(this.numberOfTaps)

      // Set Resampled Values
      this.resampledNumberOfTaps = resampledLeft

      buffer = audioContext.createBuffer(
        2,
        this.resampledNumberOfTaps,
        playbackSampleRate
      )

      var bufferChannelLeft = buffer.getChannelData(0)
      var bufferChannelRight = buffer.getChannelData(1)
      for (var e = 0; e < resampledLeft; e++) {
        bufferChannelLeft[e] = resamplerLeft.outputBuffer[e]
        bufferChannelRight[e] = resamplerRight.outputBuffer[e]
      }
    } else {
      buffer = audioContext.createBuffer(
        2,
        this.numberOfTaps,
        playbackSampleRate
      )

      var bufferChannelLeft = buffer.getChannelData(0)
      var bufferChannelRight = buffer.getChannelData(1)
      for (var e = 0; e < hrtfRecord.fir_coeffs_left.length; e++) {
        bufferChannelLeft[e] = hrtfRecord.fir_coeffs_left[e]
        bufferChannelRight[e] = hrtfRecord.fir_coeffs_right[e]
      }
    }

    // Set buffer in HRTF Record
    hrtfRecord.buffer = buffer

    if (this.sampleRate !== playbackSampleRate) {
      // console.log("New number of Taps due to re-sampling: " + this.resampledNumberOfTaps);
    }
  }
}
