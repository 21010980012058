/*
 *
 */
export class VirtualStereoFilters {
  private binauralImpulseResponseForLeftVirtualSource: AudioBuffer
  private binauralImpulseResponseForRightVirtualSource: AudioBuffer

  constructor(
    binauralImpulseResponseForLeftVirtualSource: AudioBuffer,
    binauralImpulseResponseForRightVirtualSource: AudioBuffer
  ) {
    this.binauralImpulseResponseForLeftVirtualSource =
      binauralImpulseResponseForLeftVirtualSource
    this.binauralImpulseResponseForRightVirtualSource =
      binauralImpulseResponseForRightVirtualSource
  }

  /*
   * getBinauralImpulseResponseForLeftVirtualSource
   *
   * @returns {AudioBuffer}
   */
  public getBinauralImpulseResponseForLeftVirtualSource(): AudioBuffer {
    return this.binauralImpulseResponseForLeftVirtualSource
  }

  /*
   * getBinauralImpulseResponseForRightVirtualSource
   *
   * @returns {AudioBuffer}
   */
  public getBinauralImpulseResponseForRightVirtualSource(): AudioBuffer {
    return this.binauralImpulseResponseForRightVirtualSource
  }
}
