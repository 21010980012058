import { IPluginService } from '../../audio-core/plugin/IPluginService'
import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { PluginInstance } from '../../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { EqBiquadPluginParametersService } from '../../audio-plugins-parameters/eq-biquad/eq-biquad-plugin-parameters.service'
import { EqBiquadAudioNode } from './eq-biquad-audio-node'

export class EqBiquadPluginService implements IPluginService {
  constructor(
    private eqBiquadPluginParametersService: EqBiquadPluginParametersService
  ) {}

  createAudioNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<IAudioNode> {
    return new Promise((resolve, reject) => {
      pluginInstance =
        this.eqBiquadPluginParametersService.ensurePluginInstanceParameterKeyValues(
          pluginInstance
        )

      var audioNode: EqBiquadAudioNode = new EqBiquadAudioNode(
        audioContext,
        this.eqBiquadPluginParametersService.getParameterKeyValues(
          pluginInstance
        )
      )

      resolve(audioNode)
    })
  }

  setParameterValue(
    audioContext: AudioContext,
    audioNode: IAudioNode,
    parameterKeyValue: ParameterKeyValue,
    browser: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      audioNode.setParameter(parameterKeyValue)

      // TODO
      switch (parameterKeyValue.key) {
        case EqBiquadPluginParametersService.GAIN_RATIOS_3:
          for (
            var i: number = 0;
            i <
            audioNode.getParameter(
              EqBiquadPluginParametersService.NUMBER_OF_BANDS
            ).value;
            i++
          ) {
            ;(<EqBiquadAudioNode>audioNode).biquadFilterNodeList[
              i
            ].gain.setTargetAtTime(
              audioNode.getParameter(
                EqBiquadPluginParametersService.GAIN_RATIOS_3
              ).value[i],
              audioContext.currentTime,
              0.015
            )
          }
          resolve()
          break
        case EqBiquadPluginParametersService.GAIN_RATIOS_11:
          for (
            var i: number = 0;
            i <
            audioNode.getParameter(
              EqBiquadPluginParametersService.NUMBER_OF_BANDS
            ).value;
            i++
          ) {
            ;(<EqBiquadAudioNode>audioNode).biquadFilterNodeList[
              i
            ].gain.setTargetAtTime(
              audioNode.getParameter(
                EqBiquadPluginParametersService.GAIN_RATIOS_11
              ).value[i],
              audioContext.currentTime,
              0.015
            )
          }
          resolve()
          break
        case EqBiquadPluginParametersService.GAIN_RATIOS_21:
          for (
            var i: number = 0;
            i <
            audioNode.getParameter(
              EqBiquadPluginParametersService.NUMBER_OF_BANDS
            ).value;
            i++
          ) {
            ;(<EqBiquadAudioNode>audioNode).biquadFilterNodeList[
              i
            ].gain.setTargetAtTime(
              audioNode.getParameter(
                EqBiquadPluginParametersService.GAIN_RATIOS_21
              ).value[i],
              audioContext.currentTime,
              0.015
            )
          }
          resolve()
          break
        case EqBiquadPluginParametersService.QS_3:
          for (
            var i: number = 0;
            i <
            audioNode.getParameter(
              EqBiquadPluginParametersService.NUMBER_OF_BANDS
            ).value;
            i++
          ) {
            ;(<EqBiquadAudioNode>audioNode).biquadFilterNodeList[
              i
            ].Q.setTargetAtTime(
              audioNode.getParameter(EqBiquadPluginParametersService.QS_3)
                .value[i],
              audioContext.currentTime,
              0.015
            )
          }
          resolve()
          break
        case EqBiquadPluginParametersService.QS_11:
          for (
            var i: number = 0;
            i <
            audioNode.getParameter(
              EqBiquadPluginParametersService.NUMBER_OF_BANDS
            ).value;
            i++
          ) {
            ;(<EqBiquadAudioNode>audioNode).biquadFilterNodeList[
              i
            ].Q.setTargetAtTime(
              audioNode.getParameter(EqBiquadPluginParametersService.QS_11)
                .value[i],
              audioContext.currentTime,
              0.015
            )
          }
          resolve()
          break
        case EqBiquadPluginParametersService.QS_21:
          for (
            var i: number = 0;
            i <
            audioNode.getParameter(
              EqBiquadPluginParametersService.NUMBER_OF_BANDS
            ).value;
            i++
          ) {
            ;(<EqBiquadAudioNode>audioNode).biquadFilterNodeList[
              i
            ].Q.setTargetAtTime(
              audioNode.getParameter(EqBiquadPluginParametersService.QS_21)
                .value[i],
              audioContext.currentTime,
              0.015
            )
          }
          resolve()
          break
        case EqBiquadPluginParametersService.NUMBER_OF_BANDS:
          ;(<EqBiquadAudioNode>audioNode)
            .applyNewFilters()
            .then((result: any) => {
              resolve()
            })
          break
        case EqBiquadPluginParametersService.PREAMP_GAIN:
          ;(<EqBiquadAudioNode>audioNode).applyPreampGain()
          break
        default:
          console.error(
            'No available parameter with name: ' + parameterKeyValue.key
          )
          reject()
      }
    })
  }
}
