import {
  PluginInstance,
  AddNewPluginToPresetRequest,
  PluginInstanceParameterKeyValue,
  Preset
} from '../..'
import ArccPluginParametersService from '../audio-plugins-parameters/arcc/arcc-plugin-parameters.service'
import AreiPluginParametersService from '../audio-plugins-parameters/arei/arei-plugin-parameters.service'
import { CapturePluginParametersService } from '../audio-plugins-parameters/capture/capture-plugin-parameters.service'
import { ConvolverPluginParametersService } from '../audio-plugins-parameters/convolver/convolver-plugin-parameters.service'
import { DynamicsCompressorPluginParametersService } from '../audio-plugins-parameters/dynamics-compressor/dynamics-compressor-plugin-parameters.service'
import { EqBiquadPluginParametersService } from '../audio-plugins-parameters/eq-biquad/eq-biquad-plugin-parameters.service'
import { PluginGainParametersService } from '../audio-plugins-parameters/gain/plugin-gain-parameters.service'
import { PluginRaceParametersService } from '../audio-plugins-parameters/race/plugin-race-parameters.service'
import { ReverbPluginParametersService } from '../audio-plugins-parameters/reverb/reverb-plugin-parameters.service'
import { StereoWideningPluginParametersService } from '../audio-plugins-parameters/stereo-widening/stereo-widening-plugin-parameters.service'

export default class PluginGraphUiService {
  public static preOrder: number = 1000
  public static coreOrder: number = 2000
  public static postOrder: number = 3000

  constructor(
    private arccPluginParametersService: ArccPluginParametersService,
    private areiPluginParametersService: AreiPluginParametersService,
    private capturePluginParametersService: CapturePluginParametersService,
    private dynamicsCompressorPluginParametersService: DynamicsCompressorPluginParametersService,
    private eqBiquadPluginParametersService: EqBiquadPluginParametersService,
    private pluginGainParametersService: PluginGainParametersService,
    private pluginRaceParametersService: PluginRaceParametersService,
    private convolverPluginParametersService: ConvolverPluginParametersService,
    private reverbPluginParametersService: ReverbPluginParametersService,
    private stereoWideningPluginParametersService: StereoWideningPluginParametersService
  ) {
    this.initializeInternalOrderValues()
  }

  initializeInternalOrderValues() {
    // console.log('audio-foreground, initializeInternalOrderValues');
    if (typeof window === 'undefined') {
      return
    }

    const item = localStorage.getItem('activePreset')

    let obj = null
    if (item) {
      obj = JSON.parse(item)
      if (obj.pluginInstances) {
        const res: Array<PluginInstance> = obj.pluginInstances
        // console.log(res);

        for (let i = 0; i < res.length; i++) {
          const pluginInstance: PluginInstance = res[i]
          if (pluginInstance.order >= 2000 && pluginInstance.order < 3000) {
            if (pluginInstance.order >= PluginGraphUiService.coreOrder) {
              PluginGraphUiService.coreOrder = pluginInstance.order
              // console.log('audio-foreground, initializeInternalOrderValues, new PluginGraphUiService.coreOrder');
              // console.log(PluginGraphUiService.coreOrder);
            }
          }
        }
      }
    }
  }

  resetOrder() {
    // console.log(`resetOrder`)
    PluginGraphUiService.preOrder = 1000
    PluginGraphUiService.coreOrder = 2000
    PluginGraphUiService.postOrder = 3000
  }

  createPluginInstance(request: AddNewPluginToPresetRequest): PluginInstance {
    const pluginInstance: PluginInstance =
      this.createPluginInstanceDirectly(request)
    return pluginInstance
  }

  createPluginInstanceDirectly(
    request: AddNewPluginToPresetRequest
  ): PluginInstance {
    const instanceId = this.getInstanceId(request)
    let parameters: Array<PluginInstanceParameterKeyValue> = []
    let presets: Array<Preset> = []
    let presetDiscriminator: string = ''

    switch (request.pluginId) {
      case 'ARCC':
        parameters =
          this.arccPluginParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.arccPluginParametersService.getPresets()
        presetDiscriminator =
          this.arccPluginParametersService.getPresetDiscriminator()
        break
      case 'AREI':
        parameters =
          this.areiPluginParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.areiPluginParametersService.getPresets()
        presetDiscriminator =
          this.areiPluginParametersService.getPresetDiscriminator()
        break
      case 'CAPTURE':
        parameters =
          this.capturePluginParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.capturePluginParametersService.getPresets()
        presetDiscriminator =
          this.capturePluginParametersService.getPresetDiscriminator()
        break
      case 'DYNAMICS_COMPRESSOR':
        parameters =
          this.dynamicsCompressorPluginParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.dynamicsCompressorPluginParametersService.getPresets()
        presetDiscriminator =
          this.dynamicsCompressorPluginParametersService.getPresetDiscriminator()
        break
      case 'EQ_BIQUAD':
        parameters =
          this.eqBiquadPluginParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.eqBiquadPluginParametersService.getPresets()
        presetDiscriminator =
          this.eqBiquadPluginParametersService.getPresetDiscriminator()
        break
      case 'SIMPLE_GAIN':
        parameters =
          this.pluginGainParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.pluginGainParametersService.getPresets()
        presetDiscriminator =
          this.pluginGainParametersService.getPresetDiscriminator()
        break
      case 'RACE_TWO_CHANNELS':
        parameters =
          this.pluginRaceParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.pluginRaceParametersService.getPresets()
        presetDiscriminator =
          this.pluginRaceParametersService.getPresetDiscriminator()
        break
      case 'CONVOLVER':
        parameters =
          this.convolverPluginParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.convolverPluginParametersService.getPresets()
        presetDiscriminator =
          this.convolverPluginParametersService.getPresetDiscriminator()
        break
      case 'REVERB':
        parameters =
          this.reverbPluginParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.reverbPluginParametersService.getPresets()
        presetDiscriminator =
          this.reverbPluginParametersService.getPresetDiscriminator()
        break
      case 'STEREO_WIDENING':
        parameters =
          this.stereoWideningPluginParametersService.getPluginInstanceParameterKeyValues(
            request.pluginId,
            instanceId
          )
        presets = this.stereoWideningPluginParametersService.getPresets()
        presetDiscriminator =
          this.stereoWideningPluginParametersService.getPresetDiscriminator()
        break
      default:
        console.error('No creator defined for: ' + request.pluginId)
        break
    }

    return {
      pluginId: request.pluginId,
      instanceId: instanceId,
      order: this.getNextOrder(request),
      name: request.name,
      enabled: this.getEnabled(request),
      description: request.description,
      color: request.color,
      icon: request.icon,
      parameters: parameters,
      presets: presets,
      presetDiscriminator: presetDiscriminator
    }
  }

  getNextOrder(request: AddNewPluginToPresetRequest): number {
    // TODO
    if (request.stage) {
      if (request.stage === 'pre') {
        PluginGraphUiService.preOrder = PluginGraphUiService.preOrder + 1
        return PluginGraphUiService.preOrder
      } else if (request.stage === 'post') {
        PluginGraphUiService.postOrder = PluginGraphUiService.postOrder + 1
        return PluginGraphUiService.postOrder
      } else {
        PluginGraphUiService.coreOrder = PluginGraphUiService.coreOrder + 1
        return PluginGraphUiService.coreOrder
      }
    } else {
      PluginGraphUiService.coreOrder = PluginGraphUiService.coreOrder + 1
      return PluginGraphUiService.coreOrder
    }
  }

  getEnabled(request: AddNewPluginToPresetRequest) {
    if (request.enabled !== undefined && request.enabled !== null) {
      return request.enabled
    } else {
      return true
    }
  }

  getInstanceId(request: AddNewPluginToPresetRequest) {
    if (request.instanceId) {
      return request.instanceId
    } else {
      return this.getRandomInstanceId()
    }
  }

  getRandomInstanceId(): string {
    // TODO
    return Math.random().toString(36).substring(7)
  }
}
