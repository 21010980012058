export class XtcFilters {
  public buffer_ll_rr: AudioBuffer
  public buffer_lr_rl: AudioBuffer

  public buffer_ll_rl: AudioBuffer
  public buffer_lr_rr: AudioBuffer

  public buffer_c_l_l: AudioBuffer
  public buffer_c_r_l: AudioBuffer
  public buffer_c_l_r: AudioBuffer
  public buffer_c_r_r: AudioBuffer

  public buffer_c_l_l_stereo: AudioBuffer
  public buffer_c_r_l_stereo: AudioBuffer
  public buffer_c_l_r_stereo: AudioBuffer
  public buffer_c_r_r_stereo: AudioBuffer

  constructor(
    public c_l_l: Array<number>,
    public c_r_l: Array<number>,
    public c_l_r: Array<number>,
    public c_r_r: Array<number>
  ) {
    //this.c_r_l = this.c_l_r;
    //this.c_r_r = this.c_l_l;
    // var max:number = -1;
    //
    // for (var i = 0; i < c_l_l.length; i++) {
    //    if (max < Math.abs(c_l_l[i])) {
    //        max = Math.abs(c_l_l[i]);
    //    }
    //    if (max < Math.abs(c_r_l[i])) {
    //        max = Math.abs(c_r_l[i]);
    //    }
    //    if (max < Math.abs(c_l_r[i])) {
    //        max = Math.abs(c_l_r[i]);
    //    }
    //    if (max < Math.abs(c_r_r[i])) {
    //        max = Math.abs(c_r_r[i]);
    //    }
    //}
    //
    //console.log("max value:" + max);
    //
    //var mul:number = 1;
    //for (var i = 0; i < c_l_l.length; i++) {
    //    c_l_l[i] = (c_l_l[i] / max) * mul;
    //    c_r_l[i] = (c_r_l[i] / max) * mul;
    //    c_l_r[i] = (c_l_r[i] / max) * mul;
    //    c_r_r[i] = (c_r_r[i] / max) * mul;
    //}
  }

  public createAudioBuffers(audioContext: AudioContext) {
    var length = this.c_l_l.length

    this.buffer_c_l_l = audioContext.createBuffer(
      1,
      length,
      audioContext.sampleRate
    )
    this.buffer_c_r_l = audioContext.createBuffer(
      1,
      length,
      audioContext.sampleRate
    )
    this.buffer_c_l_r = audioContext.createBuffer(
      1,
      length,
      audioContext.sampleRate
    )
    this.buffer_c_r_r = audioContext.createBuffer(
      1,
      length,
      audioContext.sampleRate
    )

    this.buffer_c_l_l_stereo = audioContext.createBuffer(
      2,
      length,
      audioContext.sampleRate
    )
    this.buffer_c_r_l_stereo = audioContext.createBuffer(
      2,
      length,
      audioContext.sampleRate
    )
    this.buffer_c_l_r_stereo = audioContext.createBuffer(
      2,
      length,
      audioContext.sampleRate
    )
    this.buffer_c_r_r_stereo = audioContext.createBuffer(
      2,
      length,
      audioContext.sampleRate
    )

    this.buffer_ll_rl = audioContext.createBuffer(
      2,
      length,
      audioContext.sampleRate
    )
    this.buffer_lr_rr = audioContext.createBuffer(
      2,
      length,
      audioContext.sampleRate
    )

    // COMBINE
    this.buffer_ll_rr = audioContext.createBuffer(
      2,
      length,
      audioContext.sampleRate
    )
    var bufferChannelData_LL_RR_Left = this.buffer_ll_rr.getChannelData(0)
    var bufferChannelData_LL_RR_Right = this.buffer_ll_rr.getChannelData(1)

    this.buffer_lr_rl = audioContext.createBuffer(
      2,
      length,
      audioContext.sampleRate
    )
    var bufferChannelData_LR_RL_Left = this.buffer_lr_rl.getChannelData(0)
    var bufferChannelData_LR_RL_Right = this.buffer_lr_rl.getChannelData(1)

    for (var i = 0; i < length; i++) {
      this.buffer_c_l_l[i] = this.c_l_l[i]
      this.buffer_c_r_l[i] = this.c_r_l[i]
      this.buffer_c_l_r[i] = this.c_l_r[i]
      this.buffer_c_r_r[i] = this.c_r_r[i]

      this.buffer_c_l_l_stereo.getChannelData(0)[i] = this.c_l_l[i]
      this.buffer_c_r_l_stereo.getChannelData(0)[i] = this.c_r_l[i]
      this.buffer_c_l_r_stereo.getChannelData(0)[i] = this.c_l_r[i]
      this.buffer_c_r_r_stereo.getChannelData(0)[i] = this.c_r_r[i]

      this.buffer_c_l_l_stereo.getChannelData(1)[i] = this.c_l_l[i]
      this.buffer_c_r_l_stereo.getChannelData(1)[i] = this.c_r_l[i]
      this.buffer_c_l_r_stereo.getChannelData(1)[i] = this.c_l_r[i]
      this.buffer_c_r_r_stereo.getChannelData(1)[i] = this.c_r_r[i]

      // COMBINE
      bufferChannelData_LL_RR_Left[i] = this.c_l_l[i]
      bufferChannelData_LL_RR_Right[i] = this.c_r_r[i]

      bufferChannelData_LR_RL_Left[i] = this.c_l_r[i]
      bufferChannelData_LR_RL_Right[i] = this.c_r_l[i]

      ///////////////////////////// NEW  ///////////////////////////////////////////////
      this.buffer_ll_rl.getChannelData(0)[i] = this.c_l_l[i]
      this.buffer_ll_rl.getChannelData(1)[i] = this.c_r_l[i]

      this.buffer_lr_rr.getChannelData(0)[i] = this.c_l_r[i]
      this.buffer_lr_rr.getChannelData(1)[i] = this.c_r_r[i]
    }
  }
}
