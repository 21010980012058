import { VirtualAmbioFilters } from './VirtualAmbioFilters'
import { VirtualSourceAudioNode } from '../virtual-source/VirtualSourceAudioNode'
import { RaceAudioNode } from '../race/race-audio-node'
import { ParameterKeyValue } from '../../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../../audio-core/web-audio-api/audio-node/IAudioNode'
import AreiPluginParametersService from '../../../audio-plugins-parameters/arei/arei-plugin-parameters.service'

export class VirtualAmbioAudioNode
  extends AbstractAudioNode
  implements IAudioNode
{
  private input: GainNode
  private output: GainNode
  private virtualAmbioFilters: VirtualAmbioFilters

  // FRONT
  private gainFront: GainNode
  private virtualSourceFrontLeft: VirtualSourceAudioNode
  private virtualSourceFrontRight: VirtualSourceAudioNode
  private channelSplitterFront: ChannelSplitterNode
  private raceAudioNodeFront: RaceAudioNode

  // REAR
  private gainRear: GainNode
  private virtualSourceRearLeft: VirtualSourceAudioNode
  private virtualSourceRearRight: VirtualSourceAudioNode
  private channelSplitterRear: ChannelSplitterNode
  private raceAudioNodeRear: RaceAudioNode

  // SIDE
  private gainSide: GainNode
  private virtualSourceSideLeft: VirtualSourceAudioNode
  private virtualSourceSideRight: VirtualSourceAudioNode
  private channelSplitterSide: ChannelSplitterNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>,
    virtualAmbioFilters: VirtualAmbioFilters
  ) {
    super(audioContext, parameters)
    this.virtualAmbioFilters = virtualAmbioFilters

    this.input = this.audioContext.createGain()
    this.output = this.audioContext.createGain()
    this.gainFront = this.audioContext.createGain()
    this.gainRear = this.audioContext.createGain()
    this.gainSide = this.audioContext.createGain()

    this.applyFrontGain()
    this.applySideGain()
    this.applyRearGain()

    // CREATE FRONT
    this.raceAudioNodeFront = new RaceAudioNode(
      this.audioContext,
      null,
      this.virtualAmbioFilters.xtcFilters
    )
    this.virtualSourceFrontLeft = new VirtualSourceAudioNode(
      this.audioContext,
      null,
      this.virtualAmbioFilters.binauralIRFrontLeft
    )
    this.virtualSourceFrontRight = new VirtualSourceAudioNode(
      this.audioContext,
      null,
      this.virtualAmbioFilters.binauralIRFrontRight
    )
    this.channelSplitterFront = this.audioContext.createChannelSplitter(2)

    // CREATE REAR
    this.raceAudioNodeRear = new RaceAudioNode(
      this.audioContext,
      null,
      this.virtualAmbioFilters.xtcFilters
    )
    this.virtualSourceRearLeft = new VirtualSourceAudioNode(
      this.audioContext,
      null,
      this.virtualAmbioFilters.binauralIRRearLeft
    )
    this.virtualSourceRearRight = new VirtualSourceAudioNode(
      this.audioContext,
      null,
      this.virtualAmbioFilters.binauralIRRearRight
    )
    this.channelSplitterRear = this.audioContext.createChannelSplitter(2)

    // CREATE SIDE
    this.virtualSourceSideLeft = new VirtualSourceAudioNode(
      this.audioContext,
      null,
      this.virtualAmbioFilters.binauralIRSideLeft
    )
    this.virtualSourceSideRight = new VirtualSourceAudioNode(
      this.audioContext,
      null,
      this.virtualAmbioFilters.binauralIRSideRight
    )
    this.channelSplitterSide = this.audioContext.createChannelSplitter(2)

    this.wire()
  }

  wire(): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.log('VirtualAmbioAudioNode wire');

      // INPUT CONNECT
      this.input.connect(this.gainFront)
      this.gainFront.connect(this.raceAudioNodeFront.getInput())

      this.input.connect(this.gainRear)
      this.gainRear.connect(this.raceAudioNodeRear.getInput())

      this.input.connect(this.gainSide)
      this.gainSide.connect(this.channelSplitterSide)

      // FRONT
      this.raceAudioNodeFront.getOutput().connect(this.channelSplitterFront)
      this.channelSplitterFront.connect(
        this.virtualSourceFrontLeft.getInput(),
        0
      )
      this.channelSplitterFront.connect(
        this.virtualSourceFrontRight.getInput(),
        1
      )
      this.virtualSourceFrontLeft.connect(this.getOutput())
      this.virtualSourceFrontRight.connect(this.getOutput())

      // REAR
      this.raceAudioNodeRear.getOutput().connect(this.channelSplitterRear)
      this.channelSplitterRear.connect(this.virtualSourceRearLeft.getInput(), 0)
      this.channelSplitterRear.connect(
        this.virtualSourceRearRight.getInput(),
        1
      )
      this.virtualSourceRearLeft.connect(this.output)
      this.virtualSourceRearRight.connect(this.output)

      // SIDE
      this.channelSplitterSide.connect(this.virtualSourceSideLeft.getInput(), 0)
      this.channelSplitterSide.connect(
        this.virtualSourceSideRight.getInput(),
        1
      )
      this.virtualSourceSideLeft.connect(this.output)
      this.virtualSourceSideRight.connect(this.output)

      resolve()
    })
  }

  getInput(): AudioNode {
    return this.input
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }

  applyVirtualAmbioFilters(virtualAmbioFilters: VirtualAmbioFilters) {
    // console.log('applyVirtualAmbioFilters');
    // console.log(virtualAmbioFilters);

    this.virtualAmbioFilters = virtualAmbioFilters
    this.virtualSourceFrontLeft.updateBinauralImpulseResponse(
      this.virtualAmbioFilters.binauralIRFrontLeft
    )
    this.virtualSourceFrontRight.updateBinauralImpulseResponse(
      this.virtualAmbioFilters.binauralIRFrontRight
    )
    this.virtualSourceRearLeft.updateBinauralImpulseResponse(
      this.virtualAmbioFilters.binauralIRRearLeft
    )
    this.virtualSourceRearRight.updateBinauralImpulseResponse(
      this.virtualAmbioFilters.binauralIRRearRight
    )
    this.virtualSourceSideLeft.updateBinauralImpulseResponse(
      this.virtualAmbioFilters.binauralIRSideLeft
    )
    this.virtualSourceSideRight.updateBinauralImpulseResponse(
      this.virtualAmbioFilters.binauralIRSideRight
    )

    this.raceAudioNodeFront.updateXtcFilters(
      this.virtualAmbioFilters.xtcFilters
    )
    this.raceAudioNodeRear.updateXtcFilters(this.virtualAmbioFilters.xtcFilters)

    this.applyFrontGain()
    this.applySideGain()
    this.applyRearGain()
  }

  applyFrontGain() {
    this.gainFront.gain.setTargetAtTime(
      this.getParameter(AreiPluginParametersService.FRONT_GAIN).value,
      this.audioContext.currentTime,
      0.015
    )
  }

  applyRearGain() {
    this.gainRear.gain.setTargetAtTime(
      this.getParameter(AreiPluginParametersService.REAR_GAIN).value,
      this.audioContext.currentTime,
      0.015
    )
  }

  applySideGain() {
    this.gainSide.gain.setTargetAtTime(
      this.getParameter(AreiPluginParametersService.SIDE_GAIN).value,
      this.audioContext.currentTime,
      0.015
    )
  }
}
