import { MySpecialAudioInputNode } from '../web-audio-api/audio-input/MySpecialAudioInputNode'
import { MediaStreamMediaContent } from '../media-content/MediaStreamMediaContent'
import AudioInputNodeFactory from '../web-audio-api/audio-input/AudioInputNodeFactory'
import { BasePlayer } from './base-player'
import PluginInstanceFactoryService from '../../audio-background/plugin-instance-factory.service'

export default class StreamPlayer extends BasePlayer {
  constructor(
    public audioContext: AudioContext,
    public pluginInstanceFactoryService: PluginInstanceFactoryService,
    public audioInputNodeFactory: AudioInputNodeFactory
  ) {
    super(audioContext, pluginInstanceFactoryService, audioInputNodeFactory)
  }

  /*
   * Play Stream.
   *
   * @param index
   * @returns {Promise|Promise<T>}
   */
  public play(
    mediaStreamMediaContent: MediaStreamMediaContent,
    selectedIndex: number
  ): Promise<MySpecialAudioInputNode> {
    this.currentMediaContent = mediaStreamMediaContent

    return this.playCurrentMediaContent(selectedIndex)
  }
}
