import { MySpecialAudioInputNode } from './MySpecialAudioInputNode'
import { BaseAudioInputNode } from './BaseAudioInputNode'
import { IMediaContent } from '../../media-content/IMediaContent'

export class InputMediaStream extends BaseAudioInputNode {
  constructor() {
    super()
  }

  /*
   * Create Node.
   *
   * @param mediaContent
   * @param audioContext
   * @returns {any}
   */
  public createNode(
    mediaContent: IMediaContent,
    audioContext: AudioContext
  ): Promise<MySpecialAudioInputNode> {
    var mySpecialAudioInputNode: MySpecialAudioInputNode =
      new MySpecialAudioInputNode()

    // console.log(
    //   `mySpecialAudioInputNode.mediaStream = mediaContent.getSource();`
    // )
    mySpecialAudioInputNode.mediaStream = mediaContent.getSource()
    // console.log(mySpecialAudioInputNode.mediaStream );

    return new Promise((resolve, reject) => {
      if (mySpecialAudioInputNode.mediaStreamAudioSourceNode === null) {
        try {
          mySpecialAudioInputNode.mediaStreamAudioSourceNode =
            audioContext.createMediaStreamSource(
              mySpecialAudioInputNode.mediaStream
            )
          // console.log(mySpecialAudioInputNode.mediaStreamAudioSourceNode );
        } catch (err) {
          console.error(err)
          reject(err)
        }
      }

      resolve(mySpecialAudioInputNode)
    })
  }
}
