import { MySpecialAudioInputNode } from './MySpecialAudioInputNode'
import { BaseAudioInputNode } from './BaseAudioInputNode'
import { IMediaContent } from '../../media-content/IMediaContent'

export class InputAudioLocalFile extends BaseAudioInputNode {
  constructor() {
    super()
  }

  /*
   * Create Node.
   *
   * @param mediaContent
   * @param audioContext
   * @returns {any}
   */
  public createNode(
    mediaContent: IMediaContent,
    audioContext: AudioContext,
    useStreamForLocal: boolean
  ): Promise<MySpecialAudioInputNode> {
    if (useStreamForLocal) {
      return this.createAudioStream(
        URL.createObjectURL(mediaContent.getSource()),
        audioContext
      )
    } else {
      return this.createAudioLocalFileWithFileApi(
        mediaContent.getSource(),
        audioContext
      )
    }
  }

  /*
   * CreateAudioLocalFileWithFileApi.
   *
   * @param url
   * @returns {IPromise<T>}
   */
  private createAudioLocalFileWithFileApi(
    aFile: File,
    audioContext: AudioContext
  ): Promise<any> {
    var self = this
    var mySpecialAudioInputNode: MySpecialAudioInputNode =
      new MySpecialAudioInputNode()

    return new Promise((resolve, reject) => {
      self.readFileAsArrayBuffer(aFile).then(
        (result: any) => {
          audioContext.decodeAudioData(
            result,
            function (buffer) {
              mySpecialAudioInputNode.setAudioBuffer(buffer, audioContext)
              resolve(mySpecialAudioInputNode)
            },
            function () {
              console.error('Cannot decodeAudioData')
              reject()
            }
          )
        },
        (result: any) => {
          reject(result)
        }
      )
    })
  }
}
