import { MySpecialAudioInputNode } from '../web-audio-api/audio-input/MySpecialAudioInputNode'
import { IMediaContent } from '../media-content/IMediaContent'
import AudioInputNodeFactory from '../web-audio-api/audio-input/AudioInputNodeFactory'
import { BasePlayer } from './base-player'
import PluginInstanceFactoryService from '../../audio-background/plugin-instance-factory.service'

export default class MediaPlayer extends BasePlayer {
  constructor(
    public audioContext: AudioContext,
    public pluginInstanceFactoryService: PluginInstanceFactoryService,
    public audioInputNodeFactory: AudioInputNodeFactory,
    public callbackOnFinish?: any
  ) {
    super(
      audioContext,
      pluginInstanceFactoryService,
      audioInputNodeFactory,
      callbackOnFinish
    )
  }

  public play(
    mediaContent: IMediaContent,
    selectedIndex: number
  ): Promise<MySpecialAudioInputNode> {
    this.currentMediaContent = mediaContent
    return this.playCurrentMediaContent(selectedIndex)
  }

  public pause(selectedIndex: number): Promise<MySpecialAudioInputNode> {
    return this.pauseCurrentMediaContent(selectedIndex)
  }
}
