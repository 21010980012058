import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { StereoWideningPluginParametersService } from '../../audio-plugins-parameters/stereo-widening/stereo-widening-plugin-parameters.service'
import { StereoWideningMethod } from '../../audio-plugins-parameters/stereo-widening/StereoWideningMethod'
import { StereoWideningDelay } from './method/stereo-widening-delay'

export class StereoWideningAudioNode
  extends AbstractAudioNode
  implements IAudioNode
{
  private input: GainNode
  private output: GainNode

  private stereoWideningDelay: StereoWideningDelay
  private scriptProcessorNode: ScriptProcessorNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    super(audioContext, parameters)

    this.input = this.audioContext.createGain()
    this.output = this.audioContext.createGain()

    // this.scriptProcessorNode = audioContext.createScriptProcessor(1024 * 4, 2, 2);
    // this.scriptProcessorNode.onaudioprocess = this.processAudio.bind(this);

    // if (this.getParameterValue(StereoWideningPluginParametersService.METHOD) === StereoWideningMethod.DELAY) {
    //     this.stereoWideningDelay = new StereoWideningDelay(audioContext, parameters);
    // }

    // TODO:
    this.wire()
  }

  wire(): Promise<any> {
    // if (this.getParameterValue(StereoWideningPluginParametersService.METHOD) === StereoWideningMethod.DELAY) {
    //     this.input.connect(this.stereoWideningDelay.getInput());
    //     this.stereoWideningDelay.getOutput().connect(this.output);
    // } else {
    //     this.input.connect(this.output);
    // }

    // this.input.connect(this.scriptProcessorNode);
    // this.scriptProcessorNode.connect(this.output);

    this.input.connect(this.output)

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.input
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }

  processAudio(audioProcessingEvent: any) {
    // console.log(audioProcessingEvent);

    // // A Bit
    // let mMatrixMCoeff = 1.0 * 0.5;
    // let mMatrixSCoeff = 1.2 * 0.5;
    //
    // // Slight
    // let mMatrixMCoeff = 0.95 * 0.5;
    // let mMatrixSCoeff = 1.4 * 0.5;
    //
    // // Moderate
    // let mMatrixMCoeff = 0.9 * 0.5;
    // let mMatrixSCoeff = 1.6 * 0.5;
    //
    // // High
    // let mMatrixMCoeff = 0.85 * 0.5;
    // let mMatrixSCoeff = 1.8 * 0.5;

    // Super
    let mMatrixMCoeff = 0.8 * 0.5
    let mMatrixSCoeff = 2.0 * 0.5

    var inputBuffer = audioProcessingEvent.inputBuffer
    var outputBuffer = audioProcessingEvent.outputBuffer

    let outLR, outRL
    let inputLeftBuffer = inputBuffer.getChannelData(0)
    let inputRightBuffer = inputBuffer.getChannelData(1)
    let outputLeftBuffer = outputBuffer.getChannelData(0)
    let outputRightBuffer = outputBuffer.getChannelData(1)

    for (let i = 0; i < inputBuffer.length; i++) {
      outLR = (inputLeftBuffer[i] + inputRightBuffer[i]) * mMatrixMCoeff
      outRL = (inputLeftBuffer[i] - inputRightBuffer[i]) * mMatrixSCoeff
      outputLeftBuffer[i] = outLR + outRL
      outputRightBuffer[i] = outLR - outRL
    }
  }

  applyDelayLeft() {
    if (
      this.getParameterValue(StereoWideningPluginParametersService.METHOD) ===
      StereoWideningMethod.DELAY
    ) {
      this.stereoWideningDelay.applyDelayLeft()
    }
  }

  applyDelayRight() {
    if (
      this.getParameterValue(StereoWideningPluginParametersService.METHOD) ===
      StereoWideningMethod.DELAY
    ) {
      this.stereoWideningDelay.applyDelayRight()
    }
  }
}
