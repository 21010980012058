export class HrtfRecord {
  public azimuth: number
  public elevation: number
  public distance: number
  public fir_coeffs_left: Array<number>
  public fir_coeffs_right: Array<number>
  public buffer: AudioBuffer

  constructor(
    azimuth: number,
    elevation: number,
    distance: number,
    fir_coeffs_left: Array<number>,
    fir_coeffs_right: Array<number>
  ) {
    this.azimuth = azimuth
    this.elevation = elevation
    this.distance = distance
    this.fir_coeffs_left = fir_coeffs_left
    this.fir_coeffs_right = fir_coeffs_right
  }

  public reverse(): HrtfRecord {
    var newAzimuth: number = 360 - this.azimuth
    return new HrtfRecord(
      newAzimuth,
      this.elevation,
      this.distance,
      this.fir_coeffs_right,
      this.fir_coeffs_left
    )
  }
}
