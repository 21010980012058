import { DaspService } from '../dsp/DaspService'
import { MemoryService } from '../dsp/MemoryService'
import { FftService } from '../dsp/FftService'

export class NgAudioEngineService {
  private static instance: NgAudioEngineService = null

  private static daspService: DaspService = null
  private static memoryService: MemoryService = null
  private static fftService: FftService = null

  private constructor() {
    if (NgAudioEngineService.daspService === null) {
      // console.log('Creating NgAudioEngineService.daspService');
      NgAudioEngineService.daspService = new DaspService()
      NgAudioEngineService.memoryService = new MemoryService(
        NgAudioEngineService.daspService
      )
      NgAudioEngineService.fftService = new FftService(
        NgAudioEngineService.daspService,
        NgAudioEngineService.memoryService
      )
    }
  }

  static getInstance() {
    if (!NgAudioEngineService.instance) {
      NgAudioEngineService.instance = new NgAudioEngineService()
    }
    return NgAudioEngineService.instance
  }

  public getDaspService(): DaspService {
    return NgAudioEngineService.daspService
  }

  public getMemoryService(): MemoryService {
    return NgAudioEngineService.memoryService
  }

  public getFftService(): FftService {
    return NgAudioEngineService.fftService
  }
}

export default NgAudioEngineService
