import { MediaContent } from './MediaContent'
import { IMediaContent } from './IMediaContent'
import { MediaContentType } from './MediaContentType'

export class RemoteVideoMediaContent
  extends MediaContent
  implements IMediaContent
{
  private _url: string

  constructor(url: string, title: string, thumbnail: string) {
    super(MediaContentType.VIDEO_REMOTE_FILE, title, thumbnail)
    this._url = url
  }

  getSource(): string {
    return this._url
  }
}

export default RemoteVideoMediaContent
