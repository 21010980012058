import { ParameterKeyValue } from '../../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../../audio-core/web-audio-api/audio-node/IAudioNode'
import { XtcFilters } from './XtcFilters'

export class RaceAudioNode extends AbstractAudioNode implements IAudioNode {
  private input: GainNode
  private output: GainNode
  private xtcFilters: XtcFilters

  private convolverNode_ll_rl: ConvolverNode
  private convolverNode_lr_rr: ConvolverNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>,
    xtcFilters: XtcFilters
  ) {
    super(audioContext, parameters)

    this.xtcFilters = xtcFilters
    // this.xtcFilters.createAudioBuffers(this.audioContext);

    this.input = this.audioContext.createGain()
    this.output = this.audioContext.createGain()

    // TODO:
    this.wire()
  }

  wire(): Promise<any> {
    // console.log(this.xtcFilters.buffer_ll_rl.getChannelData(0));
    // console.log(this.xtcFilters.buffer_ll_rl.getChannelData(1));
    // console.log(this.xtcFilters.buffer_lr_rr.getChannelData(0));
    // console.log(this.xtcFilters.buffer_lr_rr.getChannelData(1));

    this.convolverNode_ll_rl = this.audioContext.createConvolver()
    this.convolverNode_ll_rl.normalize = false
    this.convolverNode_ll_rl.buffer = this.xtcFilters.buffer_ll_rl
    this.convolverNode_ll_rl.channelInterpretation = 'discrete'

    this.convolverNode_lr_rr = this.audioContext.createConvolver()
    this.convolverNode_lr_rr.normalize = false
    this.convolverNode_lr_rr.buffer = this.xtcFilters.buffer_lr_rr
    this.convolverNode_lr_rr.channelInterpretation = 'discrete'

    var gain_l: GainNode = this.audioContext.createGain()
    gain_l.gain.value = 1.0

    var gain_r: GainNode = this.audioContext.createGain()
    gain_r.gain.value = 1.0

    var splitter_ll_rl = this.audioContext.createChannelSplitter(2)
    var splitter_lr_rr = this.audioContext.createChannelSplitter(2)

    var merger = this.audioContext.createChannelMerger(2)

    this.input.connect(this.convolverNode_ll_rl)
    this.input.connect(this.convolverNode_lr_rr)

    this.convolverNode_ll_rl.connect(gain_l)
    this.convolverNode_lr_rr.connect(gain_r)

    gain_l.connect(splitter_ll_rl)
    gain_r.connect(splitter_lr_rr)

    var swap: boolean = false
    splitter_ll_rl.connect(merger, 0, swap ? 1 : 0)
    splitter_ll_rl.connect(merger, 1, swap ? 1 : 0)

    splitter_lr_rr.connect(merger, 0, swap ? 0 : 1)
    splitter_lr_rr.connect(merger, 1, swap ? 0 : 1)

    merger.connect(this.output)

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.input
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }

  public updateXtcFilters(xtcFilters: XtcFilters) {
    this.xtcFilters = xtcFilters
    // this.xtcFilters.createAudioBuffers(this.audioContext);

    this.convolverNode_ll_rl.buffer = this.xtcFilters.buffer_ll_rl
    this.convolverNode_lr_rr.buffer = this.xtcFilters.buffer_lr_rr
  }
}
