import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { CapturePluginParametersService } from '../../audio-plugins-parameters/capture/capture-plugin-parameters.service'

export class CaptureAudioNode extends AbstractAudioNode implements IAudioNode {
  public gain: GainNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>
  ) {
    super(audioContext, parameters)

    this.gain = audioContext.createGain()
    this.gain.gain.setValueAtTime(
      this.getParameter(CapturePluginParametersService.GAIN).value,
      audioContext.currentTime
    )
  }

  wire(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.gain
  }

  getOutput(): AudioNode {
    return this.gain
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.gain.connect(destination)
  }

  disconnect(output?: number): void {
    this.gain.disconnect(output)
  }

  applyRecordingEnabled() {
    let recordingEnabled = this.getParameter(
      CapturePluginParametersService.RECORDING_ENABLED
    ).value

    if (recordingEnabled) {
    } else {
    }
  }

  private startRecording() {}

  private stopRecording() {}
}
