import { AbstractPluginParametersService } from '../../audio-core/plugins/AbstractPluginParametersService'
import { IPluginParameters } from '../../audio-core/plugins/IPluginParameters'

export class CapturePluginParametersService
  extends AbstractPluginParametersService
  implements IPluginParameters
{
  public static RECORDING_ENABLED: string = 'recordingEnabled'
  public static GAIN: string = 'gain'

  private static DEFAULT_GAIN_VALUE: number = 1
  private static DEFAULT_RECORDING_ENABLED: boolean = false

  constructor() {
    super()

    this.parameters.push({
      key: CapturePluginParametersService.GAIN,
      value: CapturePluginParametersService.DEFAULT_GAIN_VALUE
    })

    this.parameters.push({
      key: CapturePluginParametersService.RECORDING_ENABLED,
      value: CapturePluginParametersService.DEFAULT_RECORDING_ENABLED
    })
  }

  public getPluginId(): string {
    return 'CAPTURE'
  }

  public getPresetDiscriminator(): string {
    return null
  }
}
