import { ParameterKeyValue } from '../../audio-core/plugins/ParameterKeyValue'
import { AbstractAudioNode } from '../../audio-core/web-audio-api/audio-node/AbstractAudioNode'
import { IAudioNode } from '../../audio-core/web-audio-api/audio-node/IAudioNode'
import { XtcFilters } from './XtcFilters'

export class ArccAudioNode extends AbstractAudioNode implements IAudioNode {
  private input: GainNode
  private output: GainNode
  private xtcFilters: XtcFilters

  private convolverNode_ll_rl: ConvolverNode
  private convolverNode_lr_rr: ConvolverNode
  private splitter_ll_rl: ChannelSplitterNode
  private splitter_lr_rr: ChannelSplitterNode
  private merger: ChannelMergerNode

  constructor(
    audioContext: AudioContext,
    parameters: Array<ParameterKeyValue>,
    xtcFilters: XtcFilters
  ) {
    super(audioContext, parameters)

    this.xtcFilters = xtcFilters
    this.input = this.audioContext.createGain()
    this.output = this.audioContext.createGain()
    this.splitter_ll_rl = this.audioContext.createChannelSplitter(2)
    this.splitter_lr_rr = this.audioContext.createChannelSplitter(2)
    this.merger = this.audioContext.createChannelMerger(2)

    // TODO:
    this.wire()
  }

  wire(): Promise<any> {
    this.convolverNode_ll_rl = this.audioContext.createConvolver()
    this.convolverNode_ll_rl.normalize = false
    this.convolverNode_ll_rl.buffer = this.xtcFilters.buffer_ll_rl
    this.convolverNode_ll_rl.channelInterpretation = 'discrete'

    this.convolverNode_lr_rr = this.audioContext.createConvolver()
    this.convolverNode_lr_rr.normalize = false
    this.convolverNode_lr_rr.buffer = this.xtcFilters.buffer_lr_rr
    this.convolverNode_lr_rr.channelInterpretation = 'discrete'

    this.input.connect(this.convolverNode_ll_rl)
    this.input.connect(this.convolverNode_lr_rr)

    this.convolverNode_ll_rl.connect(this.splitter_ll_rl)
    this.convolverNode_lr_rr.connect(this.splitter_lr_rr)

    const swap = false
    this.splitter_ll_rl.connect(this.merger, 0, swap ? 1 : 0)
    this.splitter_ll_rl.connect(this.merger, 1, swap ? 1 : 0)
    this.splitter_lr_rr.connect(this.merger, 0, swap ? 0 : 1)
    this.splitter_lr_rr.connect(this.merger, 1, swap ? 0 : 1)

    this.merger.connect(this.output)

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  getInput(): AudioNode {
    return this.input
  }

  getOutput(): AudioNode {
    return this.output
  }

  connect(destination: AudioNode, output?: number, input?: number): void {
    this.output.connect(destination)
  }

  disconnect(output?: number): void {
    this.output.disconnect(output)
  }

  public updateXtcFilters(xtcFilters: XtcFilters) {
    this.xtcFilters = xtcFilters

    // Stopped working in Chrome 69
    // this.convolverNode_ll_rl.buffer = this.xtcFilters.buffer_ll_rl;
    // this.convolverNode_lr_rr.buffer = this.xtcFilters.buffer_lr_rr;

    this.convolverNode_ll_rl.disconnect()
    this.convolverNode_lr_rr.disconnect()

    this.wire()
  }
}
