import { MySpecialAudioInputNode } from './MySpecialAudioInputNode'
import { InputAudioLocalFile } from './InputAudioLocalFile'
import { InputAudioRemoteFile } from './InputAudioRemoteFile'
import { InputAudioStream } from './InputAudioStream'
import { InputVideoStream } from './InputVideoStream'
import { InputVideoLocalFile } from './InputVideoLocalFile'
import { InputVideoRemoteFile } from './InputVideoRemoteFile'
import { IMediaContent } from '../../media-content/IMediaContent'
import { InputAudioDom } from './InputAudioDom'
import { InputVideoDom } from './InputVideoDom'
import { InputMediaStream } from './InputMediaStream'

export class AudioInputNodeFactory {
  private inputAudioLocalFile: InputAudioLocalFile
  private inputAudioRemoteFile: InputAudioRemoteFile
  private inputAudioStream: InputAudioStream
  private inputAudioDom: InputAudioDom

  private inputVideoLocalFile: InputVideoLocalFile
  private inputVideoRemoteFile: InputVideoRemoteFile
  private inputVideoStream: InputVideoStream
  private inputVideoDom: InputVideoDom

  private inputMediaStream: InputMediaStream

  private useStreamForLocal: boolean
  private useStreamForRemote: boolean
  private mediaElementSourceSupported: boolean

  constructor() {
    this.inputAudioLocalFile = new InputAudioLocalFile()
    this.inputAudioRemoteFile = new InputAudioRemoteFile()
    this.inputAudioStream = new InputAudioStream()
    this.inputAudioDom = new InputAudioDom()

    this.inputVideoLocalFile = new InputVideoLocalFile()
    this.inputVideoRemoteFile = new InputVideoRemoteFile()
    this.inputVideoStream = new InputVideoStream()
    this.inputVideoDom = new InputVideoDom()

    this.inputMediaStream = new InputMediaStream()

    this.setMediaElementSourceSupported(true)
  }

  /*
   * Set MediaElementSource Supported or not
   * @param value
   */
  public setMediaElementSourceSupported(value: boolean): void {
    this.mediaElementSourceSupported = value
    this.setUseStream(value)
  }

  /*
   * Set Use Stream.
   *
   * @param value
   */
  private setUseStream(value: boolean): void {
    this.useStreamForLocal = value
    this.useStreamForRemote = value
  }

  /*
   * Create AudioNode.
   *
   * @param mediaContent
   * @param audioContext
   * @returns {any}
   */
  public createAudioNode(
    mediaContent: IMediaContent,
    audioContext: AudioContext,
    index: number
  ): Promise<MySpecialAudioInputNode> {
    // console.log(`createAudioNode for mediaContent:`, mediaContent)

    if (mediaContent.isAudioLocalFile()) {
      return this.inputAudioLocalFile.createNode(
        mediaContent,
        audioContext,
        this.useStreamForLocal
      )
    } else if (mediaContent.isAudioRemoteFile()) {
      return this.inputAudioRemoteFile.createNode(
        mediaContent,
        audioContext,
        this.useStreamForRemote
      )
    } else if (mediaContent.isAudioStream()) {
      return this.inputAudioStream.createNode(mediaContent, audioContext)
    } else if (mediaContent.isAudioDom()) {
      return this.inputAudioDom.createNode(mediaContent, audioContext)
    } else if (mediaContent.isVideoLocalFile()) {
      return this.inputVideoLocalFile.createNode(
        mediaContent,
        audioContext,
        index,
        this.useStreamForLocal
      )
    } else if (mediaContent.isVideoRemoteFile()) {
      return this.inputVideoRemoteFile.createNode(
        mediaContent,
        audioContext,
        index,
        this.useStreamForRemote
      )
    } else if (mediaContent.isVideoStream()) {
      return this.inputVideoStream.createNode(mediaContent, audioContext, index)
    } else if (mediaContent.isVideoDom()) {
      return this.inputVideoDom.createNode(mediaContent, audioContext, index)
    } else if (mediaContent.isMediaStream()) {
      return this.inputMediaStream.createNode(mediaContent, audioContext)
    } else {
      return new Promise((resolve, reject) => {
        console.error('Unknown content type!')
        alert('Unknown content type!')
        reject(new Error(`Unknown content type!`))
      })
    }
  }
}

export default AudioInputNodeFactory
