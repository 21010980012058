import { IPluginService } from '../audio-core/plugin/IPluginService'
import PluginGraphNode from '../audio-core/plugin/PluginGraphNode'
import { PluginInstance } from '../audio-core/plugins/PluginInstance'
import { IAudioNode } from '../audio-core/web-audio-api/audio-node/IAudioNode'
import { ArccPluginService } from '../audio-plugins-background/arcc/arcc-plugin.service'
import { AreiPluginService } from '../audio-plugins-background/arei/arei-plugin.service'
import { CapturePluginService } from '../audio-plugins-background/capture/capture-plugin.service'
import { ConvolverPluginService } from '../audio-plugins-background/convolver/convolver-plugin.service'
import { DynamicsCompressorPluginService } from '../audio-plugins-background/dynamics-compressor/dynamics-compressor-plugin.service'
import { EqBiquadPluginService } from '../audio-plugins-background/eq-biquad/eq-biquad-plugin.service'
import { GainPluginService } from '../audio-plugins-background/gain/gain-plugin.service'
import { RacePluginService } from '../audio-plugins-background/race/race-plugin.service'
import { ReverbPluginService } from '../audio-plugins-background/reverb/reverb-plugin.service'
import { StereoWideningPluginService } from '../audio-plugins-background/stereo-widening/stereo-widening-plugin.service'

/**
 * What we want to do:
 * provided a pluginId, we must create a new instance of IAudioNode.
 *
 * In the end, this factory should dynamically load plugin implementations.
 *
 * For now, it will load them in advance and do a simple switch.
 */
export class PluginInstanceFactoryService {
  constructor(
    private arccPluginService: ArccPluginService,
    private areiPluginService: AreiPluginService,
    private capturePluginService: CapturePluginService,
    private dynamicsCompressorPluginService: DynamicsCompressorPluginService,
    private eqBiquadPluginService: EqBiquadPluginService,
    private gainPluginService: GainPluginService,
    private racePluginService: RacePluginService,
    private convolverPluginService: ConvolverPluginService,
    private reverbPluginService: ReverbPluginService,
    private stereoWideningPluginService: StereoWideningPluginService
  ) {}

  public createPluginGraphNode(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    browser: any
  ): Promise<PluginGraphNode> {
    switch (pluginInstance.pluginId) {
      case 'ARCC':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.arccPluginService,
          browser
        )
      case 'AREI':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.areiPluginService,
          browser
        )
      case 'CAPTURE':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.capturePluginService,
          browser
        )
      case 'DYNAMICS_COMPRESSOR':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.dynamicsCompressorPluginService,
          browser
        )
      case 'EQ_BIQUAD':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.eqBiquadPluginService,
          browser
        )
      case 'SIMPLE_GAIN':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.gainPluginService,
          browser
        )
      case 'RACE_TWO_CHANNELS':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.racePluginService,
          browser
        )
      case 'CONVOLVER':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.convolverPluginService,
          browser
        )
      case 'REVERB':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.reverbPluginService,
          browser
        )
      case 'STEREO_WIDENING':
        return this.createPluginGraphNodeActual(
          audioContext,
          pluginInstance,
          this.stereoWideningPluginService,
          browser
        )
      default:
        console.error('Cannot create ' + pluginInstance.pluginId)
        break
    }

    return new Promise((resolve, reject) => {
      reject()
    })
  }

  private createPluginGraphNodeActual(
    audioContext: AudioContext,
    pluginInstance: PluginInstance,
    pluginService: IPluginService,
    browser: any
  ): Promise<PluginGraphNode> {
    return new Promise((resolve, reject) => {
      pluginService.createAudioNode(audioContext, pluginInstance, browser).then(
        (audioNode: IAudioNode) => {
          resolve(
            new PluginGraphNode(
              audioContext,
              audioNode,
              pluginService,
              pluginInstance,
              browser
            )
          )
        },
        (error) => reject(error)
      )
    })
  }
}

export default PluginInstanceFactoryService
